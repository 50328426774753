import {
  createTheme,
  ICustomizations,
  IPalette,
  ITheme,
  IPersonaCoinStyleProps,
  IPersonaCoinStyles,
} from '@fluentui/react';
// import { DatePickerStyles } from './styles/DatePickerStyles';
// import { PeoplePickerItemStyles } from './styles/PeoplePickerStyles';
// import { addVariants } from '@fluentui/scheme-utilities';
import { addVariants } from '@uifabric/variants';
import { FluentStyles } from '@uifabric/fluent-theme/lib/fluent/FluentStyles';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import { getInputFocusStyle } from 'office-ui-fabric-react';
import { DarkPalette } from './palette';


const PersonaCoinStyles = (props: IPersonaCoinStyleProps): Partial<IPersonaCoinStyles> => {
  return {
    initials: {
      color: props.showUnknownPersonaCoin ? DarkTheme.palette.redDark : DarkTheme.palette.black,
    },
  };
};

const extendedSemanticColors = {
  headerText: DarkPalette.neutralPrimary,
  navBackground: DarkPalette.neutralQuaternaryAlt,
  navItemBackgroundHovered: DarkPalette.neutralQuaternary,
  navItemBackgroundPressed: DarkPalette.neutralTertiaryAlt,
  bodyStandoutBackground: DarkPalette.neutralLighter,
  bodyTextChecked: DarkPalette.neutralLighter,
  disabledBodyText: DarkPalette.neutralTertiaryAlt,
  variantBorder: DarkPalette.neutralSecondary,
  variantBorderHovered: DarkPalette.neutralSecondary,
  defaultStateBackground: DarkPalette.white,
  link: DarkPalette.themeDark,
  linkHovered: DarkPalette.themeDarker,
  buttonBackgroundChecked: DarkPalette.neutralLight,
  buttonBackgroundCheckedHovered: DarkPalette.neutralQuaternary,
  buttonBorder: DarkPalette.neutralSecondary,
  buttonTextHovered: DarkPalette.neutralPrimary,
  buttonTextChecked: DarkPalette.neutralPrimary,
  buttonTextCheckedHovered: DarkPalette.neutralPrimary,
  buttonTextPressed: DarkPalette.neutralPrimary,
  buttonTextDisabled: DarkPalette.neutralTertiaryAlt,
  primaryButtonTextDisabled: DarkPalette.neutralTertiaryAlt,
  accentButtonBackground: DarkPalette.themePrimary,
  inputBorder: DarkPalette.neutralSecondary,
  inputFocusBorderAlt: DarkPalette.themeDarkAlt,
  inputTextHovered: DarkPalette.neutralPrimary,
  disabledText: DarkPalette.neutralTertiaryAlt,
  disabledSubtext: DarkPalette.neutralTertiaryAlt,
  listItemBackgroundCheckedHovered: DarkPalette.neutralQuaternary,
  menuDivider: DarkPalette.neutralQuaternaryAlt,
  menuIcon: DarkPalette.neutralPrimary,
  menuHeader: DarkPalette.neutralPrimary,
  menuItemTextHovered: DarkPalette.neutralPrimary
};


export const DarkTheme: ITheme = createTheme({
  palette: DarkPalette,
  semanticColors: extendedSemanticColors,
  //isInverted: true,
  effects: {
    roundedCorner2: '2px',
    elevation4: Depths.depth4,
    elevation8: Depths.depth8,
    elevation16: Depths.depth16,
    elevation64: Depths.depth64
  }
});

const DropdownStyles = (props) => {
  var theme = props.theme;
  if (!theme) {
    throw new Error('theme is undefined or null in base Dropdown getStyles function.');
  }
  return {
    title: [
      {
        borderColor: theme.palette.neutralSecondary
      }
    ]
  };
};

const SearchBoxStyles = (props) => {
  var theme = props.theme, hasFocus = props.hasFocus, underlined = props.underlined;
  if (!theme) {
    throw new Error('theme is undefined or null in base SearchBox getStyles function.');
  }
  return {
    root: [
      hasFocus && [
        'is-active',
        {
          position: 'relative'
        },
        getInputFocusStyle(theme.palette.themePrimary, underlined ? 0 : theme.effects.roundedCorner2, underlined ? 'borderBottom' : 'border')
      ]
    ]
  };
};

const TextFieldStyles = (props) => {
  var theme = props.theme, focused = props.focused, underlined = props.underlined, hasErrorMessage = props.hasErrorMessage;
  if (!theme) {
    throw new Error('theme is undefined or null in base TextField getStyles function.');
  }
  return {
    fieldGroup: [
      focused &&
      !underlined &&
      getInputFocusStyle(!hasErrorMessage ? theme.palette.themePrimary : theme.semanticColors.errorText, theme.effects.roundedCorner2)
    ]
  };
};

const BreadcrumbStyles = (props) => {
  var theme = props.theme;
  if (!theme) {
    throw new Error('theme is undefined or null in base Breadcrumb getStyles function.');
  }
  var lastChildItem = {
    color: theme.palette.neutralPrimary,
    backgroundColor: 'transparent'
  };
  return {
    listItem: {
      selectors: {
        '&:last-child .ms-Breadcrumb-itemLink': lastChildItem,
        '&:last-child .ms-Breadcrumb-item': lastChildItem
      }
    }
  };
};

const componentStyles = {
  Card: {
    styles: {
      root: {
        background: DarkTheme.palette.neutralLighter,
      },
    },
  },
  // DatePicker: {
  //   styles: DatePickerStyles,
  // },
  DetailsList: {
    styles: {
      headerWrapper: {
        selectors: {
          '.ms-DetailsHeader': {
            borderColor: DarkTheme.palette.neutralQuaternary,
          },
        },
      },
    },
  },
  ActionButton: {
    styles: {
      root: {
        backgroundColor: DarkTheme.palette.white,
      },
      rootDisabled: {
        backgroundColor: DarkTheme.palette.neutralLighter,
      },
      rootHovered: {
        backgroundColor: DarkTheme.palette.neutralLight,
      },
      rootPressed: {
        backgroundColor: DarkTheme.palette.neutralQuaternaryAlt,
      },
    },
  },
  DetailsRow: {
    styles: {
      root: {
        selectors: {
          ':hover': {
            background: DarkTheme.palette.neutralLighter,
          },
        },
        borderColor: DarkTheme.palette.neutralQuaternaryAlt,
      },
    },
  },
  Modal: {
    styles: {
      main: {
        backgroundColor: DarkTheme.palette.neutralLighter,
      },
    },
  },
  Overlay: {
    styles: {
      root: {
        background: DarkTheme.palette.blackTranslucent40,
      },
    },
  },
  VerticalDivider: {
    styles: {
      divider: {
        backgroundColor: DarkTheme.palette.neutralQuaternaryAlt,
      },
      wrapper: {
        Backgroundcolor: DarkTheme.palette.green,
      },
    },
  },
  DocumentCard: {
    styles: {
      root: {
        border: `1px solid ${DarkTheme.palette.neutralQuaternaryAlt}`,
        selectors: {
          '.ms-DocumentCardPreview': {
            borderRight: `1px solid ${DarkTheme.palette.neutralQuaternaryAlt}`,
          },
        },
      },
    },
  },
  DocumentCardPreview: {
    styles: {
      root: {
        borderBottom: `1px solid ${DarkTheme.palette.neutralQuaternaryAlt}`,
        borderRight: `1px solid ${DarkTheme.palette.neutralQuaternaryAlt}`,
      },
    },
  },
  Panel: {
    styles: {
      main: {
        backgroundColor: DarkTheme.palette.neutralLighter,
      },
      closeButton: {
        color: DarkTheme.palette.neutralSecondary,
        selectors: {
          ':hover': {
            color: DarkTheme.palette.neutralPrimary,
          },
        },
      },
    },
  },
  // PeoplePickerItem: {
  //   styles: PeoplePickerItemStyles,
  // },
  PersonaCoin: {
    styles: PersonaCoinStyles,
  },
  Separator: {
    styles: {
      root: {
        selectors: {
          ':before': {
            backgroundColor: DarkTheme.palette.neutralQuaternaryAlt,
          },
          ':after': {
            backgroundColor: DarkTheme.palette.neutralQuaternaryAlt,
          },
        },
      },
    },
  },
  SpinButton: {
    styles: {
      inputTextSelected: {
        color: DarkTheme.palette.black,
        background: DarkTheme.palette.themePrimary,
      },
    },
  },
  // coherence controls related
  Dropdown: {
    styles: DropdownStyles
  },
  SearchBox: {
    styles: SearchBoxStyles
  },
  TextField: {
    styles: TextFieldStyles
  },
  Breadcrumb: {
    styles: BreadcrumbStyles
  }
};

DarkTheme.components = componentStyles;
addVariants(DarkTheme);

export const DarkCustomizations: ICustomizations = {
  settings: {
    theme: DarkTheme,
  },
  scopedSettings: componentStyles,
};

