import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '../../theme/Theme.types';

export interface IContactUsStyles {
  root: IStyle;
  header: IStyle;
  title: any;
  body: IStyle;
  link: IStyle;
  message: IStyle;
}

export const getStyles = (theme: IAppTheme): IContactUsStyles => {
  return {
    root: {
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme ? theme.palette.white : 'transparent',  // '#ffffff',
      alignContent: 'center',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      font: {
        size: 18,
        weight: 600,
      },
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    link: {
      //color: theme.colors.Link.PrimaryLink,
      selectors: {
        '&:hover': {
          opacity: 0.5,
          cursor: 'pointer',
        },
      }
    },
    message: {
      width: '90%',
      fontFamily: 'Segoe UI',
      fontSize: '14px',
      //color: '#333333',
    }
  }
}

