import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { IAppTheme } from '@msx/platform-types'
//import extensionMessage from './Extension.message';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './Extension.styles'
import { ActionButton, IIconProps } from 'office-ui-fabric-react';
import { IExtensionMessage, getDefaultExtensionMessages } from './Extension.types';

export interface DashboardErrorProps  {
  isDashboard: boolean;
  extensionName: string;
  componentName: string;
  errorMessage: string;
  error: string;
  handleRefreshTile: () => void;
  handleFeedback: () => void;
  handleShowErrorDetail: () => void;
  //intl: InjectedIntlProps["intl"];
  theme: IAppTheme;
  messages: IExtensionMessage;
}
const getClassNames = classNamesFunction<any, any>();
let classes: any;

export const DashboardError: (React.FC<DashboardErrorProps>) = props => {
  const { theme } = props;
  classes = getClassNames(getStyles(theme as IAppTheme));
  const messages = props.messages ? props.messages : getDefaultExtensionMessages();

  const renderExtensionErrorDashboardContent = (): JSX.Element => {
    const containerStackTokens: IStackTokens = { childrenGap: 20 };
    const stackTokens: IStackTokens = { childrenGap: 40 };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };
    const moreIcon: IIconProps = { iconName: 'More' };
    return (
      <Stack horizontal tokens={containerStackTokens}>
        <Stack >
          <p className={classes.para}>{messages.extensionErrorMessageTitle}</p>
          <p className={classes.para}>{messages.extensionErrorMessageSubTitle}</p>
          <p className={classes.para}><a className={classes.link} onClick={props.handleFeedback}>{messages.extensionErrorMessageFeedback}</a></p>
          <div className={classes.sectionNear} >
            <Stack horizontal tokens={stackTokens}>
              <ActionButton iconProps={refreshIcon} onClick={props.handleRefreshTile}>
                {messages.extensionErrorMessageRefresh}
              </ActionButton>
              <ActionButton iconProps={moreIcon} onClick={props.handleShowErrorDetail}>
                {messages.extensionErrorMessageDetail}
              </ActionButton>
            </Stack>
          </div>
        </Stack>
      </Stack>
    );
  }

  const renderMain = (): JSX.Element => {
    return renderExtensionErrorDashboardContent()
  }
  return renderMain();
}

export default DashboardError;
