import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  Stack, IconButton, IStackTokens,
  classNamesFunction, Customizer
} from 'office-ui-fabric-react';
import { ApplicationContext, } from '../../common/context/ApplicationContext';
import { DashboardProps } from './MsxDashboard.types'
import { ExtensionEventTypes } from '../Extension/Extension.types';
import { getStyles } from './MsxDashboard.styles'
import { IDashboardTile } from '../..';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { useBoolean } from '@uifabric/react-hooks';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { getDefaultDashboardMessages } from './MsxDashboard.types';
import arrayMove from 'array-move';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

export const MsxDashboard: (React.FC<DashboardProps>) = props => {
  const { tiles } = props;
  const { appState, userDashboardTiles, extensionsRegistrationClient } = useContext(ApplicationContext);
  const [isOpen, { setTrue: openPanel, setFalse: dismissAddNewPanel }] = useBoolean(false);
  const doneEditButtonRef = useRef(null)
  const [inDashboardEditMode, { setTrue: allowEdit, setFalse: cancelEdit }] = useBoolean(false);
  const messages = props.messages ? props.messages : getDefaultDashboardMessages();
  const addNewFooterButtonStyles = { root: { marginRight: 8 } };
  const [editTiles, setEditTiles] = useState([]);
  const [addTiles, setAddTiles] = useState([]);
  const [tilesToInsert, setTilesToInsert] = useState([]);
  const addTilesRef = useRef(addTiles);
  const editTilesRef = useRef(editTiles);
  const defaultButtonStyle = { root: { marginTop: '10px' } };
  classes = getClassNames(getStyles(appState.theme));

  useEffect(() => {
    initializeTiles();
    return () => {
      // UNLOAD
    }
  }, [tiles, userDashboardTiles, appState])

  useEffect(() => {
    notifyCanvasTilesRefresh(userDashboardTiles, false);
    window.addEventListener(ExtensionEventTypes.DACHBOARD_EDIT_ACTION_EVENT, e => handleDashboardEditAction(e));
    return () => {
      // UNLOAD
      window.removeEventListener(ExtensionEventTypes.DACHBOARD_EDIT_ACTION_EVENT, handleDashboardEditAction);
      notifyCanvasTilesRefresh(userDashboardTiles, false);
    }
  }, [])

  const handleDashboardEditAction = (e) => {
    if (!e.detail) return;
    switch (e.detail.action) {
      case ExtensionEventTypes.DASHBOARD_EDIT_ACTION_MOVE_FORWARD:
        handleMoveForward(e.detail.id);
        break;
      case ExtensionEventTypes.DASHBOARD_EDIT_ACTION_MOVE_BACKWARD:
        handleMoveBackward(e.detail.id);
        break;
      case ExtensionEventTypes.DASHBOARD_EDIT_ACTION_REMOVE:
        handleRemoveClick(e.detail.id);
        break;
    }
  }

  const handleMoveBackward = (tileId: string) => {
    const tileIndex = editTilesRef.current.findIndex(tile => tile.key == tileId);
    if (tileIndex <= 0) return;
    setEditTiles(arrayMove(editTilesRef.current, tileIndex, tileIndex - 1));
  }

  const handleMoveForward = (tileId: string) => {
    const tileIndex = editTilesRef.current.findIndex(tile => tile.key == tileId);
    if (tileIndex === -1) return;
    if (tileIndex == editTilesRef.current.length - 1) return;
    setEditTiles(arrayMove(editTilesRef.current, tileIndex, tileIndex + 1));
  }

  const handleRemoveClick = (tileId: string) => {
    const tile = editTilesRef.current.find(tile => tile.key == tileId);
    const newEditTiles = editTilesRef.current.filter(tile => tile.key !== tileId);
    setEditTiles(newEditTiles);
    setAddTiles([...addTilesRef.current, tile]);
  }


  const initializeTiles = () => {
    const newEditTiles = [];
    const newAddTiles = [];

    // add tiles to edit canvas
    if (userDashboardTiles.length > 0) {
      userDashboardTiles.forEach(userTile => {
        const validTile = tiles.find(item => item.key === userTile.id);
        if (validTile) {
          newEditTiles.push(validTile);
        }
      });
    }

    // add tiles to the new tile collection
    tiles.forEach(tile => {
      // check tile is already added to edit canvas or not
      const userTile = userDashboardTiles.find(item => item.id === tile.key);
      if (!userTile) {
        newAddTiles.push(tile);
      }
    });
    setEditTiles(newEditTiles);
    setAddTiles(newAddTiles);
  }

  const onAddSelectedCardToDashboard = () => {
    dismissAddNewPanel();

    const newEditTiles = [];
    for (const tile of editTiles) {
      newEditTiles.push(tile);
    }
    tilesToInsert.forEach(tile => {
      if (tile.isChecked) {
        newEditTiles.push(tile.tile);
      }
    });

    // remove from add tile collection
    const newAddTiles = [];
    for (const tile of addTiles) {
      const userTile = newEditTiles.find(item => item.key === tile.key);
      if (!userTile) {
        newAddTiles.push(tile);
      }
    }
    setAddTiles(newAddTiles);
    setEditTiles(newEditTiles);
    notifyCanvasTilesRefresh(newEditTiles, true);
  }

  const onRenderAddNewFooterContent = React.useCallback(
    () => (
      <div>
        <PrimaryButton onClick={onAddSelectedCardToDashboard} styles={addNewFooterButtonStyles}>
          {messages.addNewCardFooterAddButtonText}
        </PrimaryButton>
        <DefaultButton onClick={dismissAddNewPanel}>{messages.addNewCardFooterCancelButtonText}</DefaultButton>
      </div>
    ),
    [dismissAddNewPanel, onAddSelectedCardToDashboard],
  );

  const onEditDashboard = () => {
    allowEdit();
    notifyCanvasTilesRefresh(editTiles, true);
    props.onBeginChanges();
  }


  const onSaveDashboard = (): void => {
    const data = buildOutput();
    props.onSaveChanges(data);
    cancelEdit();
    notifyCanvasTilesRefresh(data, false);
  }

  const onRevertToDefault = () => {
    initializeTiles();
    notifyCanvasTilesRefresh(editTiles, true);
  }

  const onCancelEdit = () => {
    cancelEdit();
    props.onCancelChanges();
    notifyCanvasTilesRefresh(userDashboardTiles, false);
  }

  const renderRevertToDefaultBtn = () => {
    if (inDashboardEditMode) {
      return (
        <DefaultButton
          onClick={onRevertToDefault}
          styles={defaultButtonStyle}
        >
          {messages.revertToDefaultButtonText}
        </DefaultButton>
      );
    }
  }

  const buildOutput = (): IDashboardTile[] => {
    const result: IDashboardTile[] = [];
    for (const tile of editTilesRef.current) {
      result.push({ id: tile.key });
    }
    return result;
  }

  const notifyCanvasTilesRefresh = (tiles, editMode) => {
    const selectedTiles: IDashboardTile[] = [];
    for (const tile of tiles) {
      selectedTiles.push({ id: tile.key });
    }
    const data = {
      inDashboardEditMode: editMode,
      inDashboardEditTiles: selectedTiles,
    }
    const event = new CustomEvent(ExtensionEventTypes.DASHBOARD_EDIT_TILES_REFRESH, { detail: data });
    window.dispatchEvent(event);
  }

  const onAddNewCard = () => {
    notifyCanvasTilesRefresh(editTilesRef.current, true);
    setTilesToInsert([]);
    openPanel();
  }


  const renderAddNewCardButton = () => {
    if (inDashboardEditMode) {
      return (
        <DefaultButton
          onClick={onAddNewCard}
          styles={defaultButtonStyle}
        >
          {messages.addNewCardButtonText}
        </DefaultButton>
      );
    }
  }

  const renderCancelEditButton = () => {
    if (inDashboardEditMode) {
      return (
        <DefaultButton
          onClick={onCancelEdit}
          styles={defaultButtonStyle}
        >
          {messages.cancelEditButtonText}
        </DefaultButton>
      );
    }
  }

  const renderEditDashboardHeader = () => {
    return (
      <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 15 }}>
        {
          !inDashboardEditMode
            ? <PrimaryButton
              onClick={onEditDashboard}
              autoFocus={true}
              styles={defaultButtonStyle}
            >
              {messages.editDashboardButtonText}
            </PrimaryButton>
            : <PrimaryButton
              onClick={onSaveDashboard}
              componentRef={doneEditButtonRef}
              autoFocus={true}
              styles={defaultButtonStyle}
            >
              {messages.doneEditingButtonText}
            </PrimaryButton>
        }

        {renderAddNewCardButton()}
        {renderRevertToDefaultBtn()}
        {renderCancelEditButton()}
      </Stack>
    );
  }
  const renderAddNewCardPanel = () => {
    return (
      <Panel
        isLightDismiss
        isOpen={isOpen}
        type={PanelType.custom}
        customWidth={'500px'}
        onDismiss={dismissAddNewPanel}
        closeButtonAriaLabel="Close"
        headerText={messages.addnewCardHeadingText}
        onRenderFooterContent={onRenderAddNewFooterContent}
        isFooterAtBottom={true}
      >
        {renderAddNewCardPanelBody()}
      </Panel>
    )
  }
  const renderAddNewCardPanelBody = (): JSX.Element => {
    return (
      <Stack horizontalAlign={'start'}>
        <Stack.Item>
          {renderNewtiles()}
        </Stack.Item>
      </Stack>
    );
  }

  const handleTileSelectionChange = (isChecked: boolean, tile: JSX.Element) => {
    const existingTile = tilesToInsert.find(item => item.id === tile.key);
    if (existingTile) {
      existingTile.isChecked = isChecked;
    }
    else {
      tilesToInsert.push({ id: tile.key, isChecked: isChecked, tile: tile });
    }
  }

  const renderNewtiles = (): JSX.Element[] => {
    return addTiles.map((tile, index) => {
      return (
        <React.Fragment key={index}>
          <section>
            <Stack horizontal>
              <Stack.Item align="center" className={classes.selectCheckBox}>
                <Checkbox label="" onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => handleTileSelectionChange(isChecked, tile)} />
              </Stack.Item>
              <Stack.Item align="center" className={classes.newTile}>
                {tile}
              </Stack.Item>
            </Stack>
          </section>
        </React.Fragment >
      );
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setEditTiles(arrayMove(editTiles, oldIndex, newIndex));
  };

  const normalizeEditTiles = () => {
    const tileExtensions = extensionsRegistrationClient.getExtensionsTiles();
    const newEditTiles = [];
    editTiles.forEach(tile => {
      // check tile is still valid or not
      const userTile = tileExtensions.find(item => item.key === tile.key);
      if (userTile) {
        newEditTiles.push(tile);
      }
    });
    return newEditTiles;
  }

  const renderDashboard = () => {
    if (!editTiles || !addTiles) return null;
    editTilesRef.current = editTiles;
    addTilesRef.current = addTiles;
    const stateProps = {
      inDashboardEditMode: inDashboardEditMode,
      onSortEnd: onSortEnd,
    };
    const data = buildOutput();
    const tilesToRender = normalizeEditTiles();
    return (
      <Stack >
        {renderEditDashboardHeader()}
        {props.onRenderBody(tilesToRender, stateProps)}
        {/* NOTE: Do not remove below line. debug purpose only */}
        {/* {PrettyPrintJson({ data })} */}
        {renderAddNewCardPanel()}
      </Stack>
    );
  }

  const PrettyPrintJson = ({ data }) => {
    if (!inDashboardEditMode) return null;
    return (<div><br /><pre>{JSON.stringify(data, null, 2)}</pre></div>);
  }

  const renderMain = (): JSX.Element => {
    return (
      <Customizer {...appState.themeCustomizations}>
        {renderDashboard()}
      </Customizer>
    );
  }
  return renderMain();
}

export default MsxDashboard;
