import Props from './MsxDonutChart.types';



export const legendTranslate = (props: Props, d: any, i: number, legendHeight: number, slimLayout: boolean): string => {
  const itemWidth = 130;
  const itemCount = props.chartData.length;
  const itemHeight = 18;
  if (slimLayout)
    return "translate(" +
      (3 + i % itemCount * itemWidth) +
      "," + (Math.floor(i / itemCount) * itemHeight - 15) + ")";
  if (props.chartData.length <= 2) {
    return 'translate(125,' + ((i * legendHeight * 4.6) - 55) + ')';
  }
  else if (i % 2 == 0) {
    return 'translate(90,' + ((i / 2 * legendHeight * 4.6) - 55) + ')';
  }
  return 'translate(190,' + Math.floor((i / 2 * legendHeight * 4.6) - 94) + ')';
}


export const abbrNum = (props: Props, number, decPlaces) => {
  const isNegativeNumber = number < 0;
  number = isNegativeNumber ? Math.abs(Math.round(number * 100) / 100) : number; 
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {

    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round(number * decPlaces / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if ((number == 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number = new Intl.NumberFormat(props.locale ? props.locale : 'en').format(number) + abbrev[i];

      // We are done... stop
      break;
    }
  }
  if (number == 0) number = new Intl.NumberFormat(props.locale ? props.locale : 'en', { minimumSignificantDigits: 3 }).format(number);
  return isNegativeNumber ? "-" + number : number;
}
