import { useEffect, useContext, useState } from 'react';
import { IUser } from '../models/IUser';
import { ServiceContext } from '../context/ServiceContext';

export function useUser(): IUser | null {
  const { authClient } = useContext(ServiceContext);
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    authClient.getUser()
      .then((user: IUser | null): void => {
        if (user) {
          setUser(user)
        }
        else {
          console.log('authClient: No user found');
        }
      }
      );
  }, [authClient]);

  return user;
}
