//import { IKeyValueItem } from "../..";
import { messages } from './Extension.message'
import {AttributeProps, INotificationItem} from '@msx/platform-types';

export type NavigationRouteProps = {
  [key: string]: any
};

export type NavigationParamProps = {
  [key: string]: any
};

// export type AttributeProps = {
//   [key: string]: any
// };

export type NotificationProps = {
  component: string;
  componentKey: string;
  extensionKey: string;
  notificationItem: INotificationItem;
}

export interface ExtensionProps {
  componentKey: string;
  onNavigateToRoute: (route: NavigationRouteProps) => void;
  config?: {
    [key: string]: any;
  };
  attributes?: AttributeProps;
  onLaunchFeedback?: (extensionKey: string) => void;
  onLaunchChatBot?: (extensionKey: string) => void;
  onValidateAttributes?: (attributes: AttributeProps) => boolean;
  messages?: IExtensionMessage;
  onNotify?: (item: NotificationProps) => void;
}

export const ExtensionEventTypes = {
  EXCLUDE_OPTIONS_EVENT: "SupplierSelectOptionsEvent",
  PIN_PAGE_TO_DASHBOARD: 'PinPageToDashboard',
  APP_ERROR_EVENT: 'appError',
  CHAT_BOT_INVOKED: 'CHAT_BOT_INVOKED',
  NOTIFY_ITEM_INVOKED: 'NOTIFY_ITEM_INVOKED',
  NOTIFY_ITEM_FAILED: 'NOTIFY_ITEM_FAILED',
  FEEDBACK_LAUNCHER_INVOKED: 'FEEDBACK_LAUNCHER_INVOKED',
  SUPPLIER_SELECT_OPTIONS_EVENT: "SupplierSelectOptionsEvent",
  DACHBOARD_EDIT_ACTION_EVENT: "MsxDashboardEditAction",
  DASHBOARD_EDIT_ACTION_MOVE_FORWARD: 'MoveForward',
  DASHBOARD_EDIT_ACTION_MOVE_BACKWARD: 'MoveBackward',
  DASHBOARD_EDIT_ACTION_REMOVE: 'Remove',
  DASHBOARD_EDIT_TILES_REFRESH: 'DASHBOARD_EDIT_TILES_REFRESH',
  SUPPLIER_VALIDATOIN_INVOKED: 'SUPPLIER_VALIDATOIN_INVOKED',
  LOCALE_CHANGED: "LOCALE_CHANGED",
  THEME_CHANGED: "THEME_CHANGED",
  DASHBOARD_EDIT_ACTION_SAVE: "DASHBOARD_EDIT_ACTION_SAVE",
}

export const ExtensionKeys = {
  KEY_SUPPLIER_ID: 'supplierId',
  KEY_COMPANY_CODE: 'companyCode',
  KEY_CAN_MODIFY_EXTENSIONS: "feature.canmodifyextensions",
  KEY_TEST_EXTENSIONS: "testExtensions",
  KEY_SUPPLIER_EXCLUDE_OPTION: 'supplier',
  KEY_COMPANY_EXCLUDE_OPTION: 'company'
}

export const ExtensionErrorTypes = {
  ROUTE_REGISTRATION_NOT_FOUND_ERROR: "ROUTE_REGISTRATION_NOT_FOUND_ERROR",
  EXTENSION_NOT_FOUND_ERROR: "EXTENSION_NOT_FOUND_ERROR",
  EXTENSION_SCRIPT_LOAD_FAILED: "EXTENSION_SCRIPT_LOAD_FAILED",
  SCRIPT_ERROR: 'script error',
  AUTH_CLIENT_GET_USER_FAILED: 'AUTH_CLIENT_GET_USER_FAILED',
}


export interface IExtensionMessage {
  loadingMessage: string;
  extensionErrorDetailHeading: string;
  extensionErrorMessageTitle: string;
  extensionErrorMessageSubTitle: string;
  extensionErrorMessageDetail: string;
  extensionErrorMessageRefresh: string;
  extensionErrorMessageFeedback: string;
  extensionErrorDetailName: string;
  extensionErrorDetailErrorLine: string;
  extensionErrorDetailErrorColumn: string;
  extensionErrorDetailComponent: string;
  extensionErrorDetailErrorReason: string;
  extensionErrorDetailErrorStack: string;
}

export const getDefaultExtensionMessages = (): IExtensionMessage => {
  return {
    loadingMessage: messages.loadingMessage.defaultMessage,
    extensionErrorDetailHeading: messages.extensionErrorDetailHeading.defaultMessage,
    extensionErrorMessageTitle: messages.extensionErrorMessageTitle.defaultMessage,
    extensionErrorMessageSubTitle: messages.extensionErrorMessageSubTitle.defaultMessage,
    extensionErrorMessageDetail: messages.extensionErrorMessageDetail.defaultMessage,
    extensionErrorMessageRefresh: messages.extensionErrorMessageRefresh.defaultMessage,
    extensionErrorMessageFeedback: messages.extensionErrorMessageFeedback.defaultMessage,
    extensionErrorDetailName: messages.extensionErrorDetailName.defaultMessage,
    extensionErrorDetailErrorLine: messages.extensionErrorDetailErrorLine.defaultMessage,
    extensionErrorDetailErrorColumn: messages.extensionErrorDetailErrorColumn.defaultMessage,
    extensionErrorDetailComponent: messages.extensionErrorDetailComponent.defaultMessage,
    extensionErrorDetailErrorReason: messages.extensionErrorDetailErrorReason.defaultMessage,
    extensionErrorDetailErrorStack: messages.extensionErrorDetailErrorStack.defaultMessage,

  }
}