import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IStyleFunctionOrObject } from 'office-ui-fabric-react/lib/Utilities';
import { INavLink, INavLinkGroup, INavState } from 'office-ui-fabric-react/lib/Nav';

export enum NavGroupType {
  ToggleGroup,
  MenuGroup,
  CustomizationGroup,
}

export interface INavProps {
  groups: ICustomNavLinkGroup[] | null;
  initialSelectedKey?: string;
  selectedKey?: string;
  isNavCollapsed?: boolean;
  navScrollerId?: string;
  styles?: IStyleFunctionOrObject<INavStyleProps, INavStyles>;
  dataHint?: string;
  enableCustomization?: boolean;
  showMore?: boolean;
  onNavCollapsedCallback?(isCollapsed: boolean): void;
  onShowMoreLinkClicked?(ev: React.MouseEvent<HTMLElement>): void;
  onNavNodeExpandedCallback?(nodeKey: string, isExpanded: boolean): void;
  onEditLeftNavClickedCallback?(): void;
}

export interface INavStates extends INavState {
  isNavCollapsed?: boolean;
  showMore?: boolean;
}

export interface INavLinks extends INavLink {
  scrollTop?: number;
  isHidden?: boolean;
  alternateText?: string;
  isShowMoreLink?: boolean;
  disableAutoExpand?: boolean;
}

export interface ICustomNavLinkGroup extends INavLinkGroup {
  groupType: NavGroupType;
}

export interface INavStyleProps {
  isSelected?: boolean;
  hasChildren?: boolean;
  hasGroupName?: boolean;
  isChildLinkSelected?: boolean;
  nestingLevel?: number;
  isCollapsed?: boolean;
  scrollTop?: number;
}

export interface INavStyles {
  root: IStyle;
  navItemRoot: IStyle;
  navItemBarMarker: IStyle;
  navItemIconColumn: IStyle;
  navItemNameColumn: IStyle;
  navSlimItemRoot: IStyle;
  navFloatingRoot: IStyle;
  navFloatingItemRoot: IStyle;
  navGroupSeparatorRoot: IStyle;
  navGroupSeparatorHrLine: IStyle;
  navGroupSeparatorHeaderGroupName: IStyle;
  navToggler: IStyle;
}

export interface INavLinkProps extends React.AllHTMLAttributes<HTMLAnchorElement> {
  dataHint?: string;
  dataValue?: string;
  ariaLabel?: string;
  ariaExpanded?: boolean;
  rootClassName?: string;
  leftIconName?: string;
  rightIconName?: string;
  textClassName?: string;
  iconClassName?: string;
  barClassName?: string;
}
