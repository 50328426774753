import React, { useEffect } from 'react';
import * as d3 from "d3";
import Props from './MsxDonutChart.types';
import { getStyles } from './MsxDonutChart.styles';
import * as utils from "./MsxDonutChart.utils";
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const detectSlimLayout = (): boolean => {
  const windowWidth: number = window.innerWidth;
  return windowWidth > 568;
}

const MsxDonutChart: React.FC<Props> = (props) => {

  let activeSlimLayout = !props.slimLayout ? props.slimLayout : detectSlimLayout();
  let currentIndex = props.selectedIndex;
  classes = getClassNames(getStyles, props.theme);


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setCurrentIndex(props.selectedIndex);
    renderChart();
  }, [props.selectedIndex]);

  useEffect(() => {
    // UNMOUNT
    return () => {
      if (window) window.removeEventListener("resize", handleResize);
    }

  }, []);

  const handleResize = (): void => {
    if (!props.slimLayout)
      return;
    activeSlimLayout = detectSlimLayout();
    const element = document.getElementById(props.id);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    renderChart();
  }

  const getCurrenthoverRectId = () => {
    return `${props.id}hoverRect${currentIndex}`;
  }

  const setCurrentIndex = (index: number) => {
    d3
      .select(`#${getCurrenthoverRectId()}`)
      .attr('class',
        (props.slimLayout ? classes.notSelectedSlimLayout : classes.notSelectedTileLayout)
      );
    currentIndex = index;
    d3
      .select(`#${getCurrenthoverRectId()}`)
      .attr('class', classes.selected);

  }

  const legendLabelBoxesCoordinates = () => {
    return !activeSlimLayout
      ? { x: 0, y: 0 }
      : { x: 120, y: 0 }
  }

  const legendLabelTextCoordinates = () => {
    return !activeSlimLayout
      ? { x: 15, y: 10 }
      : { x: 135, y: 10 }
  }

  const legendLabelValuesCoordinates = () => {
    return !activeSlimLayout
      ? { x: 0, y: 45 }
      : { x: 115, y: 50 }
  }


  const renderChart = () => {

    let pie = d3.pie()
      .value(function (d) {
        return d.value
      })
      .sort(null)
      .padAngle(0);

    const outerRadius = 66;
    const innerRadius = 47;
    const graphTotalText = props.totalLabel ? props.totalLabel : 'Total';
    const graphCurrencyText = props.currencyLabel ? props.currencyLabel : 'Currency';

    let domain = [];
    let range = [];
    for (let i = 0; i < props.chartData.length; i++) {
      domain.push(props.chartData[i].name);
      range.push(props.chartData[i].color);
    }

    let color = d3.scaleOrdinal()
      .domain(domain)
      .range(range);

    let arc = d3.arc()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius)

    let svg = d3.select(`#${props.id}`)
      .append("svg")
      .attr('class', classes.svgContainer)
      .append('g')
      .attr('transform', 'translate(' + 73 + ',' + 75 + ')')
      .attr('alt', `${props.title}  donut chart and legend`);

    let path = svg.selectAll('path')
      .data(pie(props.chartData))
      .enter()
      .append('path')
      .attr('d', arc)
      .style('fill', (d, i) => color(d.data.name))
      .attr('stroke', '#fff')
      .attr('stroke-width', '0')
      .style('cursor', 'pointer')
      .attr('alt', `${props.title}  donut chart`)

    path.transition()
      .duration(1000)
      .attrTween('d', function (d) {
        let interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return function (t) {
          return arc(interpolate(t));
        }
      })

    path.on('click', (d, i) => {
      setCurrentIndex(i);
      props.handleGraphClick(d.data.name);
    });

    svg.append('text')
      .attr('aria-label', `${graphTotalText} ${props.ignoreNumberFormat ? props.total : utils.abbrNum(props, props.total.toFixed(2), 1)}`)
      .attr('text-anchor', 'middle')
      .attr("tabindex", "0")
      .attr('role', 'button')
      .text(`${props.ignoreNumberFormat ? props.total : utils.abbrNum(props, props.total.toFixed(2), 1)}`)
      .attr('class', [classes.chartLink, classes.pieTitle].join(' '))
      .on('click', d => {
        setCurrentIndex(-1);
        props.handleGraphClick('');
      })
      .on('keydown', (d, i) => {
        if (d3.event.keyCode === 13 || d3.event.keyCode === 32) {
          setCurrentIndex(i);
          props.handleGraphClick(d);
        }
      })
      .style('cursor', 'pointer')

    svg.append('text')
      .attr("text-anchor", "middle")
      .text(`${graphTotalText}`)
      .attr('aria-hidden', 'true')
      .attr('class', [classes.chartLink, classes.pieSubTitle].join(' '))
      .attr('transform', 'translate(' + 0 + ',' + 20 + ')')
      .on('click', d => {
        setCurrentIndex(-1);
        props.handleGraphClick('');
      })
      .style('cursor', 'pointer')
      .attr('alt', `${props.title}  ${graphTotalText} title`)

    svg.append('text')
      .attr("text-anchor", "middle")
      .attr('class', classes.chartText)
      .text(`${graphCurrencyText} ${props.currency}`)
      .attr('transform', 'translate(' + 0 + ',' + 85 + ')')
      .attr('alt', `${props.title}  amount displayed ${graphCurrencyText}`);

    setTimeout(function () {
      renderLegend(svg, color);
    }, 1000);

  }


  const renderLegend = (svg: any, color: any) => {
    let legendRectSize = 10;
    let legendSpacing = 7;
    let legendHeight: number = legendRectSize + legendSpacing;
    const highlightRectH = !activeSlimLayout ? 75 : 80;
    const highlightRectW = !activeSlimLayout ? 90 : 110;

    let legend = svg.selectAll('.legend')
      .data(color.domain())
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('aria-label', (d, i) => `${d} ${props.ignoreNumberFormat ? props.chartData[i].value : utils.abbrNum(props, props.chartData[i].value.toFixed(2), 1)}`)
      .attr('transform', (d, i) => utils.legendTranslate(props, d, i, legendHeight, activeSlimLayout))
      .attr('alt', `${props.title} donut chart legend`)
      .on('click', (d, i) => {
        d3.event.stopPropagation();
        setCurrentIndex(i);
        props.handleGraphClick(d);
      })
      .on('keydown', (d, i) => {
        if (d3.event.keyCode === 13 || d3.event.keyCode === 32) {
          setCurrentIndex(i);
          props.handleGraphClick(d);
        }
      })
      .style('cursor', 'pointer')

    d3.selectAll('.legend').each(function () {
      this.setAttribute("tabindex", "0");
      this.setAttribute('focusable', 'true');
      this.setAttribute('role', 'button');
    });

    legend.append('rect')
      .attr('aria-hidden', 'true')
      .attr("id", (d, i) => `${props.id}hoverRect${i}`)
      .attr('x', legendLabelBoxesCoordinates().x - (!activeSlimLayout ? 10 : 20))
      .attr('y', legendLabelBoxesCoordinates().y - 20)
      .attr('width', highlightRectW)
      .attr('height', highlightRectH)
      .attr('class', (d, i) => {
        let className = currentIndex == i
          ? classes.selected
          : (props.slimLayout ? classes.notSelectedSlimLayout : classes.notSelectedTileLayout)
        return [className, classes.legendRect].join(' ')
      })
      .on("mouseover", function (d, i) {
        showHoverEffect(i, true);
      })
      .on("mouseout", function (d, i) {
        showHoverEffect(i, false);
      })
      .on('click', (d, i) => {
        setCurrentIndex(i);
      });

    legend.append('rect')
      .attr('aria-hidden', 'true')
      .attr('x', legendLabelBoxesCoordinates().x)
      .attr('y', legendLabelBoxesCoordinates().y)
      .attr('width', legendRectSize)
      .attr('height', legendRectSize)
      .style('fill', color)
      .style('stroke', color)
      .attr('class', (d, i) => {
        return [classes.legendRect].join(' ')
      })
      .on("mouseover", function (d, i) {
        showHoverEffect(i, true);
      })
      .on("mouseout", function (d, i) {
        showHoverEffect(i, false);
      })
      .on('click', (d, i) => {
        setCurrentIndex(i);
      })
      .attr('alt', `${props.title}  donut chart legend`);

    legend.append('text')
      .attr('aria-hidden', 'true')
      .attr('x', legendLabelTextCoordinates().x)
      .attr('y', legendLabelTextCoordinates().y)
      .text(d => `${d}`)
      .attr('class', [classes.chartLink, classes.legendName].join(' '))
      .on('click', (d, i) => {
        d3.event.stopPropagation();
        setCurrentIndex(i);
        props.handleGraphClick(d);
      })
      .style('cursor', 'pointer')
      .on("mouseover", function (d, i) {
        showHoverEffect(i, true);
      })
      .on("mouseout", function (d, i) {
        showHoverEffect(i, false);
      })
      .attr('alt', `${props.title}  donut chart legend filter`);

    // Legend values
    legend.append('text')
      .attr('aria-hidden', 'true')
      .attr('x', legendLabelValuesCoordinates().x)
      .attr('y', legendLabelValuesCoordinates().y)
      .text((d, i) => `${props.ignoreNumberFormat ? props.chartData[i].value : utils.abbrNum(props, props.chartData[i].value.toFixed(2), 1)}`)
      .attr('class', [classes.chartLink, classes.legendValue].join(' '))
      .on('click', (d, i) => {
        d3.event.stopPropagation();
        setCurrentIndex(i);
        d3
          .select(`#${getCurrenthoverRectId()}`)
          .attr('class', classes.selected);
        props.handleGraphClick(d);
      })
      .style('cursor', 'pointer')
      .on("mouseover", function (d, i) {
        showHoverEffect(i, true);
      })
      .on("mouseout", function (d, i) {
        showHoverEffect(i, false);
      })
      .attr('alt', `${props.title}  donut chart legend filter amount`)

  };

  const showHoverEffect = (index: number, enable: boolean) => {
    d3
      .select(`#${props.id}hoverRect${index}`)
      .classed(classes.legendRectIsHover, enable);
  }

  const renderGraphContainer = (): JSX.Element => {
    return (
      <div id={props.id} className={[classes.root].join(' ')}></div>
    );
  }

  return renderGraphContainer();
}

export default MsxDonutChart;
