import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '@msx/platform-types'

export const getStyles = (theme: IAppTheme) => {
  return {
    root: {
      // padding: '0',
      // margin: '0',
      // // display: 'flex',
    },
    // headingMain: {
    //   //color: theme.colors.Text.HeadingMain,
    //   marginLeft: '20px',
    // },
    // tiles: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   alignItems: 'stretch',
    // },
    tile: {
      //margin: '10px 20px 20px 0',
      // // display: 'flex',
      // width: '392px',
      // height: '230px',
    },
  };
};
