import { UserAgentApplication, Configuration } from 'msal';
import { IUser, } from '../../common/models';
import { IAuthClient } from '@msx/platform-types';

import { ITelemetryClient } from '@msx/platform-types';

import { TelemetryEvents } from '../../common/resources/TelemetryEvents';

export class MSALClient implements IAuthClient {
  public readonly authContext: UserAgentApplication;
  private readonly telemetryClient: ITelemetryClient;
  private readonly config: Configuration;

  public constructor(
    options: Configuration,
    telemetryClient: ITelemetryClient
  ) {
    this.config = options;
    this.authContext = new UserAgentApplication({
      ...options,
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
        ...options.cache,
      },
      auth: options.auth,
    });
    this.telemetryClient = telemetryClient;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.authContext.handleRedirectCallback(() => { });
  }

  public async login(): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.telemetryClient.trackTrace({
        message: TelemetryEvents.UserLogInRequested,
      });

      try {
        this.authContext.loginRedirect();
        resolve();
      } catch (ex) {
        this.telemetryClient.trackTrace({
          message: TelemetryEvents.UserLoginFailed,
        });
        reject(ex);
      }
    });
  }

  public async logOut(): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.telemetryClient.trackTrace({
        message: TelemetryEvents.UserLogOutRequested,
      });

      try {
        this.authContext.logout();
        resolve();
      } catch (ex) {
        this.telemetryClient.trackTrace({
          message: TelemetryEvents.UserLogOutFailed,
        });
        reject(ex);
      }
    });
  }

  public async getUser(): Promise<IUser | null> {
    return new Promise((resolve, reject): void => {
      try {
        const user = this.authContext.getAccount();
        if (!user) return resolve(null);

        resolve({
          id: user.userName,
          email: user.userName,
          name: user.name,
          oid: user.accountIdentifier,
        });
      } catch (ex) {
        reject(ex);
      }
    });
  }

  public async getUserId(): Promise<string | null> {
    return new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          const user = await this.getUser();

          resolve(user ? user.id : null);
        } catch (ex) {
          reject(ex);
        }
      }
    );
  }

  public async isLoggedIn(): Promise<boolean> {
    return new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          resolve((await this.getUser()) !== null);
        } catch (ex) {
          reject(ex);
        }
      }
    );
  }

  public async acquireToken(
    scopes: string | string[]
  ): Promise<string | null> {
    return new Promise((resolve, reject): void => {
      // let normalizedScopes: string[] = [];
      // if (typeof scopes === 'string')
      //     normalizedScopes.push(scopes + '/.default');
      // else normalizedScopes = [...scopes];
      const resourceOrScopes = typeof scopes === 'string'
        ? scopes.split(',') : scopes;

      //alert('MSALClient: ' + resourceOrScopes.toString());

      this.authContext
        .acquireTokenSilent({
          scopes: resourceOrScopes, //normalizedScopes,
          authority: this.config.auth?.authority,
          redirectUri:
            (this.config.auth?.redirectUri as string) ||
            window.location.origin,
        })
        .then((tokenResponse) => {
          resolve(tokenResponse.accessToken);
        })
        .catch((ex) => {
          // this.authContext.acquireTokenRedirect({
          //   scopes: resourceOrScopes, //normalizedScopes,
          //   authority: this.config.auth?.authority,
          //   redirectUri:
          //     (this.config.auth?.redirectUri as string) ||
          //     window.location.origin,
          // });
          reject(ex);
        });
    });
  }

  private isIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ') > -1;
    const msie11 = ua.indexOf('Trident/') > -1;

    return msie || msie11;
  }
}
