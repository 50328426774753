import React, { useState, useEffect, useRef } from 'react';
import { Stack, DefaultButton, IconButton } from 'office-ui-fabric-react';
import TableFilterDropdown, { FilterChangeData } from '../TableFilterDropdown/TableFilterDropdown';
import { getTableFilterDropdownCollectionStyles } from './TableFilterDropdownCollection.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import {IAppTheme} from '../../../theme/Theme.types'
const getClassNames = classNamesFunction<any, any>();
let classes: any;

export interface Props {
  items: any[];
  columns: any[];
  maxFilters: number; // Max allowed filter dropdowns.
  onColumnSorted?: (data: any) => void;
  onChange: any;
  intl?: any; // This will be injected by injectIntl.
  theme:IAppTheme;
}

export const TableFilterDropdownCollection: React.FC<Props> = props => {
  //const { intl } = props;
  classes = getClassNames(getTableFilterDropdownCollectionStyles(props.theme))
  const addFilterAriaLabel = 'Add Filter';
  const removeFilterAriaLabel = 'Remove Filter';
  const applyFilterButtonText = 'Apply Filter';
  const clearFilterButtonText = 'Clear Filter';


  // Key index to use with colleciton of dropdowns.
  // This will keep incrementing when adding filters, even after users clear the filters. It is just used as a unique key for each dropdown.
  const keyIndexRef = useRef<number>(0);

  const filterChangeDataMapRef = useRef<Map<number, FilterChangeData>>(new Map<number, FilterChangeData>());

  const renderNewFilter = (includeRemoveButton: boolean): JSX.Element => {
    const keyIndex: number = keyIndexRef.current++;
    const elem: JSX.Element = (
      <Stack key={keyIndex} horizontal tokens={{ childrenGap: 10 }}>
        <TableFilterDropdown
          theme={props.theme}
          altKey={keyIndex}
          items={props.items}
          columns={props.columns}
          onFilterChange={handleFilterChange}
        />

        {
          (includeRemoveButton) ?
            <IconButton
              iconProps={{ iconName: "Remove" }}
              title={removeFilterAriaLabel}
              ariaLabel={removeFilterAriaLabel}
              onClick={() => { handleRemoveFilter(keyIndex) }}
              className={classes.addRemoveIconButton}
            />
            : null
        }

      </Stack>
    );

    return elem;
  }

  const handleFilterChange = (data: FilterChangeData) => {
    // In React you cannot reach into a child component to get its state, which is why we have this handleFilterChange
    // event handler to raise an event from the child component and capture the state here in the higher order component.
    // Store the filter selection into this map.
    filterChangeDataMapRef.current.set(data.altKey, data);
  }

  const handleAddFilter = () => {
    setCollection(prev => ([...prev, renderNewFilter(true)]));
  }

  const handleRemoveFilter = (keyIndex: number) => {
    // Note, using collectionRef here and not collection as it has the state from when the component referencing this
    // function was rendered. See comments above where collectionRef is declared.
    let newCollection = collectionRef.current.slice(0);
    let index = newCollection.findIndex((elem) => { return elem.key === keyIndex + ''; }); // The + '' is to convert the key to a string.
    if (index > -1) {
      newCollection.splice(index, 1);
      setCollection(newCollection);
    }

    // Delete from the filter map.
    if (filterChangeDataMapRef.current.has(keyIndex)) {
      filterChangeDataMapRef.current.delete(keyIndex);
    }
  }

  // A collection of dropdown elements.
  // Important to have this line placed below above lines, as renderNewFilter references functions like handleFilterChange and
  // handleRemoveFilter which need to be defined above before calling renderNewFilter - otherwise those are undefined in renderNewFilter.
  const [collection, setCollection] = useState<JSX.Element[]>([renderNewFilter(false)]);

  // Make a ref for the collection to store the current state across renders.
  // See https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
  // "Any function inside a component, including event handlers and effects, “sees” the props and state from the
  // render it was created in."
  // If we use 'collection' in functions like handleRemoveFilter then it would be the state of the 'collection'
  // when the component was rendered.
  // Ex: In "<IconButton onClick={() => { handleRemoveFilter(keyIndex) }} />" if 'collection' referenced inside handleRemoveFilter
  // then it would be the 'collection' from when that element was added.
  // See: https://reactjs.org/docs/hooks-reference.html#useref
  const collectionRef = useRef<JSX.Element[]>(collection);

  // This effect will update the collectionRef with the current collection when the collection changes.
  useEffect(() => {
    // See: https://stackoverflow.com/questions/58562455/react-state-variable-not-accurate-after-set-state-in-usestate-hook
    collectionRef.current = collection;
  }, [collection]);

  const getFilteredRows = (items: any[]): any[] => {
    let filteredItems: any[] = [...items];

    filterChangeDataMapRef.current.forEach((filterChangeData) => {
      if (filterChangeData.column !== '' && filterChangeData.filter !== '') {
        filteredItems = filteredItems.filter(item => {
          if (item[filterChangeData.column] === undefined && item.isNRC === false) {
            return item.priceLine.some(priceLine => priceLine[filterChangeData.column] == filterChangeData.filter)
          } else {
            return item[filterChangeData.column] == filterChangeData.filter
          }
        })
      }
    });

    return filteredItems;
  }

  const handleClearFilters = () => {
    // Clear the filter map.
    filterChangeDataMapRef.current.clear();
    setCollection([renderNewFilter(false)]);

    props.onChange(props.items)
    props.onColumnSorted(props.items)
  }

  const handleApplyFilters = () => {
    props.onChange(getFilteredRows(props.items))
    if (props.onColumnSorted) {
      props.onColumnSorted(getFilteredRows(props.items));
    }
  }

  return (
    <Stack>
      <Stack horizontal wrap className={classes.collectionStack} tokens={{ childrenGap: 10 }}>
        {
          collection.map((elem) => {
            return elem;
          })
        }

        {
          // Only add an Add button if the maxFilters is not yet reached.
          (collection.length < props.maxFilters) ?
            <IconButton
              iconProps={{ iconName: "Add" }}
              ariaLabel={addFilterAriaLabel}
              onClick={() => { handleAddFilter() }}
              className={classes.addRemoveIconButton}
            />
            : null
        }
      </Stack>

      <Stack horizontal wrap className={classes.collectionActionButtonStack} tokens={{ childrenGap: 10 }}>
        <DefaultButton
          className={classes.collectionActionButton}
          text={applyFilterButtonText}
          onClick={handleApplyFilters}
        />
        <DefaultButton
          className={classes.collectionActionButton}
          text={clearFilterButtonText}
          onClick={handleClearFilters}
        />
      </Stack>

    </Stack>
  )
}

export default TableFilterDropdownCollection;
