import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '../../theme/Theme.types'

export interface IMsxModalProps {
  title: string;
  height: MsxModalHeight;
  isBlocking?: boolean;
  onDismiss?: (ev?: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement>) => any;
  onRenderSubHeader?: () => JSX.Element;
  closeButtonTooltipText?: string;
  theme?: IAppTheme;
}

export interface IMsxModalState {
  closeModal: boolean;
  announcedMessage?: string;
}

export interface IMsxModalStyles {
  headerArea: IStyle;
  headerRow: IStyle;
  contentArea: IStyle;
  titleTextContainer: IStyle;
  titleText: IStyle;
  closeIconContainer: IStyle;
  closeIcon: IStyle;
}

export enum MsxModalHeight {
  Min,
  Max,
}
