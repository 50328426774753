import React, { useState, useEffect } from 'react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsList,
  SelectionMode,
  IDetailsHeaderProps,
  IDetailsRowProps,
  ConstrainMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { IRenderFunction } from '@uifabric/utilities';
import { customRowStyle, customListStyle, customHeaderStyles } from '../../../theme/styles/DetailsList.styles';
import { SortListItems } from '../../../utils/helpers';
import { TooltipHost } from 'office-ui-fabric-react';
import { ITooltipHostProps } from "office-ui-fabric-react/lib/Tooltip";
import { Announced, IAnnouncedProps } from 'office-ui-fabric-react/lib/Announced';
import { getFabricTableStyles } from './FabricTable.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IAppTheme } from '../../../theme/Theme.types'

const getClassNames = classNamesFunction<any, any>();
let classes: any;

export interface Props {
  items: any[];
  columns: Partial<IColumn>[];
  theme: IAppTheme;
  title?: string;
  height?: string;
  onColumnSorted?: (data: any) => void;
  disableSorting?: boolean;
}

const FabricTable: React.FC<Props> = (props) => {
  const [announced, setAnnounced] = useState<IAnnouncedProps>(<Announced message={''} />);
  const [items, setItems] = useState(props.items);

  classes = getClassNames(getFabricTableStyles(props.theme));

  useEffect(() => {
    setItems(props.items);
  }, [props.items])

  const formatColumns = (columns: Partial<IColumn>[]): IColumn[] => {
    const defaultMinWidth = 75;
    const defaultMaxWidth = 100;
    const defaultIsResizable = true;

    return columns.map(col => {
      col.minWidth = col.minWidth ? col.minWidth : defaultMinWidth;
      col.maxWidth = col.maxWidth ? col.maxWidth : defaultMaxWidth;
      col.isResizable = col.isResizable ? col.isResizable : defaultIsResizable;
      return col as IColumn;
    });
  }


  const [columns, setColumns] = useState(formatColumns(props.columns));
  const _detailsList = React.createRef<IDetailsList>();


  /*
   * On clicking a column, we set its isSorted flag to true and revert all other
   * columns to false since we can only sort by one column. For the selected column,
   * we toggle the sorting orientation (ascending vs. descending) and sort its items
   * accordingly.
   */
  const onColumnClick = (event: React.MouseEvent<HTMLElement>, columnClicked: IColumn): void => {
    if (props.disableSorting === true)
      return;
    setColumns(() => {
      return columns.map(c => {
        c.isSorted = (c.key === columnClicked.key); // On clicking a column, we set its isSorted flag to true and revert all other columns

        if (c.isSorted) {
          c.isSortedDescending = !c.isSortedDescending;
          setItems(
            SortListItems(
              items,
              c.fieldName || '',
              c.isSortedDescending,
              undefined
            ));
          const announcedMessage = `${c.name} is sorted in ${c.isSortedDescending ? 'descending' : 'ascending'} order`;
          const sortedData = SortListItems(props.items, c.fieldName || '', c.isSortedDescending, undefined);
          setItems(sortedData);
          if (typeof props.onColumnSorted === 'function') {
            props.onColumnSorted(items);
          }
          setAnnounced(<Announced message={announcedMessage} />);
        }


        return c;
      });
    });
  };

  const renderNoColumnsMessage = () => {
    if (!columns)
      return (
        <p style={{ marginTop: '30px', textAlign: 'center' }} id="no-data">
          Oops! no column header found.
        </p>
      );
  };

  const renderNoData = () => {
    if (items.length === 0)
      return (
        <p style={{ margin: '30px', textAlign: 'center' }} >
          There is no data available for selected supplier and company code.
        </p>
      );
  }

  const onRenderColumnHeaderTooltip = (tooltipProps: ITooltipHostProps) => {
    return <TooltipHost {...tooltipProps} />
  }

  const onRenderDetailsHeader = (
    detailsHeaderProps: IDetailsHeaderProps,
    defaultRender: IRenderFunction<IDetailsHeaderProps>
  ) =>
  (
    defaultRender({
      ...detailsHeaderProps,
      styles: customHeaderStyles(props.theme),
      onRenderColumnHeaderTooltip: onRenderColumnHeaderTooltip
    })
  );

  const onRenderRow = (
    rowProps?: IDetailsRowProps,
    defaultRender?: IRenderFunction<IDetailsRowProps>
  ) => (
    // defaultRender && rowProps
    //   ?
    //   defaultRender({ ...rowProps, styles: customRowStyle(props.theme) }, defaultRender)
    //   : null
    defaultRender({
      ...rowProps,
      styles: customRowStyle(props.theme)
    })
  );

  const render = (): JSX.Element => {
    const howToSortMessage = 'please press enter when focused on a column header to sort the data by this column';
    const gridAriaLabel = props.title + '. ' + howToSortMessage;
    renderNoColumnsMessage();
    return (
      <React.Fragment>
        {announced}
        <DetailsList
          items={items}
          columns={columns}
          styles={customListStyle(props.theme)}
          compact={true}
          selectionMode={SelectionMode.none}
          onRenderRow={onRenderRow}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          componentRef={_detailsList}
          setKey="set"
          onColumnHeaderClick={onColumnClick}
          onRenderDetailsHeader={onRenderDetailsHeader}
          ariaLabelForGrid={gridAriaLabel}
          constrainMode={ConstrainMode.unconstrained}
        />
        {renderNoData()}
      </React.Fragment>
    );
  }

  return render();
}

export default FabricTable;
