import React from 'react';
import { CSVLink } from 'react-csv';
import { Icon, Stack, ActionButton } from 'office-ui-fabric-react';
import Props from './ExportCsv.types';
import { getStyles } from './ExportCsv.styles';
import { Data } from 'react-csv/lib/metaProps';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
const getClassNames = classNamesFunction<any, any>();
let classes: any;

export const isFilenameValid = (filename: string) => {
  if (filename) {
    const splitStr = filename.split('.');
    return splitStr[splitStr.length - 1] === 'csv';
  }
  return false;
}

export const isDataEmpty = (data: string | Data) => {
  if (data.length === 0) return true;
  return false;
}

const renderDefault = (classes: any, buttonText: string) => {
  const btnIconGap = 5;
  return (
    <ActionButton tabIndex={-1}>
      <Stack
        className={classes.csvBtnRoot}
        horizontal
        gap={btnIconGap}
        verticalAlign={'center'}
      >
        <Stack.Item>
          <span className={classes.csvBtnText}>{buttonText}</span>
        </Stack.Item>
        <Stack.Item className={classes.csvBtnIcon}>
          <Icon iconName="Export" />
        </Stack.Item>
      </Stack>
    </ActionButton >
  );
}

export const ExportCsv: React.FC<Props> = (props) => {
  const { data, filename, target, asyncOnClick, onClick, onRender, headers } = props;
  const buttonText = props.exportButtonLabel ? props.exportButtonLabel : 'Export to CSV';
  classes = getClassNames(getStyles(props.theme));
  if (isFilenameValid(filename) && !isDataEmpty(data)) {
    return (
      <CSVLink
        data={data}
        filename={filename}
        target={target ? target : '_blank'}
        asyncOnClick={asyncOnClick}
        onClick={onClick}
        headers={headers}
        enclosingCharacter={`"`}
        role='button'
        aria-label='Export to csv (this will download a csv file)'
      >
        {onRender ? onRender() : renderDefault(classes, buttonText)}
      </CSVLink>
    );
  } else {
    return null;
  }
}

export default ExportCsv;
