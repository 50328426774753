import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import { getStyles } from './MsxPageLayout.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IAppTheme } from '../../theme/Theme.types'


interface OwnProps {
  Title?: any;
  Heading?: any;
  Body?: any;
  Footer?: any;
  modal?: boolean;
  theme: IAppTheme;
}

const getClassNames = classNamesFunction<any, any>();
let classes: any;

type Props = OwnProps;

class MsxPageLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    classes = getClassNames(getStyles(props.theme));

  }

  renderTitle() {
    const { Title, modal } = this.props;
    return Title
      ?
      <Stack
        horizontal
        wrap
        className={[modal ? classes.titleRowModal : classes.titleRow].join(' ')}
      >
        {Title}
      </Stack>
      : null;
  }

  renderHeading() {
    const { Heading, modal } = this.props;
    return Heading
      ?
      <Stack
        horizontal
        wrap
        className={[modal ? classes.modalHeadingComponent : classes.headingComponent].join(' ')}
      >
        {Heading}
      </Stack>
      : null;
  }

  renderBody() {
    const { Body, } = this.props;
    return Body
      ?
      <Stack
        horizontal
        wrap
        className={[classes.table].join(' ')}
      >
        {Body}
      </Stack>
      : null;
  }

  renderFooter() {
    const { Footer } = this.props;
    return Footer
      ? <Stack horizontal wrap><Footer /></Stack>
      : null;
  }

  render() {
    const { modal } = this.props;
    const stackGap: number = modal ? 0 : 20;
    return (
      <Stack className={[classes.root].join(' ')} gap={stackGap}>
        <Stack.Item>
          <Stack gap={stackGap} className={classes.header}>
            {this.renderTitle()}
            {this.renderHeading()}
          </Stack>
          <Stack className={modal ? classes.modalBody : classes.body}>
            {this.renderBody()}
            {this.renderFooter()}
          </Stack>
        </Stack.Item>
      </Stack>
    );
  }
}

export default MsxPageLayout;
