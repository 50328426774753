import * as React from 'react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import MsxDetailsList from './MsxDetailsList';
import { getStyles } from './MsxTable.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Icon, Stack, IColumn, ActionButton } from 'office-ui-fabric-react';
import { IAppTheme } from '../../theme/Theme.types'

const Window = window as any;

export interface OwnProps<T> {
  rows: T[];
  customCsvData?: any[];
  columns: Partial<IColumn>[];
  tableTitle?: string;
  isLoading?: boolean;
  onColumnSorted?: (data: any) => void;
  customHeight?: string;
  disableExport?: boolean;
  disableSorting?: boolean;
  onCsvExported?: () => void;
  theme?: IAppTheme;
}

const getClassNames = classNamesFunction<any, any>();
let classes: any;

type Props<T> = OwnProps<T>;

export class MsxTable<T> extends React.Component<Props<T>> {
  constructor(props: Props<T>) {
    super(props);
    classes = getClassNames(getStyles(props));

  }

  componentDidUpdate() {
    if (Window.isClickedSeeAll)
      document.getElementById('tableTitleHeader').focus();

    Window.isClickedSeeAll = false;
  }
  handleExportCsvClick = (): void => {
    if (typeof this.props.onCsvExported === 'function') {
      this.props.onCsvExported();
    }
  }

  renderTable = (): JSX.Element => {
    const { isLoading, rows, columns, customHeight, onColumnSorted, disableSorting } = this.props;
    //const defaultHeight = '100%';
    const tableTitleWithoutNumbers = this.props.tableTitle ? this.props.tableTitle.split('(')[0].trim() : '';
    return (isLoading && rows.length === 0)
      ? <Spinner label="loading..." />
      : <div className={classes.tableStackItem}>
        <MsxDetailsList
          columns={columns}
          items={rows}
          title={tableTitleWithoutNumbers}
          //height={customHeight ? customHeight : defaultHeight}
          disableSorting={disableSorting}
          onColumnSorted={onColumnSorted}
          theme={this.props.theme}
        />
      </div>
  }

  renderTableTitle = (): JSX.Element => {
    const { tableTitle } = this.props;
    if (tableTitle) {
      return (
        <Stack.Item align={'start'}>
          <h2 className={classes.tableTitle} id='tableTitle' tabIndex={-1}>
            {tableTitle}
          </h2>
        </Stack.Item>
      );
    }
  }

  renderTableHeader = (): JSX.Element => {
    const tableTitle = this.renderTableTitle();
    if (tableTitle) {
      const headingGap = '40%';
      return (
        <Stack.Item>
          <Stack horizontal horizontalAlign="space-between">
            {this.renderTableTitle()}
          </Stack>
        </Stack.Item>
      );
    }
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <Stack className={classes.rootStack}>
          {this.renderTableHeader()}
          {this.renderTable()}
        </Stack>
      </React.Fragment >
    );
  }
}

export default MsxTable;
