import * as React from 'react';
import { ICardProps } from './Card.types';
import { CardFrame } from './CardFrame';
import { getStyles } from './DxpCard.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IDxpCardStyles, IDxpCardProps } from './DxpCard.types';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export type DxpCardProps = ICardProps & IDxpCardProps;

const getClassNames = classNamesFunction<DxpCardProps, IDxpCardStyles>();
let classNames = getClassNames(getStyles);

export class DxpCard extends React.Component<DxpCardProps> {
  constructor(props: DxpCardProps) {
    super(props);

    classNames = getClassNames(getStyles, this.props);
  }

  public render(): JSX.Element {
    const { disableDrag, cardFrameContent } = this.props;
    const contentElement: JSX.Element | null = this.getContentArea();
    const fontFamily: string = '"Segoe UI Semibold", "Segoe UI Web (West European)", "Segoe UI",' +
      ' -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif';

    return (
      <div className={classNames.root}>
        <CardFrame
          cardTitle={cardFrameContent.cardTitle}
          disableDrag={disableDrag === undefined ? true : disableDrag}
          fontFamily={fontFamily}
          onExpandClick={this.props.onExpandClick}
          onViewMoreClick={this.props.onViewMoreClick}
          id={`CardFrame${this.props.id}`}
          tileId={this.props.tileId}
        >
          <div className={classNames.layoutRoot} >
            <div className={classNames.contentAreaLayout}>{contentElement}</div>
          </div>
        </CardFrame>
      </div>
    );
  }

  private getContentArea(): JSX.Element | null {
    if (this.props.loading) {
      return this.getLoadingContent();
    } else if (this.props.noDataSettings && this.props.noDataSettings.isVisible) {
      return (
        <div
          className={classNames.noDataContent}
          onClick={() => this.onClick()}
          onKeyPress={this.onKeyPress}
          tabIndex={0}
          role={'button'}
        >
          {this.getNoDataIcon()}
          {this.getNoDataMessage()}
        </div>
      );
    } else {
      return <div className={classNames.contentArea1}>{this.props.children}</div>;
    }
  }

  private onClick() {
    if (this.props.noDataSettings && this.props.noDataSettings.noDataAction) {
      this.props.noDataSettings.noDataAction();
    }
  }

  private onKeyPress = (e: React.KeyboardEvent) => {
    // This is to only allow space and enter to trigger the modal
    if (e.charCode === 13 || e.charCode === 32) {
      this.onClick();
    }
  }

  private getNoDataIcon() {
    if (this.props.noDataSettings && this.props.noDataSettings.icon) {
      return <Icon iconName={this.props.noDataSettings.icon} className={classNames.noDataIcon} />;
    } else {
      return <Icon iconName="Document" className={classNames.noDataIcon} />;
    }
  }

  private getNoDataMessage() {
    if (this.props.noDataSettings && this.props.noDataSettings.message) {
      return this.props.noDataSettings.message;
    } else {
      return 'There is no data available for this view.';
    }
  }

  private getLoadingContent() {
    return (
      <div className={classNames.loading}>
        <Spinner size={SpinnerSize.large} />
      </div>
    );
  }
}
