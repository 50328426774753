import { IAppContext } from "../..";
import { InjectedIntlProps } from 'react-intl';
import { RouteProps, RouterProps } from "react-router";
import {
  IAppAuthContext,
  IAppHttpContext,
  IAppLocale,
  IAppNavigationContext,
  IAppState,
  IAppTheme,
  IAppUser,
  IConfigurationContext,
  IKeyValueItem,
  ISupplierContext,
} from "@msx/platform-types";

import {
  IAppExtensionContext,
  IDashboardTile,
  IExtensionComponent,
  IUser
} from "../../common/models";
import { IHttpClient, IHttpClientRequest, IGraphClient, IAuthClient } from '@msx/platform-types';

import { ITelemetryClient } from '@msx/platform-types';

import { UserEvent } from "../../common/models/UsageTelemetry";
import { ExtensionErrorTypes, ExtensionEventTypes, ExtensionKeys, } from "./Extension.types";
import { AttributeProps } from '@msx/platform-types';
import { EventType } from '../../common/models/UsageTelemetry/UsageEvent';
import { MemCache } from "../../core/CacheClient/MemCache";
//import { AxiosResponse } from "axios";
//import { useHistory } from "react-router-dom";
import { NavigationRouteProps, NavigationParamProps, NotificationProps } from './Extension.types';
import { IExtensionsSchemaLoader } from "./ExtensionSchemaLoader.types";
import { INotificationItem } from "@msx/platform-types/dist/common/models";

export interface IAppContextProps {
  //user: IUser;
  graphClient: IGraphClient;
  authClient: IAuthClient,
  httpClient: IHttpClient,
  telemetryClient: ITelemetryClient,
  extensionsSchemaLoader: IExtensionsSchemaLoader,
  componentSchema: IExtensionComponent,
  //location: RouteProps["location"],
  //intl: InjectedIntlProps["intl"],
  params: any,
  //theme: IAppTheme,
  appState: IAppState,
  //locale: string;
  userDashboardTiles: IDashboardTile[],
  onNotify?: (item: NotificationProps) => void,
  onNavigateToRoute: (route: NavigationRouteProps) => void,
  onLaunchFeedback?: (extensionKey: string) => void;
  onLaunchChatBot?: (extensionKey: string) => void;
  onValidateAttributes: (attributes: AttributeProps) => boolean;
  config: {
    [key: string]: any;
  };
}

export class AppContext implements IAppExtensionContext {
  private graphClient: IGraphClient;
  private authClient: IAuthClient;
  private httpClient: IHttpClient;
  private telemetryClient: ITelemetryClient;
  private extensionsSchemaLoader: IExtensionsSchemaLoader;
  private componentSchema: IExtensionComponent;
  //private location: RouteProps["location"];
  //private intl: InjectedIntlProps["intl"];
  private params: any;
  //private theme: IAppTheme;
  private appState: IAppState;
  private userDashboardTiles: IDashboardTile[];
  //private locale: string;
  private user: IUser;
  //TODO: VERIFY LATER
  // private supplierId = "fakesupplier001";
  // private companyCode = "fakecompany001";
  private onNavigateToRoute: (route: NavigationRouteProps) => void;
  private onNotify: (item: NotificationProps) => void;
  private onLaunchFeedback: (extensionKey: string) => void;
  private onLaunchChatBot: (extensionKey: string) => void;
  private onValidateAttributes: (attributes: AttributeProps) => boolean;

  private config: {
    [key: string]: any;
  };

  public constructor(props: IAppContextProps
  ) {
    // this.user = props.user;
    this.graphClient = props.graphClient;
    this.authClient = props.authClient;
    this.httpClient = props.httpClient;
    this.telemetryClient = props.telemetryClient;
    this.extensionsSchemaLoader = props.extensionsSchemaLoader;
    this.componentSchema = props.componentSchema;
    //this.intl = props.intl;
    //this.location = props.location;
    this.params = props.params;
    //this.theme = props.theme;
    this.appState = props.appState;
    this.userDashboardTiles = props.userDashboardTiles;
    //this.locale = props.locale;
    this.onNavigateToRoute = props.onNavigateToRoute;
    this.onNotify = props.onNotify;
    this.onLaunchChatBot = props.onLaunchChatBot;
    this.onLaunchFeedback = props.onLaunchFeedback;
    this.onValidateAttributes = props.onValidateAttributes;
    this.config = props.config;
  }

  public getLocale(): IAppLocale {
    return {
      localeName: this.appState.locale
    };
  }
  public setInDashboardEditMode(inDashboardEditMode: boolean, inDashboardEditTiles: IDashboardTile[]): void {
    this.appState.inDashboardEditMode = inDashboardEditMode;
    this.appState.inDashboardEditTiles = inDashboardEditTiles;
  }
  public setLocale(locale: string): void {
    this.appState.locale = locale;
  }

  public addToLocale(data: IKeyValueItem[]): void {
    //TODO: DO NOTHING
  }

  public getParams(): IKeyValueItem[] {
    return this.params;
  }
  public getTheme(): IAppTheme {
    return this.appState.theme;
  }

  private isInDashboardEditMode = (): boolean => {
    if (!this.appState.inDashboardEditMode) {
      return false;
    }
    if (!this.appState.inDashboardEditTiles) {
      return false;
    }
    const tile = this.appState.inDashboardEditTiles.find(item => item.id === this.componentSchema.key);
    const result = tile ? true : false;
    if (this.appState.inDashboardEditMode) {
      const deleteButtonId = `Remove${this.componentSchema.key}`;
      this.changeVisibility(deleteButtonId, result);
      const settingsButtonId = `Settings${this.componentSchema.key}`;
      this.changeVisibility(settingsButtonId, result);
    }
    return result;
  }

  private changeVisibility(id: string, show: boolean) {
    const element: any = document.getElementById(id);
    if (element) {
      element.style.visibility = !show ? "hidden" : "visible";
    }
  }

  public getAppState(): IAppState {
    const result: IAppState = {
      isReady: this.appState.isReady,
      hasSupplier: this.appState.hasSupplier,
      inDashboardEditMode: this.isInDashboardEditMode(),
    }
    //return result;
    return this.appState;
  }

  public getConfigurationContext(): IConfigurationContext {
    return {
      getConfigurations: (): IKeyValueItem[] => this.getExtensionConfigItems(),
    }
  }

  public getSupplierContext(): ISupplierContext {
    return {
      isValidSupplier: (supplierId: string, companyCode: string): boolean => this.isValidSupplier(supplierId, companyCode),
    }
  }

  public getNavigationContext(): IAppNavigationContext {
    return {
      navigate: (key: string, params?: IKeyValueItem[]) => this.navigateToRoute(key, params),
      canNavigate: (key: string) => this.canNavigateToRoute(key)
    }
  }

  public getAuthContext(): IAppAuthContext {
    return {
      profile: this.getUser(),
      getToken: (resourceOrScopes?: string | string[]): any => this.getToken(resourceOrScopes),
    }
  }

  public notify(item: INotificationItem): void {
    if (typeof this.onNotify === 'function') {
      this.logEvent(ExtensionEventTypes.NOTIFY_ITEM_INVOKED);
      try{
        this.onNotify({
          component: this.componentSchema.component,
          componentKey: this.componentSchema.key,
          extensionKey: this.componentSchema.extensionKey,
          notificationItem: item
        });
      }
      catch(ex){
        this.logEvent(ExtensionEventTypes.NOTIFY_ITEM_FAILED, ex);
      }
    }
  }


  private normalizeKeys(key: string): string {
    return key.replace(/[^a-zA-Z0-9]/g, '_');
  }

  public getHttpClient(): IHttpClient {
    return this.httpClient;
  }

  public getAuthClient(): IAuthClient {
    return this.authClient;
  }

  public getTelemetryClient(): ITelemetryClient {
    return this.telemetryClient;
  }

  public getGraphClient(): IGraphClient {
    return this.graphClient;
  }

  public getHttpContext(): IAppHttpContext {
    return {
      get: (url: string, config: any, forceReload?: boolean) => {
        let cacheKey = this.normalizeKeys(url);
        let addNewRequestHeaders = true;
        if (config.params) {
          for (const [key, value] of Object.entries(config.params)) {
            cacheKey += `_${this.normalizeKeys("" + value)}`;
          }
        } else {
          // to support old extensions
          for (const [key, value] of Object.entries(config)) {
            if (key.includes('headers')) {
              addNewRequestHeaders = false;
            } else {
              cacheKey += `_${value}`;
            }
          }
        }
        if (!forceReload && MemCache.exist(cacheKey)) {
          return MemCache.get(cacheKey);
        }
        const res = this.httpGet(addNewRequestHeaders, url, config)
        MemCache.set(cacheKey, res);
        //console.log('cacheKey', cacheKey);
        return res;
      },
      post: (url: string, data: any, config: any) => {
        return this.httpPost(url, data, config)
      },
      delete: (url: string, config: any) => {
        return this.httpDelete(url, config)
      },
    }
  }

  public getTelemetryContext() {
    return {
      logEvent: (event: string, data?: any) => this.logEvent(event, data),
      getProps: (config: any) => this.getProps(config)
    }
  }

  public launchFeedback() {
    if (typeof this.onLaunchFeedback === 'function') {
      this.logEvent(ExtensionEventTypes.FEEDBACK_LAUNCHER_INVOKED);
      this.onLaunchFeedback(this.componentSchema.extensionKey);
    }
  }

  public launchChatBot() {
    if (typeof this.onLaunchChatBot === 'function') {
      this.logEvent(ExtensionEventTypes.CHAT_BOT_INVOKED);
      this.onLaunchChatBot(this.componentSchema.extensionKey);
    }
  }

  private getProps(config: any) {
    return config;
  }

  private logEvent(event: string, data?: any) {
    const userEvent: UserEvent = {
      eventName: event,
      type: EventType.User
    }
    this.telemetryClient.trackEvent(userEvent, data);
  }

  // This is to support v1 http client. 
  // Need to remove its refernce call once all client extension upgrade to v2
  private makeCompatibleResponse(response: any, config: any) {
    response.config = config;
    if (!response.config) response.config = { data: {} };
    if (!response.config.data) response.config.data = {};
    return response;
  }

  private httpDelete = async (url: string, config: any) => {
    let response: any = await this.httpClient.delete(url, config);
    response = this.makeCompatibleResponse(response, config);
    return response;
  }

  private httpPost = async (url: string, data: any, config: any) => {
    let response: any = await this.httpClient.post(url, config);
    response = this.makeCompatibleResponse(response, config);
    return response;
  }

  private httpGet = async (addNewRequestHeaders: boolean, url: string, config: any) => {
    let response: any;
    if (addNewRequestHeaders) {
      let params = config ? config : {};
      if (params.params) {
        params = params.params;
      }
      const resource = this.appState.appConfig.apiConfig.resource;
      const token = await this.authClient.acquireToken(resource);
      const request: IHttpClientRequest = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: params,
      };
      //console.log('somnath httpGet adding header ', url, request);
      response = await this.httpClient.get(url, request);
    }
    else {
      if (!config.data) config.data = {};
      //console.log('somnath httpGet header exists ', url, config);
      response = await this.httpClient.get(url, config);
    }
    response = this.makeCompatibleResponse(response, config);
    return response;
  }



  private getToken = async (resourceOrScopes: string | string[]) => {
    const resource = typeof resourceOrScopes === 'string' ? resourceOrScopes.split(',') : resourceOrScopes;
    const token = await this.authClient.acquireToken(resource);
    return token;
  }

  public getUser() {
    const user = this.appState.user;
    const result: any = {
      id: user?.id ?? 'userid',
      mail: user?.email ?? 'useremail',
      displayName: user?.name ?? 'username',
      msa: false, //TODO: VERIFY LATER WHETHER IT IS REQUIRE OR NOT
      oid: user?.oid ?? 'oid',
    }
    return result;
  }

  private canNavigateToRoute(key: string) {
    const route = this.componentSchema.appRoutes.find(item => item.key === key && item.active);
    return route ? true : false;
  }

  private navigateToRoute(key: string, params?: IKeyValueItem[]): void {
    if (this.appState.inDashboardEditMode) return;

    const route = this.componentSchema.appRoutes.find(item => item.key === key && item.active);
    if (!route) {
      const message = `No such route key ${key} exists in the route registration schema.`;
      const exception = {
        exception: new Error(message),
      }
      const customProperties = {
        extension: this.componentSchema.extensionName,
        error: ExtensionErrorTypes.ROUTE_REGISTRATION_NOT_FOUND_ERROR,
        routeKey: key,
      };
      this.telemetryClient.trackException(exception, customProperties);

      return;
    }
    if (params) {
      const queryParams = [];
      const newParams: NavigationParamProps = {};
      params.forEach((p) => {
        queryParams.push(encodeURIComponent(p.key) + '=' + encodeURIComponent(p.value))
        Object.assign(newParams, { [p.key]: p.value });
      });
      const search = queryParams.join('&');
      const newPath = { pathname: `/${route.routePath}`, search: search };
      const component = this.extensionsSchemaLoader.getExtensionComponentByRouteKey(route.key);
      this.onNavigateToRoute({ location: newPath, componentKey: component?.key ?? null, params: newParams });
    }
    else {
      const queryParams = [];
      const newParams: NavigationParamProps = {};
      const search = queryParams.join('&');
      const newPath = { pathname: `/${route.routePath}`, search: search };
      const component = this.extensionsSchemaLoader.getExtensionComponentByRouteKey(route.key);
      this.onNavigateToRoute({ location: newPath, componentKey: component?.key ?? null, params: newParams });
    }
  }

  private isValidSupplier(supplierId: string, companyCode: string): boolean {
    let result = true;
    if (typeof this.onValidateAttributes === 'function') {
      this.logEvent(ExtensionEventTypes.SUPPLIER_VALIDATOIN_INVOKED);
      try {
        result = this.onValidateAttributes({ supplierId, companyCode });
      }
      catch { }
    }
    return result;
  }

  private getExtensionConfigItems(): IKeyValueItem[] {
    const result: IKeyValueItem[] = [
      { key: 'EXTENSION_KEY', value: this.componentSchema ? this.componentSchema.extensionKey : 'N/A' },
      { key: 'EXTENSION_NAME', value: this.componentSchema ? this.componentSchema.extensionName : 'N/A' },
      { key: 'COMPONENT_KEY', value: this.componentSchema ? this.componentSchema.key : 'N/A' },
      { key: 'COMPONENT_NAME', value: this.componentSchema ? this.componentSchema.name : 'N/A' },
      { key: 'CAN_PIN_THIS_PAGE', value: this.canPinThisPage() },
      { key: 'IS_PAGE_ALREADY_PINNED', value: this.isPageAlreadyPinned() },
      { key: 'PINNED_PAGE_TILE_KEY', value: this.getPinTileKey() },
    ];
    if (this.config) {
      for (const [key, value] of Object.entries(this.config)) {
        result.push({ key: key, value: value });
      }
    }
    return result;
  }


  private isPageAlreadyPinned(): boolean {
    if (this.componentSchema.isDashboard) return true;
    const tile = this.userDashboardTiles.find(tile => tile.id == this.componentSchema.tileKey);
    return tile ? true : false;
  }

  private canPinThisPage(): boolean {
    if (this.componentSchema.isDashboard) return false;
    return this.componentSchema.tileKey.length > 0;
  }
  private getPinTileKey(): string {
    if (this.componentSchema.isDashboard) return '';
    return this.componentSchema.tileKey;
  }
}
