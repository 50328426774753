import React from 'react';
import { Stack, ActionButton, IconButton } from 'office-ui-fabric-react';
import { getStyles } from './MsxPageTitle.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IAppTheme } from '../../theme/Theme.types'
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

interface OwnProps {
  title?: string | JSX.Element;
  seeAllButtonTitle?: string | JSX.Element;
  pathname?: string;
  id?: string;
  handleSeeAllClick?: () => void;
  theme: IAppTheme;
  canPin?: boolean;
  isAlreadyPinned?: boolean;
  pinTile?: string;
  tileId?: string;
  handlePinClick?: () => void;
}

type Props = OwnProps;
const PIN_PAGE_TO_DASHBOARD = 'PinPageToDashboard';

class MsxPageTitle extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    classes = getClassNames(getStyles(props.theme));

    this.handleSeeAllClick = this.handleSeeAllClick.bind(this);
    this.handlePinClick = this.handlePinClick.bind(this);
  }

  handleSeeAllClick() {
    if (typeof this.props.handleSeeAllClick == 'function') {
      this.props.handleSeeAllClick();
    }

  }

  renderSeeAllButtonTitle(): string | JSX.Element {
    const { seeAllButtonTitle } = this.props;

    return (typeof (seeAllButtonTitle) === 'string')
      ? <div className={classes.seeAllBtnTitle}>{seeAllButtonTitle}</div>
      : seeAllButtonTitle
  }

  renderSeeAllButton() {
    const { seeAllButtonTitle, pathname } = this.props;

    if (!seeAllButtonTitle || !pathname) return null;

    return (
      <ActionButton className={classes.headingButton} onClick={this.handleSeeAllClick} id={`SeeAll${this.props.id}`} >
        {this.renderSeeAllButtonTitle()}
      </ActionButton>
    );
  }

  private handlePinClick = () => {
    const newEvent = new CustomEvent(PIN_PAGE_TO_DASHBOARD, {
      bubbles: false,
      detail: { id: this.props.tileId }
    });
    window.dispatchEvent(newEvent);
    if (typeof this.props.handlePinClick == 'function') {
      this.props.handlePinClick();
    }
  }

  renderPinButton() {

    if (!this.props.canPin)
      return;


    if (this.props.isAlreadyPinned) {
      return (
        <IconButton
          disabled
          iconProps={{
            iconName: 'Pinned'
          }}
          title="Tile Pinned"

        />
      );
    } else {
      return (
        <TooltipHost
          content={this.props.pinTile}
          id='pinTile'
        >
          <IconButton
            iconProps={{
              iconName: 'pin'
            }}
            onClick={this.handlePinClick}
          />
        </TooltipHost>
      );
    }
  }

  render() {
    const { title } = this.props;
    return (
      <Stack horizontal verticalAlign='center'>
        <h1 className={classes.pageTitle}>
          {title}
        </h1>
        {this.renderPinButton()}
        {this.renderSeeAllButton()}
      </Stack>
    );
  }
}

export default MsxPageTitle;
