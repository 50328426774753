import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';

export const DefaultColors = {
  Application: {
    Primary: '#0078d7',
    Secondary: '#e5e5e5',
    LightBackground: '#ffffff',
    NeutralBackground: '#e5e5e5',
    HighContrastBlackOnWhite: '#FFFFFF !important',
    HighContrastWhiteOnBlack: '#000000 !important',
    HighContrast: '#000000 !important',
  },
  VisualIndicators: {
    GrayIndicator: '#7a7574',
    GreenIndicator: '#498205',
    YellowIndicator: '#fce100',
    RedIndicator: '#d1343b',
    BlueIndicator: '#0078d7'
  },
  Text: {
    Header: DefaultPalette.neutralPrimary,
    Primary: DefaultPalette.neutralPrimary,
    Secondary: DefaultPalette.neutralSecondary,
    HeroAccent: '#0078D7',
    Error: DefaultPalette.redDark,
    HighContrastBlackOnWhite: '#000000 !important',
    HighContrastWhiteOnBlack: '#FFFFFF !important',
    HighContrast: '#FFFF00 !important',
    HeadingMain: '#423f3f',
    HeadingSecondary: '#333333',
  },
  Link: {
    PrimaryLink: '#0073D1',
    HeroLink: DefaultPalette.themeDark,
    HighContrastBlackOnWhite: '#00009F !important',
    HighContrastWhiteOnBlack: '#FFFF00 !important',
    HighContrast: '#8080FF !important',
  },
  List: {
    HeaderText: DefaultPalette.neutralSecondary,
    RowText: DefaultPalette.neutralPrimary,
  },
  Item: {
    ListItemHoverBackgroundColor: DefaultPalette.neutralLighterAlt,
    ItemIndicator: '#0078d7'
  },
  Icon: {
    SecondaryIcon: DefaultPalette.neutralSecondary,
    PrimaryIcon: DefaultPalette.neutralPrimary
  }
};

