import * as React from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { INavLinkProps } from './Nav.types';
import { IStyle, mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import '../MsxLeftNavBar-local.css';
import { TooltipHost } from 'office-ui-fabric-react';
/**
 * Represents a composed link in the Nav component.
 */
export const NavLink: React.SFC<INavLinkProps> = (props: INavLinkProps) => {
  if (!props) {
    return null;
  }

  const computedTextWidth: IStyle = {
    // 100px to accomodate left and right icons (48px each)
    width: 'calc(100% - 96px)'
  };

  if (!props.rightIconName && !props.leftIconName) {
    // no icons, take full with to text
    computedTextWidth.width = '100%';
  } else if (!props.leftIconName || !props.rightIconName) {
    // 48px to the left or right icon
    computedTextWidth.width = 'calc(100% - 48px)';
  }

  const fixedIconWidth: IStyle = {
    width: '48px',
    display: props.rightIconName === 'OpenInNewWindow' ? 'none' : 'inline-block'
  };

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      props.onClick(target);
    }
  }

  return (
    <TooltipHost
      content={props.title}
      id={`tooltip-leftnav-${props.id}`}
    >
      <a
        id={props.id}
        href={props.href}
        target={props.target}
        onKeyPress={e => handleKeyPress(e)}
        onClick={props.onClick}
        data-hint={props.dataHint}
        data-value={props.dataValue}
        aria-label={props.ariaLabel}
        aria-expanded={props.ariaExpanded}
        role={props.role}
        data-is-visible="true"
        className={['leftNavLink'].join(' ')}
        aria-posinset={props['aria-posinset']}
        aria-setsize={props['aria-setsize']}
      >
        <div className={[props.rootClassName, 'leftNavLinkContainer'].join(' ')} aria-hidden="true">
          <hr className={props.barClassName} />
          {props.leftIconName ? <Icon iconName={props.leftIconName} className={props.iconClassName} /> : null}
          {props.content ? (
            <div className={mergeStyles(props.textClassName, computedTextWidth)}>{props.content}</div>
          ) : null}
          {props.rightIconName ? (
            <Icon iconName={props.rightIconName} className={mergeStyles(props.iconClassName, fixedIconWidth)} />
          ) : null}
        </div>
      </a>
    </TooltipHost>
  );
};
