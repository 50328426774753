import * as React from 'react';
import { Announced, ContextualMenu, Modal, IDragOptions, Stack } from 'office-ui-fabric-react';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
//import { IMsxModalProps, IMsxModalState, IMsxModalStyles } from './MsxModal.types';
import { getStyles, getRootStyle } from './MsxModal.styles';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { closeMessage, modalIsOpen } from './MsxModalMessage';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Toggle,
  IIconProps,
  IStackProps,
} from '@fluentui/react';
import {
  IMsxModalProps, IMsxModalStyles, MsxModalHeight, IMsxModalState
} from './MsxModal.types';

export class MsxModal extends React.Component<IMsxModalProps, IMsxModalState> {
  constructor(props: IMsxModalProps) {
    super(props);
    this.state = {
      closeModal: false,
      announcedMessage: `${closeMessage} ${this.props.title} ${modalIsOpen}`
    };
  }

  renderSubHeading() {
    if (this.props.onRenderSubHeader) {
      return this.props.onRenderSubHeader();
    }
  }

  renderCloseButton() {
    const getClassNames = classNamesFunction<IMsxModalProps, IMsxModalStyles>();
    const classNames = getClassNames(getStyles(this.props));
    const tooltipText = this.props.closeButtonTooltipText ? this.props.closeButtonTooltipText : closeMessage;
    return (
      <span className={[classNames.closeIcon, classNames.closeIconContainer].join(' ')}>
        <TooltipHost
          content={tooltipText}>
          <IconButton
            iconProps={{
              iconName: 'ChromeClose'
            }}
            ariaLabel={`${this.props.title} close`}
            onClick={() => this.closeModal()}
          />
        </TooltipHost>
      </span>
    );
  }

  public render() {
    const getClassNames = classNamesFunction<IMsxModalProps, IMsxModalStyles>();
    const classNames = getClassNames(getStyles(this.props));
    const contentStyles = mergeStyleSets({
      container: {
        backgroundColor: this.props.theme ? this.props.theme.palette.white : 'white', 
        width: '75vw',
        height: this.props.height === MsxModalHeight.Max ? '75vh' : '50vh',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
      },
      // header: [
      //   // eslint-disable-next-line deprecation/deprecation
      //   theme.fonts.xLargePlus,
      //   {
      //     flex: '1 1 auto',
      //     borderTop: `4px solid ${theme.palette.themePrimary}`,
      //     color: theme.palette.neutralPrimary,
      //     display: 'flex',
      //     alignItems: 'center',
      //     fontWeight: FontWeights.semibold,
      //     padding: '12px 12px 14px 24px',
      //   },
      // ],
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        // selectors: {
        //   p: { margin: '14px 0' },
        //   'p:first-child': { marginTop: 0 },
        //   'p:last-child': { marginBottom: 0 },
        // },
      },
    });
     
    return (
      <div >
        <Announced message={`${closeMessage} ${this.props.title} ${modalIsOpen}`} />
        <Modal
          isOpen={!this.state.closeModal}
          onDismiss={this.closeModal}
          isBlocking={this.props.isBlocking || true}
          //styles={getRootStyle(this.props)}
          isDarkOverlay={true}
          containerClassName={contentStyles.container}
        >
          <Stack >
            <Stack className={classNames.headerRow}>
              <Stack.Item align='end'>
                {this.renderCloseButton()}
              </Stack.Item>
            </Stack>
            <Stack wrap horizontal horizontalAlign={'space-between'} className={classNames.headerRow}>
              <h1 className={classNames.titleText}>{this.props.title}</h1>
              <Stack>
                {this.renderSubHeading()}
              </Stack>
            </Stack>
            {/* <div className={classNames.contentArea}> */}
            <div className={contentStyles.body}>
              {this.props.children}
            </div>
          </Stack>
        </Modal>
      </div>
    );
  }
  private closeModal =
    (ev?: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement> | undefined): void => {
      let isDismissConfirmed = true;
      // Invoke onDismiss Callback if passed.
      if (this.props.onDismiss) {
        // Do not dismiss modal if callback returns false.
        isDismissConfirmed = this.props.onDismiss(ev);
      }
      this.setState({ closeModal: isDismissConfirmed });
    }
}
