
import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '../../theme/Theme.types';

export interface ILayoutMainStyles {
  root: IStyle;
  headingComponent: IStyle;
  modalHeadingComponent: IStyle;
  titleRow: IStyle;
  titleRowModal: IStyle;
  table: IStyle;
  header: IStyle;
  body: IStyle;
  modalBody: IStyle;
}

export const getStyles = (theme: IAppTheme): ILayoutMainStyles => {
  return {
    root: {
      marginLeft: '0px',
      backgroundColor: theme ? theme.palette.white : 'transparent',
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    headingComponent: {
      margin: '0 20px 0 20px',
      //width: '95%',
    },
    modalHeadingComponent: {
      //width: '100%',
    },
    titleRow: {
      margin: '10px 20px 0 20px',
      minHeight: '43px',
    },
    titleRowModal: {
      minHeight: '43px',
    },
    table: {
      margin: '0 0 0 20px',
      backgroundColor: 'transparent',
    },
    header: {
      //backgroundColor: '#f8f8f8',
      //height: '50%'
    },
    body: {
      //backgroundColor: '#ffffff',
    },
    modalBody: {
      paddingTop: '20px',
      // backgroundColor: '#ffffff',
      //height: '50%'
    }
  }
}
