import { DefaultTheme } from '../../theme/theme';
import { DefaultColors } from '../../theme/defaultTheme/colors';
import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '../../theme/Theme.types';
import { isEmpty } from '../../utils/helpers';

export interface IDonutChartStyles {
  root: IStyle;
  svgContainer: IStyle;
  notSelectedSlimLayout: any;
  notSelectedTileLayout: any;
  selected: any,
  focused: IStyle;
  chartLink: any;
  chartText: any;
  pieTitle: IStyle;
  pieSubTitle: IStyle;
  legendName: IStyle;
  legendValue: IStyle;
  legendRect: any;
  legendRectIsHover: IStyle;

}

export const getStyles = (theme: IAppTheme): IDonutChartStyles => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxHeight: '170px',
      width: '100%',
      //minWidth: '360px',
    },
    svgContainer: {
      width: '100%',
      height: '170px',
    },
    notSelectedSlimLayout: {
      selectors: {
        '@media screen and (-ms-high-contrast: active)': {
          fill: DefaultColors.Application.HighContrast
        },
        '@media screen and (-ms-high-contrast: black-on-white)': {
          fill: DefaultColors.Application.HighContrastBlackOnWhite
        },
        '@media screen and (-ms-high-contrast: white-on-black)': {
          fill: DefaultColors.Application.HighContrastWhiteOnBlack
        },
      },
      fill: `${isEmpty(theme) ? '#f8f8f8' : theme.palette.white}`  // todotheme
      //fill: '#f8f8f8'
    },
    notSelectedTileLayout: {
      selectors: {
        '@media screen and (-ms-high-contrast: active)': {
          fill: DefaultColors.Application.HighContrast
        },
        '@media screen and (-ms-high-contrast: black-on-white)': {
          fill: DefaultColors.Application.HighContrastBlackOnWhite
        },
        '@media screen and (-ms-high-contrast: white-on-black)': {
          fill: DefaultColors.Application.HighContrastWhiteOnBlack
        },
      },
      fill: `${isEmpty(theme) ? '#FFFFFF' : theme.palette.white}`  // todotheme
    },
    selected: {
      //stroke: 'rgba(139, 139, 139, 0.8)',
      stroke: `${isEmpty(theme) ? 'rgba(139, 139, 139, 0.8)' : theme.palette.black}`,  // todotheme
      strokeWidth: '1',
      strokeDasharray: "4,3",
      //fill: 'rgba(196, 196, 196, 0.2)',
      fill: `${isEmpty(theme) ? 'rgba(196, 196, 196, 0.2)' : theme.palette.white}`  // todotheme
    },
    focused: {
      fill: `${isEmpty(theme) ? 'rgba(196, 196, 196, 0.2)' : theme.palette.white}`,  // todotheme
      //fill: 'rgba(196, 196, 196, 0.2)',
      cursor: 'pointer',
    },
    chartLink: {
      selectors: {
        '@media screen and (-ms-high-contrast: active)': {
          fill: DefaultColors.Link.HighContrast
        },
        '@media screen and (-ms-high-contrast: black-on-white)': {
          fill: DefaultColors.Link.HighContrastBlackOnWhite
        },
        '@media screen and (-ms-high-contrast: white-on-black)': {
          fill: DefaultColors.Link.HighContrastWhiteOnBlack
        },
      },

    },
    chartText: {
      selectors: {
        '@media screen and (-ms-high-contrast: active)': {
          fill: DefaultColors.Text.HighContrast
        },
        '@media screen and (-ms-high-contrast: black-on-white)': {
          fill: DefaultColors.Text.HighContrastBlackOnWhite
        },
        '@media screen and (-ms-high-contrast: white-on-black)': {
          fill: DefaultColors.Text.HighContrastWhiteOnBlack
        },
      },
      //fill: '#666666',
      fill: `${isEmpty(theme) ? '#666666' : theme.palette.black}`,   // todotheme
      lineHeight: '1.33',
      fontSize: '10px',
      fontFamily: 'Segoe UI',
    },
    pieTitle: {
      fontSize: '24px',
      fontFamily: 'Segoe UI',
      // fill: '#333333',
      fill: `${isEmpty(theme) ? '#333333' : theme.palette.black}`,   // todotheme
      lineHeight: '2.17',
    },
    pieSubTitle: {
      fontSize: '12px',
      fontFamily: 'Segoe UI',
      //fill: '#333333',
      fill: `${isEmpty(theme) ? '#333333' : theme.palette.black}`,   // todotheme
      lineHeight: '1.33',
    },
    legendName: {
      fontSize: '12px',
      //fill: '#333333',
      fill: `${isEmpty(theme) ? '#333333' : theme.palette.black}`,   // todotheme
    },
    legendValue: {
      fontSize: '26px',
      fontWeight: '600',
      fontFamily: 'Segoe UI',
      //fill: '#333333',
      fill: `${isEmpty(theme) ? '#333333' : theme.palette.black}`,   // todotheme
    },
    legendRect: {
      selectors: {
        '&:hover': {
          fill: 'rgba(196, 196, 196, 0.2)',
        }
      },
    },
    legendRectIsHover: {
      fill: 'rgba(196, 196, 196, 0.2)',
    },
  }
};


