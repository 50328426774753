import { INavLinks, INavProps, INavStates } from './Nav.types';
import { INav } from 'office-ui-fabric-react/lib/Nav';
import * as React from 'react';

export class NavBase extends React.Component<INavProps, INavStates> implements INav {
  protected _hasAtleastOneHiddenLink = false;

  constructor(props: INavProps) {
    super(props);
  }

  public get selectedKey(): string | undefined {
    return this.state.selectedKey;
  }

  public focus(forceIntoFirstElement?: boolean): boolean {
    return forceIntoFirstElement ? forceIntoFirstElement : false;
  }

  protected getPreferredSelectedKey(): string {
    let selectedKey = '';

    // if caller passes in selectedKey, use it as first choice or use current state.selectedKey
    if (this.props.selectedKey) {
      selectedKey = this.props.selectedKey;
    } else if (this.state.selectedKey) {
      selectedKey = this.state.selectedKey;
    }

    return selectedKey;
  }

  /* given a link, find if one of the child is selected */
  protected isChildLinkSelected(link: INavLinks): boolean {
    const selectedKey = this.getPreferredSelectedKey();

    if (!selectedKey || !link || !link.links || link.links.length === 0) {
      return false;
    }

    return link.links.some((childLink: INavLinks) => {
      return !!childLink && childLink.key === selectedKey;
    });
  }

  // given a link and an optional includeChildren parameter, find if the link or any of the children is selected
  protected isLinkSelected(link: INavLinks, includeChildren: boolean): boolean {
    const selectedKey = this.getPreferredSelectedKey();

    if (!selectedKey || !link) {
      return false;
    }

    // check if the link or any of the child link is selected
    return link.key === selectedKey || (includeChildren && this.isChildLinkSelected(link));
  }

  protected getLinkText(link: INavLinks, showMore?: boolean): string | undefined {
    if (!link) {
      return undefined;
    }

    if (link.isShowMoreLink && !showMore && link.alternateText) {
      // if the link is show more/less link, based on the showMore state; return "Show more" localized text
      return link.alternateText;
    }

    return link.name;
  }

  // find if atleast one child link is visible using isHidden property
  // showMore flag will overwrite isHidden property
  protected hasAtleastOneVisibleLink(links: INavLinks[], showMore?: boolean): boolean {
    if (!links || links.length === 0) {
      return false;
    }

    return (
      links.some(
        (link: INavLinks): boolean => {
          return !link.isHidden;
        }
      ) || !!showMore
    );
  }
}
