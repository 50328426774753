import {IAppTheme} from '../../../theme/Theme.types'

export const getStyles = (theme:IAppTheme) => {
  return {
    csvBtnRoot: {
      width: "100%",
      height: "36px",
    },
    csvBtnText: {
      fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontSize: "12px",
      //color: "#0073D1",
      fontWeight: "600",
    },
    csvBtnIcon: {
      width: "14px",
      height: "14px",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      //color: "#0064c1",
      paddingTop: "2px",
    },
  };
};
