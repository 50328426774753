import * as React from 'react';
import { ICardFrameProps, ICardFrameStyles } from './CardFrame.types';
// import { IconButton, Button } from 'office-ui-fabric-react/lib/Button';
// import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { getStyles } from './CardFrame.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
// import { IProcessedStyleSet } from 'office-ui-fabric-react/lib/Styling';
//import { getInDashboardEditMode } from '../../../../store/selectors/appSelectors';
//import { useDispatch } from 'react-redux';
//import { getUserTilesOrder, getUserProfile } from '../../../../store/selectors/userProfile/userProfileSelectors';
//import { setUserProfile } from '../../../../store/actions/userProfileActions/userProfileActions';
// import arrayMove from 'array-move';
// import { IContextualMenuProps } from 'office-ui-fabric-react';
// import { useState } from 'react';

export const CardFrame: React.FC<ICardFrameProps> = (props) => {
  //const inDashboardEditMode = useSelector(getInDashboardEditMode);
  // const tilesOrder = useSelector(getUserTilesOrder);
  // const userProfile = useSelector(getUserProfile);
  // const [tileIndex] = useState(tilesOrder.findIndex(tileConfig => tileConfig.id == props.tileId));
  // const dispatch = useDispatch();

  const render = () => {
    const getClassNames = classNamesFunction<ICardFrameProps, ICardFrameStyles>();
    const { fontFamily, fontSize, cardTitle, seperatorColor, titleTextColor, disableDrag } = props;
    const classNames = getClassNames(getStyles, {
      cardTitle,
      fontFamily,
      fontSize,
      seperatorColor,
      titleTextColor,
      disableDrag
    });

    return (
      <div className={classNames.root}>
        <div className={classNames.cardTitleBox}>
          <h2 className={classNames.cardTitle}>
            {cardTitle}
          </h2>
          {/* {inDashboardEditMode ? getRemoveButton(classNames) : ''}
          {inDashboardEditMode ? getSettingsButton(classNames) : ''}
          {!inDashboardEditMode && props.onViewMoreClick ? getViewMoreButton(classNames) : ''}
          {!inDashboardEditMode && props.onExpandClick ? getExpandButton(classNames) : ''} */}
        </div>
        <div className={classNames.layout}>{props.children}</div>
      </div>
    );
  }

  // const handleMoveForward = () => {
  //   dispatch(setUserProfile({
  //     ...userProfile,
  //     userPreference: {
  //       tilesOrder: arrayMove(tilesOrder, tileIndex, tileIndex + 1)
  //     }
  //   }));
  // }

  // const handleMoveBackward = () => {
  //   dispatch(setUserProfile({
  //     ...userProfile,
  //     userPreference: {
  //       tilesOrder: arrayMove(tilesOrder, tileIndex, tileIndex - 1)
  //     }
  //   }));
  // }

  // const menuProps: IContextualMenuProps = {
  //   items: [
  //     {
  //       key: 'moveForward',
  //       text: 'Move forward',
  //       iconProps: { iconName: 'DecreaseIndentArrowMirrored' },
  //       onClick: handleMoveForward,
  //       disabled: tileIndex == tilesOrder.length - 1
  //     },
  //     {
  //       key: 'moveBackward',
  //       text: 'Move backward',
  //       iconProps: { iconName: 'IncreaseIndentArrowMirrored' },
  //       onClick: handleMoveBackward,
  //       disabled: tileIndex == 0

  //     }
  //   ],
  //   directionalHintFixed: true
  // }

  // const getSettingsButton = (classNames) => {
  //   return (
  //     <div className={classNames.cardTitleEllipsisButton}>
  //       <TooltipHost
  //         content={`${props.cardTitle} Move Settings`}
  //         id="MoveSettings"
  //       >
  //         <IconButton
  //           id={`Settings${props.id}`}
  //           className={classNames.ellipsisButtonStyle}
  //           menuIconProps={{ iconName: 'More' }}
  //           menuProps={menuProps}
  //           split={false}
  //           ariaLabel={`${props.cardTitle} Move Settings`}
  //           styles={{
  //             root: { width: '100%', height: '100%' },
  //             rootHovered: { background: '#EAEAEA', color: '#000000' },
  //             rootPressed: { background: '#979797', color: '#000000' },
  //             rootExpanded: { color: '#000000' }
  //           }}
  //         />
  //       </TooltipHost>
  //     </div>
  //   );
  // }

  // const handleRemoveClick = () => {
  //   const newTilesOrder = tilesOrder.map(tileConfig => {
  //     if (tileConfig.id == props.tileId) {
  //       tileConfig.isVisible = false;
  //     }
  //     return tileConfig
  //   });
  //   dispatch(setUserProfile({
  //     ...userProfile,
  //     userPreference: {
  //       tilesOrder: newTilesOrder
  //     }
  //   }));
  // }

  // const getRemoveButton = (classNames: IProcessedStyleSet<ICardFrameStyles>) => {
  //   return (
  //     <div className={classNames.cardTitleEllipsisButton}>
  //       <TooltipHost
  //         content={`${props.cardTitle} Remove Tile`}
  //         id='removeSettings'
  //       >
  //         <IconButton
  //           id={`Remove${props.id}`}
  //           className={classNames.ellipsisButtonStyle}
  //           menuIconProps={{ iconName: 'Delete' }}
  //           split={false}
  //           ariaLabel={`${props.cardTitle} Remove Tile`}
  //           onClick={handleRemoveClick}
  //           styles={{
  //             root: { width: '100%', height: '100%' },
  //             rootHovered: { background: '#EAEAEA', color: '#000000' },
  //             rootPressed: { background: '#979797', color: '#000000' },
  //             rootExpanded: { color: '#000000' }
  //           }}
  //         />

  //       </TooltipHost>
  //     </div>
  //   );
  // }

  // const getExpandButton = (classNames: IProcessedStyleSet<ICardFrameStyles>) => {
  //   return (
  //     <div className={classNames.cardTitleEllipsisButton}>
  //       <IconButton
  //         id={`Expand${props.id}`}
  //         className={classNames.ellipsisButtonStyle}
  //         menuIconProps={{ iconName: 'FullScreen' }}
  //         split={false}
  //         aria-label={`${props.cardTitle} expand`}
  //         onClick={props.onExpandClick}
  //         styles={{
  //           root: { width: '100%', height: '100%' },
  //           rootHovered: { background: '#EAEAEA', color: '#000000' },
  //           rootPressed: { background: '#979797', color: '#000000' },
  //           rootExpanded: { color: '#000000' }
  //         }}
  //       />
  //     </div>
  //   );
  // }

  // const getViewMoreButton = (classNames: IProcessedStyleSet<ICardFrameStyles>) => {
  //   return (
  //     <div className={classNames.cardTitleActionButton}>
  //       <Button
  //         className={classNames.ellipsisButtonStyle}
  //         disabled={false}
  //         allowDisabledFocus={false}
  //         text={'View More'}
  //         split={false}
  //         aria-label={'View More'}
  //         onClick={props.onViewMoreClick}
  //         styles={{
  //           root: {
  //             width: '100%',
  //             height: '100%',
  //             background: 'transparent',
  //             fontSize: '12px',
  //             color: '#0078D4',
  //             fontWeight: '400'
  //           },
  //           rootHovered: { background: 'transparent', color: '#0078D4' },
  //           rootPressed: { background: 'transparent', color: '#0078D4' },
  //           rootExpanded: { color: '#000000' }
  //         }}
  //       />
  //     </div>
  //   );
  // }

  return render();
}

export default CardFrame;
