import React, { useEffect, useContext, useState, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ApplicationContext, } from '../../common/context/ApplicationContext';
import { ExtensionSchemaLoader } from './ExtensionSchemaLoader';
import { ExtensionProps } from './Extension.types'
import { AttributeProps } from '@msx/platform-types';

import DashboardError from './DashboardError';
import PageError from './PageError';
import { ExtensionHelper } from './ExtensionHelper';
import { ExtensionEventTypes, ExtensionErrorTypes, ExtensionKeys } from './Extension.types';
import { ServiceContext } from '../../common/context/ServiceContext';
import { UserEvent, UsageEventName } from '../../common/models/UsageTelemetry';
import { EventType } from '../../common/models/UsageTelemetry/UsageEvent';
import { DxpCard } from '../Card';
import { DxpModal, DxpModalHeight } from '../Modal';
//import extensionMessage from './Extension.message';
import { getStyles } from './Extension.styles'
import { IAppError } from '../..';
import { AppContext, IAppContextProps } from './AppContext';
import { NavigationRouteProps } from './Extension.types';
import { IExtensionMessage, getDefaultExtensionMessages } from './Extension.types';
import { CARD_MIN_HEIGHT, CARD_MIN_WIDTH } from '../../core/utils/constants';
import { NotificationProps } from '..';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

export const MsxExtension: (React.FC<ExtensionProps>) = props => {
  const { componentKey, config, attributes } = props;
  const messages = props.messages ? props.messages : getDefaultExtensionMessages();
  const { extensionsRegistrationClient, appState, userDashboardTiles } = useContext(ApplicationContext);
  const { telemetryClient, telemetryContext, authClient, httpClient, graphClient } = useContext(ServiceContext);
  const extensionsSchemaLoader = new ExtensionSchemaLoader(extensionsRegistrationClient, componentKey);
  const componentSchema = extensionsSchemaLoader.getCurrentExtensionComponent();
  const isInIDE = componentSchema.isInIDE;
  const helper = new ExtensionHelper(componentSchema);
  const params = helper.parseQueryString(window.location.search);
  const appContextProps: IAppContextProps = {
    graphClient: graphClient,
    authClient: authClient,
    httpClient: httpClient,
    telemetryClient: telemetryClient,
    extensionsSchemaLoader: extensionsSchemaLoader,
    componentSchema: componentSchema,
    //location: location,
    //intl: intl,
    params: params,
    //theme: theme,
    appState: appState,
    userDashboardTiles: userDashboardTiles,
    //locale: locale,
    config: config,
    onNavigateToRoute: (route: NavigationRouteProps) => props.onNavigateToRoute(route),
    onNotify: (item: NotificationProps) => props.onNotify(item),
    onLaunchFeedback: (extensionKey: string) => props.onLaunchFeedback(extensionKey),
    onLaunchChatBot: (extensionKey: string) => props.onLaunchChatBot(extensionKey),
    onValidateAttributes: (attributes: AttributeProps) => props.onValidateAttributes(attributes),
  }
  const appContext = new AppContext(appContextProps);
  const componentRef = useRef(null);
  const loaderRef = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [scriptLoadingErrorMessage, setScriptLoadingErrorMessage] = useState('');
  const [extensionErrorMessage, setExtensionErrorMessage] = useState('');
  const [showExtensionErrorDetail, setShowExtensionErrorDetail] = useState(false);
  const [extensionError, setExtensionError] = useState(null);


  classes = getClassNames(getStyles(appState.theme));
  let extensionFileCount = 0;

  useEffect(() => {
    //MOUNT
    if (appState.isReady) {
      initializeExtensions();
    }
  }, [appState, attributes]);

  useEffect(() => {
    window.addEventListener(ExtensionEventTypes.PIN_PAGE_TO_DASHBOARD, e => handlePinClick(e));
    window.addEventListener(ExtensionEventTypes.DASHBOARD_EDIT_TILES_REFRESH, e => handleEditTileRefresh(e));
    window.addEventListener(ExtensionEventTypes.LOCALE_CHANGED, e => handleLocaleChanged(e));

    //UNMOUNT
    return () => {
      if (!componentSchema.isDashboard) {
        const event = new CustomEvent(ExtensionEventTypes.SUPPLIER_SELECT_OPTIONS_EVENT, { detail: [] });
        window.dispatchEvent(event);
      }
      window.removeEventListener(ExtensionEventTypes.LOCALE_CHANGED, handleLocaleChanged);
      window.removeEventListener(ExtensionEventTypes.APP_ERROR_EVENT, handleError);
      window.removeEventListener(ExtensionEventTypes.PIN_PAGE_TO_DASHBOARD, handlePinClick);
      window.removeEventListener(ExtensionEventTypes.DASHBOARD_EDIT_TILES_REFRESH, handleEditTileRefresh);
    }

  }, [])

  const handleLocaleChanged = (e: any): void => {
    if (!e.detail) return;
    const data = e.detail;
    if (!data) return;
    const locale = data.locale;
    appContext.setLocale(locale);
  }



  const handleEditTileRefresh = (e: any): void => {
    if (!e.detail) return;
    const data = e.detail;
    if (!data) return;
    appContext.setInDashboardEditMode(data.inDashboardEditMode, data.inDashboardEditTiles);
  }

  const handlePinClick = (e: any): void => {
    if (!e.detail) return;
    const tileId = e.detail.id;
    if (!tileId) return;
    console.log('TODO: handlePinClick');
    // const newTilesOrder = userTilesOrderRef.current.map(tileConfig => {
    //   if (tileConfig.id == tileId) {
    //     tileConfig.isVisible = true;
    //   }
    //   return tileConfig
    // });
    // dispatch(setUserProfile({
    //   ...userProfile,
    //   userPreference: {
    //     tilesOrder: newTilesOrder
    //   }
    // }, true))
  }

  const initializeExtensions = async () => {
    const files = componentSchema.files;
    if (isInIDE) {
      setShowLoader(false);
      setScriptLoadingErrorMessage('');
    }
    else {
      if (files.length > 0) {
        files.map(file => {
          loadScript(file.url + file.name, file.token, file.type);
        })
      }
    }
    helper.dispatchExcludeEvent();
    renderCustomElement();
  }

  const loadScript = async (filename: string, token: string, type: string) => {
    const files = componentSchema.files;
    const nodes: any = document.getElementsByTagName("script");
    const fileUrlWithToken = `${filename}?${token}`;
    let found = false;
    for (const node of nodes) {
      if (node.getAttribute('src') === fileUrlWithToken) {
        found = true;
        break;
      }
    }
    if (found) {
      extensionFileCount++;
      if (extensionFileCount === files.length) {
        setShowLoader(false);
        setScriptLoadingErrorMessage('');
      }
      return;
    }
    try {
      await helper.loaderScript(fileUrlWithToken, type)
      scriptLoaded();
    } catch (error) {
      scriptError(filename, error);
    }
  }

  const scriptLoaded = (): void => {
    extensionFileCount++;
    const files = componentSchema.files;
    if (files.length > 0 && extensionFileCount === files.length) {
      setParentContext();
      setShowLoader(false);
      setScriptLoadingErrorMessage('');
    }
  }
  const scriptError = (filename: string, error: any): void => {

    const message = `Failed to load extension from ${filename}. Please make sure, that extension endpoint address is reachable.`;
    const exception = {
      exception: new Error(message),
    }
    const customProperties = {
      extension: componentSchema.extensionName,
      error: ExtensionErrorTypes.EXTENSION_SCRIPT_LOAD_FAILED,
      filename: filename,
    };
    telemetryClient.trackException(exception, customProperties);

    setShowLoader(false);
    setScriptLoadingErrorMessage(message);
    const errorDetail = {
      message: message,
      url: filename,
      line: 'N/A',
      column: 'N/A',
      error: new Error(ExtensionErrorTypes.SCRIPT_ERROR),
    };
    const eventAppError = new CustomEvent(ExtensionEventTypes.APP_ERROR_EVENT, { bubbles: false, detail: errorDetail });
    window.dispatchEvent(eventAppError);
    helper.cleanupExtensions();
  }

  const renderExtensionErrorContent = (): JSX.Element => {
    if (componentSchema.isDashboard) {
      return renderExtensionErrorDashboardContent();
    }
    return renderExtensionErrorPageContent();
  }

  const getErrorField = (key: string) => {
    const emptyValue = '--';
    if (!isdirtyComponent()) return emptyValue;
    if (extensionError && extensionError[key]) {
      if (key === 'error')
        return extensionError[key].stack.toString();
      else
        return extensionError[key];
    }
    return emptyValue;
  }
  const handleRefreshTile = () => {
    window.location.reload();
  }
  const handleShowErrorDetail = () => {
    setShowExtensionErrorDetail(true);
  }
  const handleFeedback = () => {
    if (typeof props.onLaunchFeedback === 'function') {
      logEvent(ExtensionEventTypes.FEEDBACK_LAUNCHER_INVOKED);
      props.onLaunchFeedback(componentSchema.extensionKey);
    }
  }

  const logEvent = (event: string, data?: any): void => {
    const userEvent: UserEvent = {
      eventName: event,
      type: EventType.User
    }
    telemetryClient.trackEvent(userEvent, data);
  }

  const renderExtensionErrorDashboardContent = (): JSX.Element => {
    const errorMessage = getErrorField('message');
    const error = getErrorField('error');
    return (
      <DashboardError
        isDashboard={componentSchema.isDashboard}
        extensionName={componentSchema.extensionName}
        componentName={componentSchema.component}
        errorMessage={errorMessage}
        error={error}
        handleRefreshTile={handleRefreshTile}
        handleFeedback={handleFeedback}
        handleShowErrorDetail={handleShowErrorDetail}
        messages={messages}
        //intl={intl}
        theme={appState.theme}
      />
    );
  }

  const renderExtensionErrorDetail = () => {
    if (!showExtensionErrorDetail) return null;
    const title = messages.extensionErrorDetailHeading;
    return (
      <DxpModal
        title={title}
        height={DxpModalHeight.Max}
        onDismiss={closeDetails}
      >
        {renderExtensionErrorPageContent()}
      </DxpModal>
    );
  }

  const renderExtensionErrorPageContent = (): JSX.Element => {
    const errorMessage = getErrorField('message');
    const error = getErrorField('error');
    return (
      <PageError
        isDashboard={componentSchema.isDashboard}
        extensionName={componentSchema.extensionName}
        componentName={componentSchema.component}
        errorMessage={errorMessage}
        error={error}
        handleRefreshTile={handleRefreshTile}
        handleFeedback={handleFeedback}
        messages={messages}
        theme={appState.theme}
      />
    );
  }

  const renderExtensionError = (): JSX.Element => {

    if (componentSchema.isDashboard) {
      const cardConfig = {
        cardTitle: componentSchema.name,
        minHeight: CARD_MIN_HEIGHT,
        minWidth: CARD_MIN_WIDTH,
      }

      return (
        <DxpCard
          cardFrameContent={cardConfig}
          loading={false}
          onExpandClick={null}
          id={componentSchema.key}
          theme={appState.theme}
        >
          {renderExtensionErrorContent()}
          {renderExtensionErrorDetail()}
        </DxpCard>
      );
    }
    return (
      <div className={classes.extensionErrorContainer}>
        {renderExtensionErrorContent()}
      </div>
    );
  }
  const isdirtyComponent = (): boolean => {
    if (extensionError === null) return false;
    return extensionErrorMessage.length > 0;
  }

  const closeDetails = (): void => {
    setShowExtensionErrorDetail(false);
  }

  const renderLoading = (): JSX.Element => {
    if (!showLoader)
      return null;

    if (componentSchema.isDashboard) {
      const cardConfig = {
        cardTitle: componentSchema.name,
        minHeight: CARD_MIN_HEIGHT,
        minWidth: CARD_MIN_WIDTH,
      }

      return (
        <DxpCard
          cardFrameContent={cardConfig}
          loading={scriptLoadingErrorMessage.length > 0 ? false : true}
          onExpandClick={null}
          id={componentSchema.key}
          theme={appState.theme}
        >
          {scriptLoadingErrorMessage}
        </DxpCard>
      );
    }
    if (scriptLoadingErrorMessage.length > 0) {
      return (
        <div className={classes.loadingContainer}>
          <p>{scriptLoadingErrorMessage}</p>
        </div>
      );
    }
    return (
      <div className='center'>
        <Spinner size={SpinnerSize.large} />
        <p>{messages.loadingMessage}</p>
      </div>
    );
  }

  const handleError = (e: any): void => {
    if (!e.detail) return;
    const appError = e.detail as IAppError;
    if (appError.error === null) return;
    let validError = false;
    const files = componentSchema.files;
    if (files.length === 0)
      return;
    files.map(file => {
      const filename = file.url + file.name;
      if (file.log && filename.toLowerCase() === appError.url.toLowerCase()) {
        validError = true;
        return;
      }
    })
    if (!validError) return;
    setExtensionError(appError);
    setExtensionErrorMessage(appError.message);

  }
  const renderCustomElement = () => {
    if (componentRef.current && componentRef.current.firstChild && attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        componentRef.current.firstChild.setAttribute(key, value);
      }
      setParentContext();
      return;
    }


    if (!componentSchema) {
      const message = `We’re sorry. The component "${componentKey}" you tried to access is no longer available.`;
      const exception = {
        exception: new Error(message),
      }
      const customProperties = {
        componentKey: componentKey,
        error: ExtensionErrorTypes.EXTENSION_NOT_FOUND_ERROR,
      };
      telemetryClient.trackException(exception, customProperties);
      return;
    }
    window.addEventListener(ExtensionEventTypes.APP_ERROR_EVENT, e => handleError(e));
    const newAttributes = { ...attributes, class: `${componentSchema.isDashboard ? 'tile' : 'page'}-root` };
    const elementName = componentSchema.component;
    const customElement = React.createElement(elementName, newAttributes);
    try {
      ReactDOM.render(customElement,
        componentRef.current,
        () => {
          setParentContext();
        })
    } catch (ex) {
      console.log(ex);
    }
  }
  const setParentContext = () => {
    const elementName = componentSchema.component;
    const component: any = document.querySelector(elementName);
    let applyParentContext = component && !component.parentContext;
    if (component.parentContext) {
      const componentAppState = component.parentContext.getAppState();
      if (!helper.deepEqual(componentAppState, appState)) {
        applyParentContext = true;
      }
    }
    if (applyParentContext) {
      component.parentContext = appContext;
    }
  }

  const renderMain = (): JSX.Element => {
    if (!appState || !appState.isReady) return null;
    if (isdirtyComponent()) {
      return renderExtensionError();
    }
    if (componentSchema.isDashboard) {
      return (
        <div className={isdirtyComponent() ? classes.hide : classes.dashboardContainer}>
          <div ref={componentRef} className={classes.tileContainer} />
          <div ref={loaderRef} className={showLoader ? classes.spinnerContainer : classes.hide} >
            {renderLoading()}
          </div>
        </div>
      );
    }

    return (
      <div className={isdirtyComponent() ? classes.hide : classes.grow}>
        <div ref={componentRef} className={classes.grow} />
        <div ref={loaderRef}>
          {renderLoading()}
        </div>
      </div>
    );
  }


  return renderMain();
}

export default MsxExtension;
