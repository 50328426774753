import {
  PublicClientApplication,
  AccountInfo,
  Configuration,
  AuthenticationResult,
} from '@azure/msal-browser';
import {  IUser,} from '../../common/models';
import { IAuthClient } from '@msx/platform-types';

import { ITelemetryClient } from '@msx/platform-types';
import { TelemetryEvents } from '../../common/resources/TelemetryEvents';

export class MSALV2Client implements IAuthClient {
  public readonly authContext: PublicClientApplication;
  private readonly config: Configuration;
  private readonly telemetryClient: ITelemetryClient;
  private account: AccountInfo | null = null;

  // Tracks whether there has been another login request during login redirect
  // If another login is called before login redirect is completed,
  // MSAL will throw interation_in_progress exception
  private isLoginRequested = false;
  // Tracks whether the login redirect has been completed
  private isRedirectComplete = false;
  // // Tracks all acquireTokens requests made during login redirection
  private acquireTokenRequests: {
    [key: string]: ((token: string) => void)[];
  } = {};

 
  public constructor(
    config: Configuration,
    telemetryClient: ITelemetryClient
  ) {
    this.telemetryClient = telemetryClient;
    this.config = config;
    this.authContext = new PublicClientApplication({
      auth: config.auth,
      cache: {
        cacheLocation: 'localStorage',
        ...config.cache,
      },
    });

    this.authContext
      .handleRedirectPromise()
      .then(this.handleRedirectCompleted.bind(this));
  }

  public login(): Promise<void> {
    return new Promise(
      async (resolve, reject): Promise<void> => {
        this.telemetryClient.trackTrace({
          message: TelemetryEvents.UserLogInRequested,
        });

        try {
          if (this.isRedirectComplete) {
            await this.authContext.loginRedirect({
              scopes: [],
            });
          } else {
            this.isLoginRequested = true;
          }

          resolve();
        } catch (ex) {
          this.telemetryClient.trackTrace({
            message: TelemetryEvents.UserLoginFailed,
          });
          reject(ex);
        }
      }
    );
  }

  public logOut(): Promise<void> {
    return new Promise((resolve, reject): void => {
      this.telemetryClient.trackTrace({
        message: TelemetryEvents.UserLogOutRequested,
      });

      try {
        this.authContext.logout();
        resolve();
      } catch (ex) {
        this.telemetryClient.trackTrace({
          message: TelemetryEvents.UserLogOutFailed,
        });
        reject(ex);
      }
    });
  }

  public getUser(): Promise<IUser | null> {
    return new Promise((resolve, reject): void => {
      try {
        const user = this.getCachedUser();
        if (!user) {
          resolve(null);
          return;
        }

        resolve({
          id: user.username,
          email: user.username,
          // User name does not exist in MSAL v2 as of now
          // TODO: Update when user name is available
          name: user.name ? user.name : '',
          oid: user.homeAccountId,
        });
      } catch (ex) {
        reject(ex);
      }
    });
  }

  public getUserId(): Promise<string | null> {
    return new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          const user = await this.getUser();

          resolve(user ? user.id : null);
        } catch (ex) {
          reject(ex);
        }
      }
    );
  }

  public isLoggedIn(): Promise<boolean> {
    return new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          await this.sleep(2000); // give a little delay to refresh user canche context
          const user = await this.getUser();
          resolve(!!user);
        } catch (ex) {
          reject(ex);
        }
      }
    );
  }

  public acquireToken(scopes: string | string[]): Promise<string | null> {
    const resourceOrScopes = typeof scopes === 'string' ? scopes.split(',') : scopes;
    return new Promise(
      async (resolve): Promise<void> => {
        if (this.isRedirectComplete) {
          const accessToken = await this.acquireTokenSilent(resourceOrScopes);
          resolve(accessToken);
        } else {
          this.addAcquireTokenRequest(resourceOrScopes, resolve);
        }
      }
    );
  }

  private handleRedirectCompleted(
    response: AuthenticationResult | null
  ): void {
    this.account =
      (response?.account as AccountInfo) || this.getCachedUser();
    this.isRedirectComplete = true;

    // Trigger login only if login redirection completed without the account info
    // This means there was no login request perviously
    if (this.isLoginRequested && !this.account) {
      this.login().catch();

      return;
    }

    if (this.account) this.flushAcquireTokenRequests();
  }

  private sleep (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private getCachedUser() {
    const user = this.authContext.getAllAccounts();
    // console.log('somnath: I am in getCachedUser()', user);
    if (user.length === 0) return null;
    if (user.length === 1) return user[0];

    // This can happen if the app supports multi tenant login
    // and user logged in using more than one account
    // This is not a supported scenario for this Framework.
    if (!user || user.length > 1) {
      //return user[1]; // *** OPTION 1 *** DO NOT DELETE, WE NEED TO DECIDE THE APPROACH
      //throw new Error('Found more than 1 user account'); // *** OPTION 2 *** DO NOT DELETE, WE NEED TO DECIDE THE APPROACH
      this.authContext.logout(); // *** OPTION 3 *** CURRENT APPROACH IS LOG OUT THE USER
    }
  }

  private normalizeScopes(scopes: string | string[]): string[] {
    let normalizedScopes: string[] = [];
    if (typeof scopes === 'string')
      normalizedScopes.push(scopes + '/.default');
    else normalizedScopes = [...scopes];
    //console.log('somnath normalizedScopes', normalizedScopes);
    return normalizedScopes;
  }

  private addAcquireTokenRequest(
    scopes: string | string[],
    callback: (token: string) => void
  ): void {
    const normalizedScopes = this.normalizeScopes(scopes);
    const key = normalizedScopes.join(',');

    this.acquireTokenRequests[key] = this.acquireTokenRequests[key] || [];
    this.acquireTokenRequests[key].push(callback);
  }

  private flushAcquireTokenRequests(): void {
    for (const key in this.acquireTokenRequests) {
      const scopes = key.split(',');
      this.acquireTokenSilent(scopes).then((accessToken) => {
        this.acquireTokenRequests[key].forEach((cb) => {
          cb(accessToken);
        });
      });
    }
  }

  private async acquireTokenSilent(
    scopes: string | string[]
  ): Promise<string> {
    return new Promise((resolve): void => {
      const normalizedScopes = this.normalizeScopes(scopes);

      this.authContext
        .acquireTokenSilent({
          scopes: normalizedScopes,
          account: this.account as AccountInfo,
        })
        .then(({ accessToken }) => {
          resolve(accessToken);
        })
        .catch((e) => {
          console.log(e);

          this.authContext.acquireTokenRedirect({
            scopes: normalizedScopes,
            redirectUri:
              this.config.auth?.redirectUri ||
              window.location.origin,
          });
        });
    });
  }
}
