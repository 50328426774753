import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './MsxErrorMessage.styles'
import { IAppTheme } from '../../theme/Theme.types'

export interface OwnProps {
  message: string;
  id: string;
  theme: IAppTheme;
  titleLabel?: string;
  subTitleLabel?: string;
  feedbackButtonLabel?: string;
  errorLabel?: string;
  handleFeedback?: () => void;
}
type Props = OwnProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const MsxErrorMessage: React.FC<Props> = props => {

  classes = getClassNames(getStyles(props.theme));

  const handleFeedback = () => {
    if (typeof props.handleFeedback === 'function') {
      props.handleFeedback();
      return;
    }
    (window as any).startMultiFeedback_AllOptional();
  }
  const errorTitleText = props.titleLabel ? props.titleLabel : "Sorry, we ran into a problem.";
  const errorSubTitleText = props.subTitleLabel ? props.subTitleLabel : "Tell us about this problem to help us fix it.";
  const errorFeedbackText = props.feedbackButtonLabel ? props.feedbackButtonLabel : "Give feedback to us";
  const errorLabelText = props.errorLabel ? props.errorLabel : "Error:";

  return (
    <div id={props.id} className={classes.root}>
      <p className={classes.para}>{errorTitleText}</p>
      <p className={classes.para}>{errorSubTitleText}</p>
      <p className={classes.para}><a className={classes.link} onClick={handleFeedback}>{errorFeedbackText}</a></p>
      <p >{errorLabelText} {props.message}</p>
    </div>);
}

export default MsxErrorMessage;