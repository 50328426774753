import React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './MsxPageNotFoundMessage.styles'
import { IAppTheme } from '../../theme/Theme.types'

export interface OwnProps {
  theme: IAppTheme;
}
type Props = OwnProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

const MsxPageNotFoundMessage: React.FC<Props> = props => {

  classes = getClassNames(getStyles(props.theme));

  return (
    <div className={classes.root}>
      <h3>The page you were looking for doesn&apos;t exist.</h3>
      <p>You may have mistyped the address or the page may have moved.</p>
    </div>);
}

export default MsxPageNotFoundMessage;