import { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../context/ServiceContext';
import { IUser } from '../models/IUser';
import { TelemetryEvents } from '../resources/TelemetryEvents';

export function useLoginOnStartup(shouldLogin?: boolean): [IUser | null] {
  const { authClient, telemetryClient } = useContext(ServiceContext);
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    telemetryClient.trackTrace({ message: TelemetryEvents.SessionStarted });

    if (user) return telemetryClient.setAuthenticatedUserContext(user.id);
  }, []);

  useEffect(() => {
    if (shouldLogin === false) return;
    // if (user) {
    //   telemetryClient.setAuthenticatedUserContext(user.id);
    //   setUser(user)
    // }
    // authClient.login().catch();

    if (user) return telemetryClient.setAuthenticatedUserContext(user.id);

    authClient
      .getUser()
      .then((user: IUser | null): void => {
        if (user) {
          // console.log('somnath auth setting existing user', user);
          // telemetryClient.setAuthenticatedUserContext(user.id);
          setUser(user)
        }
        else {
          // console.log('somnath auth no user login started');
          authClient.login().catch();
        }
      })
      .catch(() => {
        // console.log('somnath auth error getUser login again');
        authClient.login().catch();
      });
  }, [authClient, shouldLogin, telemetryClient, user]);

  return [user];
}
