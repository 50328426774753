import * as React from 'react';
import { ICustomNavLinkGroup, INavProps, INavStates, INavLinks, INavStyleProps, INavStyles, NavGroupType } from './Nav.types';
import { getStyles } from './Nav.styles';
import { NavBase } from './NavBase';
import { styled, classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { NavLink } from './NavLink';

const getClassNames = classNamesFunction<INavStyleProps, INavStyles>();

class SlimNavComponent extends NavBase {
  // store the previous floating nav shown to close when the current floating nav shows up.
  private _prevFloatingNav: any;

  constructor(props: INavProps) {
    super(props);

    this.state = {
      isLinkExpandStateChanged: false,
      selectedKey: props.initialSelectedKey || props.selectedKey,
      isGroupCollapsed: { workAround: false }
    };
  }

  public get selectedKey(): string | undefined {
    return this.state.selectedKey;
  }

  public render() {
    if (!this.props.groups || this.props.groups.length === 0) {
      return null;
    }

    // reset the flag
    // on render link, find if there is atleast one hidden link to display "Show more" link
    this._hasAtleastOneHiddenLink = false;

    return (
      <nav role="navigation">
        {this.props.groups.map((group: ICustomNavLinkGroup, groupIndex: number) => {
          return this._renderGroup(group, groupIndex);
        })}
      </nav>
    );
  }

  private _onLinkClicked(link: INavLinks, ev: React.MouseEvent<HTMLElement>): void {
    // set selected node
    const nextState: INavStates = {
      selectedKey: link.key,
      isGroupCollapsed: { workAround: false }
    };
    this.setState(nextState);

    const hasChildren = link.links && link.links.length > 0;

    // if there is no children and onClick handler is defined, call it
    if (!hasChildren && link.onClick) {
      if (!!this.props.onEditLeftNavClickedCallback && link.key && link.key === 'EditNavLink') {
        this.props.onEditLeftNavClickedCallback();
      } else {
        // if there is a onClick defined, call it
        link.onClick(ev, link);
      }
    }

    // prevent url action on anchor tag if the node has a children or if the onClick handler is defined
    if (hasChildren || link.onClick) {
      ev.preventDefault();
    }

    ev.stopPropagation();
  }

  private _getScrollTop(): number | undefined {
    if (!this.props.navScrollerId) {
      return undefined;
    }

    const navScroller = document.getElementById(this.props.navScrollerId) as HTMLElement;

    if (navScroller && navScroller.scrollTop > 0) {
      return navScroller.scrollTop;
    }

    return undefined;
  }

  private _onLinkMouseEnterOrLeave(link: INavLinks, ev: React.SyntheticEvent<HTMLElement>): void {
    link.scrollTop = this._getScrollTop();
    this.setState({ isLinkExpandStateChanged: true });

    ev.preventDefault();
    ev.stopPropagation();
  }

  private _getFloatingNav(parentElement: HTMLElement | null): HTMLDivElement | undefined {
    if (!parentElement) {
      return;
    }
    return parentElement.querySelector('[data-floating-nav]') as HTMLDivElement;
  }

  private _onKeyDown(link: INavLinks, ev: React.SyntheticEvent<HTMLElement>): void {
    const nativeEvent = ev as any;
    //console.log(nativeEvent.keyCode);
    if (nativeEvent.keyCode !== 13) {
      // accept only enter key to open the floating nav from slim nav
      return;
    }

    const a = nativeEvent.target as HTMLElement;
    const li = a.parentElement;
    const currentFloatingNav = this._getFloatingNav(li);

    if (!currentFloatingNav) {
      return;
    }

    if (this._prevFloatingNav === currentFloatingNav) {
      // toggle the floating nav
      if (currentFloatingNav.style && currentFloatingNav.style.display && currentFloatingNav.style.display === 'block') {
        currentFloatingNav.removeAttribute('style');
      } else {
        currentFloatingNav.setAttribute('style', 'display: block');
      }
    } else {
      // prev and current floating navs are different
      // close the previous if there is one
      if (this._prevFloatingNav) {
        this._prevFloatingNav.removeAttribute('style');
      }

      // open the current one
      currentFloatingNav.setAttribute('style', 'display: block');

      // store the current as prev
      this._prevFloatingNav = currentFloatingNav;
    }
  }

  private _renderLink(link: INavLinks, linkIndex: number, _nestingLevel: number): React.ReactElement<{}> | null {
    if (!link) {
      return null;
    }

    const { styles, showMore, onShowMoreLinkClicked, dataHint } = this.props;
    const isSelected = this.isLinkSelected(link, true /* includeChildren */);
    const hasChildren = !!link.links && link.links.length > 0;
    const classNames = getClassNames(styles!, { isSelected, hasChildren });
    const linkText = this.getLinkText(link, showMore);
    const onClickHandler = link.isShowMoreLink && onShowMoreLinkClicked ? onShowMoreLinkClicked : this._onLinkClicked.bind(this, link);
    const icon = showMore && link.secondaryIcon ? link.secondaryIcon : link.icon;

    return (
      <li
        role="listitem"
        key={link.key || linkIndex}
        onMouseEnter={this._onLinkMouseEnterOrLeave.bind(this, link)}
        onMouseLeave={this._onLinkMouseEnterOrLeave.bind(this, link)}
        onKeyDown={this._onKeyDown.bind(this, link)}
        className={classNames.navSlimItemRoot}
        title={linkText}
      >
        <NavLink
          id={link.key}
          title={linkText}
          href={link.url}
          target={link.target}
          dataHint={dataHint}
          dataValue={link.key}
          ariaLabel={linkText}
          role="link"
          onClick={onClickHandler}
          rootClassName={classNames.navItemRoot}
          leftIconName={icon}
          iconClassName={classNames.navItemIconColumn}
          barClassName={classNames.navItemBarMarker}
          aria-posinset={link['aria-posinset']}
          aria-setsize={link['aria-setsize']}
        />
      </li>
    );
  }

  private _renderLinks(links: INavLinks[], nestingLevel: number): React.ReactElement<{}> | null {
    if (!links || links.length === 0) {
      return null;
    }

    const { enableCustomization, showMore } = this.props;

    return (
      <ul role="list">
        {links.map((link: INavLinks, linkIndex: number) => {
          if (enableCustomization && link.isHidden && !showMore) {
            // atleast one link is hidden
            this._hasAtleastOneHiddenLink = true;

            // "Show more" overrides isHidden property
            return null;
          } else if (link.isShowMoreLink && !this._hasAtleastOneHiddenLink && !showMore) {
            // there is no hidden link, hide "Show more" link
            return null;
          } else {
            return this._renderLink(link, linkIndex, nestingLevel);
          }
        })}
      </ul>
    );
  }

  private _renderGroup(group: ICustomNavLinkGroup, groupIndex: number): React.ReactElement<{}> | null {
    if (!group || !group.links || group.links.length === 0) {
      return null;
    }

    const { enableCustomization } = this.props;

    // skip customization group if customization is not enabled
    if (!enableCustomization && group.groupType === NavGroupType.CustomizationGroup) {
      return null;
    }

    return (
      <div key={groupIndex}>
        {this._renderLinks(group.links, 0 /* nestingLevel */)}
      </div>
    );
  }
}

export const SlimNav = styled<INavProps, INavStyleProps, INavStyles>(SlimNavComponent, getStyles);
