import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '@msx/platform-types'

export interface IExtensionStyles {
  root: IStyle;
  header: IStyle;
  title: any;
  body: IStyle;
  link: any;
  message: IStyle;
  para: IStyle;
  sectionNear: IStyle;
  sectionFar: IStyle;
  column: IStyle;
  readonly: IStyle;
  hide: IStyle;
  displayField: IStyle;
  extensionErrorContainer: IStyle;
  loadingContainer: IStyle;
  grow: IStyle;
  dashboardContainer: IStyle;
  tileContainer: IStyle;
  spinnerContainer: IStyle;
}

export const getStyles = (theme: IAppTheme): IExtensionStyles => {
  return {
    root: {
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      //backgroundColor: '#ffffff',
      width: '100%',
      alignContent: 'center',
    },
    grow: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      font: {
        size: 18,
        weight: 600,
      },
    },
    body: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    link: {
      //color: theme.colors.Link.PrimaryLink,
      selectors: {
        '&:hover': {
          opacity: 0.5,
          cursor: 'pointer',
        }
      }
    },
    message: {
      width: '90%',
      fontFamily: 'Segoe UI',
      fontSize: '14px',
      //color: '#333333',
    },
    para: {
      margin: '5px 0',
    },
    sectionNear: {
      marginTop: '10px !important',
    },
    sectionFar: {
      marginTop: '30px !important',
    },
    column: {
      minWidth: '400px',
    },
    readonly: {
      color: '#666666',
      fontSize: '12px',
    },
    hide: {
      display: 'none',
    },
    displayField: {
      marginBottom: '10px !important',
    },
    extensionErrorContainer: {
      margin: '40px 20px',
    },
    loadingContainer: {
      margin: '40px 20px',
      //maxWidth: '700px',
    },
    dashboardContainer: {
      width: '392px',
      height: '230px',
      position: 'relative',
    },
    tileContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
    },
    spinnerContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
    },
  }
};


