import React, { useEffect, useRef, useState } from 'react';
import { IShellProps, ShellProps } from './Shell.types';
import {
  ITelemetryContext,
  IServiceContext,
} from '../../common/models'
import { useSelector, useDispatch } from 'react-redux';
import { ServiceContext } from '../../common/context/ServiceContext';
import * as constants from '../../common/resources/constants';

import { UsageHelper, UserAttribute } from '../../common/models/UsageTelemetry';

import { requestRoutes } from './Shell.actions';
import { shellAppSagas } from './Shell.sagas';
import { shellAppReducer } from './Shell.reducer';

import { userReducer } from '../user/User.reducer';
import { userSagas } from '../user/User.sagas';
import { requestUserInfo, requestUserPhoto } from '../User/User.actions';

export function Shell(props: ShellProps): React.ReactElement {
  const { children, appName, ...context } = props;
  const usageHelper = appName && appName.trim() != '' ? UsageHelper.Fork(appName) : UsageHelper;
  const telemetryContext: ITelemetryContext = {
    sourceComponent: 'Shell',
    sourceScript: 'main',
    setUsageEvent: usageHelper.MassageEvent,
    setUsageUser: (usageUser: UserAttribute) => {
      UsageHelper.SetUser(usageUser);
      usageHelper.SetUser(usageUser);
      return usageUser;
    },
    usageUser: usageHelper.GetUser,
    setUsageConfig: usageHelper.SetUsageConfig,
  };
  context.telemetryClient?.setContext(telemetryContext);

  return <ServiceContext.Provider value={{ ...context, telemetryContext }}>{children}</ServiceContext.Provider>;
}


// export const Shell: (React.FC<IShellProps>) = props => {
//   // const { 
//   //   authClient, 
//   //   telemetryClient, 
//   //   httpClient, 
//   //   graphClient, } = props.store.sagaContext;
//   const { children, store: storeBuilderResult } = props;
//   //, otherProps = __rest(props, ["children", "store"]);
//   const { store, reducerRegistry, runSaga, initialState } = storeBuilderResult;
//   const { authClient, telemetryClient, httpClient, graphClient } = sagaContext;

//   const loggingContext = {
//     userId: authClient.getUserId() || '',
//     component: 'ShellApp',
//     telemetrySource: 'UI'
//   };
//   const componentContext: IServiceContext = {
//     id: 'msx-shell',
//     authClient: authClient,
//     telemetryClient: telemetryClient,
//     httpClient: httpClient,
//     graphClient: graphClient,
//     useSelector: useSelector,
//     useDispatch: useDispatch,
//   }

//   useEffect(() => {
//     if (authClient.isLoggedIn()) {
//       console.log('somnath user is already logged in ...calling apis')
//       //telemetryClient.logTrace(constants.SessionStarted, loggingContext);
//       reducerRegistry.register('appSettings', shellAppReducer);
//       reducerRegistry.register('user', userReducer);
//       runSaga(shellAppSagas);
//       runSaga(userSagas);
//       store.dispatch(requestRoutes(initialState.appSettings));
//       store.dispatch(requestUserPhoto());
//       store.dispatch(requestUserInfo());
//     }
//     else {
//       console.log('somnath user is not logged in');
//       //telemetryClient.trackTrace(constants.SessionStartedUnauthenticated, loggingContext);
//     }
//     // eslint-disable-next-line
//   }, []);



//   const renderMain = (): JSX.Element => {
//     return (
//       <ServiceContext.Provider value={componentContext}>
//         {props.children}
//       </ServiceContext.Provider>
//     );
//   }
//   return renderMain();
// }

