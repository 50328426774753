
import * as React from 'react';
import { Nav } from './Nav';
import { ICustomNavLinkGroup, INavProps, INavStates, INavStyleProps, INavStyles, NavGroupType } from './Nav.types';
import { SlimNav } from './SlimNav';
import { getStyles } from './Nav.styles';
import { styled, classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { NavLink } from './NavLink';
import { FocusZone, FocusZoneDirection, FocusZoneTabbableElements } from 'office-ui-fabric-react/lib/FocusZone';

const getClassNames = classNamesFunction<INavStyleProps, INavStyles>();

class NavTogglerComponent extends React.Component<INavProps, INavStates> {
  constructor(props: INavProps) {
    super(props);

    this.state = {
      isNavCollapsed: props.isNavCollapsed ? props.isNavCollapsed : false,
      showMore: props.showMore ? props.showMore : false,
      isGroupCollapsed: { workAround: false }
    };

    this._onShowMoreLinkClicked = this._onShowMoreLinkClicked.bind(this);
  }

  public render() {
    if (!this.props.groups || this.props.groups.length == 0) {
      return null;
    }

    const { isNavCollapsed, showMore } = this.state;

    const { styles, groups } = this.props;
    const classNames = getClassNames(styles!, { isCollapsed: isNavCollapsed });

    const toggleNavGroups = groups.filter((navGroup: ICustomNavLinkGroup) => {
      return !!navGroup && navGroup.groupType === NavGroupType.ToggleGroup;
    });

    const nonToggleNavGroups = groups.filter((navGroup: ICustomNavLinkGroup) => {
      return !!navGroup && navGroup.groupType !== NavGroupType.ToggleGroup;
    });

    return (
      <div className={classNames.root}>
        <FocusZone direction={FocusZoneDirection.vertical}
          handleTabKey={FocusZoneTabbableElements.all} >
          {this._renderExpandCollapseNavItem(toggleNavGroups)}
          {isNavCollapsed ? (
            <SlimNav
              groups={nonToggleNavGroups}
              selectedKey={this.props.selectedKey}
              navScrollerId={this.props.navScrollerId}
              dataHint={this.props.dataHint}
              enableCustomization={this.props.enableCustomization}
              showMore={showMore}
              onShowMoreLinkClicked={this._onShowMoreLinkClicked}
              onEditLeftNavClickedCallback={this.props.onEditLeftNavClickedCallback}
            />
          ) : (
              <Nav
                groups={nonToggleNavGroups}
                selectedKey={this.props.selectedKey}
                dataHint={this.props.dataHint}
                enableCustomization={this.props.enableCustomization}
                showMore={showMore}
                onShowMoreLinkClicked={this._onShowMoreLinkClicked}
                onNavNodeExpandedCallback={this.props.onNavNodeExpandedCallback}
                onEditLeftNavClickedCallback={this.props.onEditLeftNavClickedCallback}
              />
            )}
        </FocusZone>
      </div>
    );
  }
  private _onNavCollapseClicked(ev: React.MouseEvent<HTMLElement>): void {
    this.setState((prevState: INavStates) => {
      const isNavCollapsed = !prevState.isNavCollapsed;

      // inform the caller about the collapse event
      if (!!this.props.onNavCollapsedCallback) {
        this.props.onNavCollapsedCallback(isNavCollapsed);
      }

      return {
        isNavCollapsed: isNavCollapsed
      };
    });

    ev.preventDefault();
    ev.stopPropagation();
  }

  private _renderExpandCollapseNavItem(toggleNavGroups: ICustomNavLinkGroup[]): React.ReactElement<{}> | null {
    if (
      (!!toggleNavGroups && toggleNavGroups.length === 0) ||
      !toggleNavGroups[0].links ||
      toggleNavGroups[0].links.length === 0
    ) {
      // There is no toggle group with links defined
      return null;
    }

    const isNavCollapsed = this.state.isNavCollapsed;
    const { styles, dataHint } = this.props;
    const classNames = getClassNames(styles!, {});
    const link = toggleNavGroups[0].links[0];
    const ariaLabel = isNavCollapsed ? link.name : link.alternateText;

    /* If link == '#' that means we are using the link as a button (e.g. nav toggler). Since
     * we don't want the screen reader to announce the url in that case, we are setting the link
     * to null. Feel free to change this approach if you there's a better solution.
     */
    const href = link.url === '#' ? null : link.url

    return (
      <NavLink
        id={link.key}
        href={href}
        onClick={this._onNavCollapseClicked.bind(this)}
        ariaExpanded={!isNavCollapsed}
        dataHint={dataHint}
        dataValue={link.key}
        ariaLabel={ariaLabel}
        rootClassName={classNames.navToggler}
        leftIconName={link.icon}
        iconClassName={classNames.navItemIconColumn}
        barClassName={classNames.navItemBarMarker}
        role="button"
      />
    );
  }

  private _onShowMoreLinkClicked(ev: React.MouseEvent<HTMLElement>): void {
    this.setState((prevState: INavStates) => {
      return {
        showMore: !prevState.showMore
      };
    });

    ev.preventDefault();
    ev.stopPropagation();
  }
}

export const NavToggler = styled<INavProps, INavStyleProps, INavStyles>(NavTogglerComponent, getStyles);
