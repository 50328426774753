import {
  IAppExtension,
  IAppExtensionFile,
  IAppExtensionPage,
  IAppExtensionTile,
  IAppRoute,
  IExtensionComponent,
  IExtensionsRegistrationClient
} from "../../common/models";
import { IExtensionsSchemaLoader } from "./ExtensionSchemaLoader.types";

export class ExtensionSchemaLoader implements IExtensionsSchemaLoader {
  private readonly extensionsRegistrationClient: IExtensionsRegistrationClient;
  private componentKey: string;

  public constructor(extensionsRegistrationClient: IExtensionsRegistrationClient, componentKey: string,) {
    this.extensionsRegistrationClient = extensionsRegistrationClient;
    this.componentKey = componentKey;
  }

  public getCurrentExtensionComponent(): IExtensionComponent {
    return this.getExtensionComponent(this.componentKey);
  }

  public getExtensionComponentByComponentKey(componentKey: string): IExtensionComponent {
    return this.getExtensionComponent(componentKey);
  }

  public getExtensionComponentByRouteKey(routeKey: string): IExtensionComponent {
    const page = this.getPageExtensionByRouteKey(routeKey);
    if (!page)
      return null;
    const componentKey = page.key;
    return this.getExtensionComponent(componentKey);
  }

  private getExtensionComponent(componentKey: string): IExtensionComponent {
    const extension = this.getExtension(componentKey);
    const files = this.getExtensionFiles(componentKey);
    const page = this.getPageExtensionByComponentKey(componentKey);
    const tile = this.getTileExtension(componentKey);
    const routes = this.extensionsRegistrationClient.getRoutes();
    if (!page && !tile)
      return null;
    return this.buildExtensionComponent(extension, files, page, tile, routes);

  }

  private buildExtensionComponent(extension: IAppExtension,
    files: IAppExtensionFile[],
    page: IAppExtensionPage,
    tile: IAppExtensionTile,
    routes: IAppRoute[]): IExtensionComponent {

    const isDashboard = tile ? true : false;
    const extensionComponent: IExtensionComponent = {
      key: isDashboard ? tile.key : page.key,
      name: isDashboard ? tile.name : page.name,
      description: isDashboard ? tile.description : page.description,
      component: isDashboard ? tile.component : page.component,
      isDashboard: isDashboard,
      isInIDE: tile ? tile.isInIDE : page.isInIDE,
      // extension related
      extensionKey: extension.key,
      extensionName: extension.name,
      feedbackEmail: extension.feedbackEmail,
      version: extension.version,
      // files
      files: files,
      // page related
      showNavigation: isDashboard ? null : page.showNavigation,
      icon: isDashboard ? null : page.icon,
      routeKey: isDashboard ? null : page.routeKey,
      routePath: isDashboard ? null : page.routePath,
      tileKey: isDashboard ? null : page.tileKey,
      search: isDashboard ? null : page.search,
      exclude: isDashboard ? null : page.exclude,
      // tile related
      tooltip: isDashboard ? tile.tooltip : null,
      // app route related
      appRoutes: routes,
      active: isDashboard ? tile.active : page.active,
    }
    return extensionComponent;
  }

  private getPageExtensionByComponentKey(componentKey: string): IAppExtensionPage {
    const pages = this.extensionsRegistrationClient.getExtensionsPages();
    return pages.find(item => item.active && item.key.toLowerCase() === componentKey.toLocaleLowerCase());
  }

  private getPageExtensionByRouteKey(routeKey: string): IAppExtensionPage {
    const pages = this.extensionsRegistrationClient.getExtensionsPages();
    return pages.find(item => item.active && item.routeKey.toLowerCase() === routeKey.toLocaleLowerCase());
  }

  private getTileExtension(componentKey: string): IAppExtensionTile {
    const tiles = this.extensionsRegistrationClient.getExtensionsTiles();
    return tiles.find(item => item.active && item.key.toLowerCase() === componentKey);
  }

  private getExtension(componentKey: string): IAppExtension {
    let result: IAppExtension = null;
    const page = this.getPageExtensionByComponentKey(componentKey);
    const tile = this.getTileExtension(componentKey);
    if (!page && !tile)
      return result;
    const isDashboard = tile ? true : false;

    const extensions = this.extensionsRegistrationClient.getExtensions();
    extensions.map(extension => {
      if (isDashboard) {
        const tiles = extension.tiles.find(item => item.component === tile.component);
        if (tiles) {
          result = extension;
        }
      }
      else {
        const pages = extension.pages.find(item => item.component === page.component);
        if (pages) {
          result = extension;
        }
      }
    })
    return result;
  }


  private getExtensionFiles(componentKey: string): IAppExtensionFile[] {
    const result: IAppExtensionFile[] = [];
    const page = this.getPageExtensionByComponentKey(componentKey);
    const tile = this.getTileExtension(componentKey);
    if (!page && !tile)
      return result;
    const isDashboard = tile ? true : false;

    const extensions = this.extensionsRegistrationClient.getExtensions();
    extensions.map(extension => {
      if (isDashboard) {
        const tiles = extension.tiles.find(item => item.component === tile.component);
        if (tiles) {
          result.push(...extension.files);
        }
      }
      else {
        const pages = extension.pages.find(item => item.component === page.component);
        if (pages) {
          result.push(...extension.files);
        }
      }
    })
    const activeFiles = result.filter(item => item.active);
    return activeFiles;

  }

}
