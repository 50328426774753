import * as React from 'react';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IDxpModalProps, IDxpModalState, IDxpModalStyles } from './DxpModal.types';
import { getStyles, getRootStyle } from './DxpModal.styles';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { closeMessage, modalIsOpen } from './DxpModalMessage';
import { Announced, ContextualMenu, Modal, IDragOptions, Stack } from 'office-ui-fabric-react';

export class DxpModal extends React.Component<IDxpModalProps, IDxpModalState> {
  constructor(props: IDxpModalProps) {
    super(props);
    this.state = {
      closeModal: false,
      announcedMessage: `${closeMessage} ${this.props.title} ${modalIsOpen}`
    };
  }

  renderSubHeading() {
    if (this.props.onRenderSubHeader) {
      return this.props.onRenderSubHeader();
    }
  }

  renderCloseButton() {
    const getClassNames = classNamesFunction<IDxpModalProps, IDxpModalStyles>();
    const classNames = getClassNames(getStyles(this.props));
    return (
      <span className={[classNames.closeIcon, classNames.closeIconContainer].join(' ')}>
        <TooltipHost
          content={closeMessage} 
        ></TooltipHost>
        <IconButton
          iconProps={{
            iconName: 'ChromeClose'
          }}
          ariaLabel={`${this.props.title} close`}
          onClick={() => this.closeModal()}
        />
      </span>
    );
  }

  public render() {
    const getClassNames = classNamesFunction<IDxpModalProps, IDxpModalStyles>();
    const classNames = getClassNames(getStyles(this.props));
    return (
      <div>
        <Announced message={`${closeMessage} ${this.props.title} ${modalIsOpen}`} />
        <Modal
          isOpen={!this.state.closeModal}
          onDismiss={this.closeModal}
          isBlocking={this.props.isBlocking || true}
          styles={getRootStyle(this.props)}
          isDarkOverlay={true}
        >
          <Stack wrap>
          <Stack.Item align='end'>
                  {this.renderCloseButton()}
                </Stack.Item>
            <Stack horizontal horizontalAlign={'space-between'} wrap className={classNames.headerRow}>
              <h1 className={classNames.titleText}>{this.props.title}</h1>
              <Stack wrap>
                {this.renderSubHeading()}
              </Stack>
            </Stack>
            <div className={classNames.contentArea}>
              {this.props.children}
            </div>
          </Stack>
        </Modal>
      </div>
    );
  }
  private closeModal = (ev?: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement> | undefined): void => {
    let isDismissConfirmed = true;
    // Invoke onDismiss Callback if passed.
    if (this.props.onDismiss) {
      // Do not dismiss modal if callback returns false.
      isDismissConfirmed = this.props.onDismiss(ev);
    }
    this.setState({ closeModal: isDismissConfirmed });
  }
}
