import { IStyle } from 'office-ui-fabric-react/lib/Styling';

export interface ITableStyles {
  tableTitle: IStyle;
  tableBody: IStyle;
  tableStackItem: IStyle;
  rootStack: IStyle
  csvBtnRoot: IStyle
  csvBtnText: IStyle
  csvBtnIcon: IStyle
}

export const getStyles = (props: any): ITableStyles => {
  const marginTop = props && props.tableTitle ? '20px' : '';
  const height = props && props.isModal ? '100%' : 'auto';
  return {
    tableTitle: {
      fontSize: "18px",
      fontStretch: "normal",
      fontFamily: "Segoe UI",
      // color: "#000000",
      minWidth: '400px',
      margin: "10px 0"
    },
    tableBody: {
      marginTop: '20px',
      //height: '100%'
    },
    tableStackItem: {
      position: 'relative',
      marginTop: marginTop,
      //height: height
    },
    rootStack: {
      //width: '100%',
    },
    csvBtnRoot: {
      width: '100%',
      height: '36px'
    },
    csvBtnText: {
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontSize: '12px',
      //color: '#0073D1',
      fontWeight:'600',
    },
    csvBtnIcon: {
      width: '14px',
      height: '14px',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      //color: '#0070cc',
      paddingTop: '2px'
    },
  }
};
