import { IStyle } from 'office-ui-fabric-react/lib/Styling';

export interface IDxpModalProps {
  title: string;
  height: DxpModalHeight;
  isBlocking?: boolean;
  onDismiss?: (ev?: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement>) => any;
  onRenderSubHeader?: () => JSX.Element;
}

export interface IDxpModalState {
  closeModal: boolean;
  announcedMessage?: string;
}

export interface IDxpModalStyles {
  headerArea: IStyle;
  headerRow: IStyle;
  contentArea: IStyle;
  titleTextContainer: IStyle;
  titleText: IStyle;
  closeIconContainer: IStyle;
  closeIcon: IStyle;
}

export enum DxpModalHeight {
  Min,
  Max,
}
