import React, { useState, useEffect } from 'react';
import { Stack } from 'office-ui-fabric-react';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { VirtualizedComboBox, IComboBox } from 'office-ui-fabric-react/lib/index';
import { getTableFilterDropdownStyles } from './TableFilterDropdown.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import {IAppTheme} from '../../../theme/Theme.types'
const getClassNames = classNamesFunction<any, any>();
let classes: any;

export interface Props {
  altKey: number; // Same as key, but passed into props (cannot pass key into props).
  items: any[];
  columns: any[];
  onFilterChange?: (data: FilterChangeData) => void;
  intl?: any; // This will be injected by injectIntl.
  theme:IAppTheme;
}

export interface FilterChangeData {
  altKey: number;
  column: string;
  filter: string;
}

export const TableFilterDropdown: React.FC<Props> = props => {
  classes = getClassNames(getTableFilterDropdownStyles(props.theme))
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [isColumnSelected, setIsColumnSelected] = useState<boolean>(false);
  const filterDropdownRef = React.createRef<IComboBox>();

  const getFilterOptions = (items: any) => {
    const filtersArr = [];
    const map = new Map();
    for (const item of items) {
      if (item[selectedColumn] == undefined && item.isNRC === false && item.priceLine !== null) {
        for (const priceLine of item.priceLine) {
          if (!map.has(priceLine[selectedColumn])) {
            map.set(priceLine[selectedColumn], true);
            filtersArr.push({
              key: priceLine[selectedColumn],
              text: `${priceLine[selectedColumn]}`
            });
          }
        }
      }
      else if (item[selectedColumn] !== undefined && !map.has(item[selectedColumn])) {
        map.set(item[selectedColumn], true);
        filtersArr.push({
          key: item[selectedColumn],
          text: `${item[selectedColumn]}`
        });
      }
    }
    return filtersArr;
  }

  const handleColumnChange = (evt: any, option?: any) => {
    if (option) {
      setSelectedKey(option.key);
      setSelectedColumn(option.fieldName);
    }
    if (option.key == '') {
      setIsColumnSelected(false);
    } else {
      setIsColumnSelected(true);
    }

    filterDropdownRef.current.focus(true);
  }

  const handleFilterChange = (evt: any, option: any) => {
    if (option) {
      setSelectedFilter(option.key);
    }
  }

  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [columnOptions, setColumnOptions] = useState<any[]>(props.columns)
  const [filterOptions, setFilterOptions] = useState<any>(getFilterOptions(props.items))

  useEffect(() => {
    setFilterOptions(getFilterOptions(props.items));
  }, [selectedColumn])

  useEffect(() => {
    if (props.onFilterChange) {
      props.onFilterChange({ altKey: props.altKey, column: selectedColumn, filter: selectedFilter });
    }
  }, [selectedColumn, selectedFilter])

  const columnCompare = (a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const filterCompare = (a: any, b: any) => {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  }

  const chooseColumnLabel = 'Choose Column';
  const chooseFilterLabel = 'Choose Filter';
  const selectionPlaceHolder = 'selectionPlaceHolder';

  return (
    <Stack horizontal wrap className={classes.containerStack} tokens={{ childrenGap: 10 }}>
      <Dropdown
        styles={classes.dropdown}
        label={chooseColumnLabel}
        options={columnOptions.sort(columnCompare)}
        onChange={handleColumnChange}
        selectedKey={selectedKey}
        placeHolder={selectionPlaceHolder}
      />
      <VirtualizedComboBox
        className={classes.dropdown}
        label={chooseFilterLabel}
        allowFreeform={true}
        options={filterOptions.sort(filterCompare)}
        useComboBoxAsMenuWidth={true}
        componentRef={filterDropdownRef}
        onChange={handleFilterChange}
        disabled={!isColumnSelected}
        placeholder={selectionPlaceHolder}
      />
    </Stack>
  )
}

export default TableFilterDropdown;
