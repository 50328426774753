import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import {IAppTheme} from '../../theme/Theme.types'

export interface IPageTitleStyles {
  root: IStyle;
  pageTitle: IStyle;
  headingComponent: IStyle;
  headingButton: IStyle;
  headingRow: IStyle;
  table: IStyle;
  seeAllBtnTitle: IStyle;
}

export const getStyles = (theme:IAppTheme): IPageTitleStyles => {
  return {
    root: {
      marginLeft: '20px',
    },
    pageTitle: {
      //color: theme.colors.Text.HeadingMain
    },
    headingComponent: {
      maxWidth: '350px',
      maxHeight: '180px'
    },
    headingButton: {
      fontSize: '12px',
      fontWeight: '600',
      //color: '#0078d7',
      marginLeft: '10px'
    },
    headingRow: {
      maxHeight: '43px',
    },
    table: {
      //backgroundColor: '#ffffff',
      width: '100%'
    },
    seeAllBtnTitle: {
      color: theme.palette.themePrimary,
    }
  }
}
