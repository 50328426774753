import * as React from 'react';
import { getStyles } from './MsxContactUs.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IAppTheme } from '../../theme/Theme.types'

export interface OwnProps {
  title?: string;
  message: string;
  id: string;
  theme: IAppTheme;
  errorText1?: string;
  errorText2?: string;
  handleFeedback?: () => void;
}
type Props = OwnProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;

class MsxContactUs extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    classes = getClassNames(getStyles(props.theme));
  }

  handleFeedback = () => {
    if (typeof this.props.handleFeedback === 'function') {
      this.props.handleFeedback();
      return;
    }
    (window as any).startMultiFeedback_AllOptional();
  }

  render() {
    const p: any = this.props;
    const text1 = this.props.errorText1 ? this.props.errorText1 : "If you feel like this is an error,"
    const text2 = this.props.errorText2 ? this.props.errorText2 : "please let us know."
    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <p className={classes.message}>
            <span>
              {this.props.message}
            </span>
            <span>
              &nbsp;{text1} <a id={this.props.id} className={classes.link} onClick={this.handleFeedback}>{text2}</a>
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default MsxContactUs;


