import * as React from 'react';
import { ICardProps } from './Card.types';
import { CardFrame } from './CardFrame';
import { getStyles } from './HeroCard.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IDxpCardStyles, IHeroCardProps } from './DxpCard.types';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export type HeroCardProps = ICardProps & IHeroCardProps;

export class HeroCard extends React.Component<HeroCardProps> {
  constructor(props: HeroCardProps) {
    super(props);
  }

  public render(): JSX.Element {
    const { cardFrameContent } = this.props;
    const getClassNames = classNamesFunction<HeroCardProps, IDxpCardStyles>();
    const classNames = getClassNames(getStyles);
    const contentElement: JSX.Element | null = this.getContentArea(classNames.contentArea1, classNames.loading);
    const fontFamily: string = '"Segoe UI Semibold", "Segoe UI Web (West European)", "Segoe UI",' +
            ' -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif';
    const fontSize = '14px';

    return (
      <div className={classNames.root}>
        <CardFrame
          cardTitle={cardFrameContent.cardTitle}
          fontFamily={fontFamily}
          fontSize={fontSize}
        >
          <div className={classNames.layoutRoot} >
            <div className={classNames.contentAreaLayout}>{contentElement}</div>
          </div>
        </CardFrame>
      </div>
    );
  }

  private getContentArea(
    contentArea1ClassName: string,
    loadingClassName: string
  ): JSX.Element | null {
    if (this.props.loading) {
      return this.getLoadingContent(loadingClassName);
    } else {
      return <div className={contentArea1ClassName}>{this.props.children}</div>;
    }
  }

  private getLoadingContent(loadingClassName: string) {
    return (
      <div className={loadingClassName}>
        <Spinner size={SpinnerSize.large} />
      </div>
    );
  }
}