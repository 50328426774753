import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { IAppTheme } from '@msx/platform-types'
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { getStyles } from './Extension.styles'
import { ActionButton, IIconProps } from 'office-ui-fabric-react';
import { IExtensionMessage, getDefaultExtensionMessages } from './Extension.types';

export interface PageErrorProps { // extends InjectedIntlProps {
  isDashboard: boolean;
  extensionName: string;
  componentName: string;
  errorMessage: string;
  error: string;
  handleRefreshTile: () => void;
  handleFeedback: () => void;
  theme: IAppTheme;
  messages: IExtensionMessage;

}
const getClassNames = classNamesFunction<any, any>();
let classes: any;

export const PageError: (React.FC<PageErrorProps>) = props => {
  const { theme } = props;
  classes = getClassNames(getStyles(theme as IAppTheme));
  const messages = props.messages ? props.messages : getDefaultExtensionMessages();

  const renderExtensionErrorPageContent = (): JSX.Element => {
    const containerStackTokens: IStackTokens = { childrenGap: 20 };
    const stackTokens: IStackTokens = { childrenGap: 10 };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };
    const feedbackIcon: IIconProps = { iconName: 'Feedback' };
    return (
      <Stack tokens={containerStackTokens}>
        <span>{messages.extensionErrorMessageTitle}</span>
        <Stack horizontal horizontalAlign="start" >
          <div className={classes.column}>
            <Stack>
              <span className={classes.readonly} >{messages.extensionErrorDetailName}</span>
              <span className={classes.displayField} >{props.extensionName}</span>
            </Stack>
            <Stack>
              <span className={classes.readonly} >{messages.extensionErrorDetailErrorReason}</span>
              <span className={classes.displayField}>{props.errorMessage}</span>
            </Stack>
          </div>
          <div className={classes.column}>
            <Stack>
              <span className={classes.readonly} >{messages.extensionErrorDetailComponent}</span>
              <span className={classes.displayField}>{props.componentName}</span>
            </Stack>

          </div>
        </Stack>
        <Stack >
          <span className={classes.readonly} >{messages.extensionErrorDetailErrorStack}</span>
          <span className={classes.displayField}>{props.error}</span>
          <div className={props.isDashboard ? classes.hide : classes.sectionFar} >
            <Stack horizontal tokens={stackTokens}>
              <ActionButton iconProps={refreshIcon} onClick={props.handleRefreshTile}>
                {messages.extensionErrorMessageRefresh}
              </ActionButton>
              <ActionButton iconProps={feedbackIcon} onClick={props.handleFeedback}>
                {messages.extensionErrorMessageFeedback}
              </ActionButton>
            </Stack>
          </div>
        </Stack>
      </Stack>
    );
  }

  const renderMain = (): JSX.Element => {
    return renderExtensionErrorPageContent()
  }
  return renderMain();
}

export default PageError;
