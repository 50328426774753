//import { Middleware } from 'redux';
import { createStore, applyMiddleware, Middleware, combineReducers, ReducersMapObject, Reducer } from 'redux';
import { IReducerRegistry, ReducerNames } from '../models/IReducerRegistry';
import { IStoreBuilder, IOptionalPersistConfig } from '../models/IStoreBuilder';
import { IStoreBuilderResult } from '../models/IStoreBuilderResult';
import { IAppState, } from '@msx/platform-types'
import { ISagaContext } from '../models/ISagaContext';
//import { PersistConfig } from 'redux-persist';
import { ReduxLoggerOptions } from 'redux-logger';
import { IServiceContext } from '../models/IServiceContext';
import { persistReducer, persistStore, PersistConfig } from 'redux-persist';

//import createSagaMiddleware from 'redux-saga';
//import { createStore, applyMiddleware, combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
//import { persistReducer, persistStore } from 'redux-persist';
import persistStorage from 'redux-persist/es/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

export class StoreBuilder<T extends IAppState> implements IStoreBuilder<T> {
  private readonly initialState: T;
  private readonly reducerRegistery: IReducerRegistry;

  private middlewares: Middleware[] = [];
  private sagaMiddleware: SagaMiddleware | undefined;
  private context: IServiceContext | undefined;
  private persistConfig: PersistConfig<T> = {
    key: 'root',
    storage: persistStorage,
    stateReconciler: autoMergeLevel2,

    // https://github.com/rt2zz/redux-persist/issues/786
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    timeout: null,
  };
  private loggerConfig: ReduxLoggerOptions = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rootSagas: any[] = [];

  public constructor(reducerRegistery: IReducerRegistry, initialState: T = { dynamic: {} } as T) {
    this.reducerRegistery = reducerRegistery;
    //console.log('somnath initialState');
    //console.log(initialState);
    this.initialState = { dynamic: { ...initialState.dynamic }, ...initialState };
    //console.log('somnath initialState this');
    //console.log(this.initialState);
  }

  public configureSaga(context: IServiceContext): IStoreBuilder<T> {
    this.context = context;
    this.sagaMiddleware = createSagaMiddleware({
      context,
    });
    this.middlewares.push(this.sagaMiddleware);

    return this;
  }

  public configureLogger(enableOrOption: boolean | ReduxLoggerOptions | undefined): IStoreBuilder<T> {
    if (enableOrOption !== false) {
      const opt = {
        ...this.loggerConfig,
        ...(typeof enableOrOption !== 'boolean' ? enableOrOption : {}),
      };

      this.middlewares.push(createLogger(opt));
    }

    return this;
  }

  public configurePersistor(config: IOptionalPersistConfig<T>): IStoreBuilder<T> {
    this.persistConfig = {
      ...this.persistConfig,
      ...config,
    };

    return this;
  }

  public addMiddleware(middleware: Middleware): IStoreBuilder<T> {
    this.middlewares.push(middleware);

    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addRootSagas(sagas: any[]): IStoreBuilder<T> {
    this.rootSagas = sagas;

    return this;
  }

  public build(): IStoreBuilderResult<T> {
    if (!this.sagaMiddleware) throw new Error('Saga middleware was not configured.');
    if (!this.context) throw new Error('Context must be configured using configureContext method');

    const reducers = this.combine(
      this.reducerRegistery.getReducers(),
      this.reducerRegistery.getDynamicReducers(),
      [],
      this.initialState
    );
    const persistedReducer = persistReducer(this.persistConfig, reducers);
    const ifDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const store = createStore(
      persistedReducer,
      this.initialState,
      !ifDev ? applyMiddleware(...this.middlewares) : composeWithDevTools(applyMiddleware(...this.middlewares))
    );
    const persistor = persistStore(store);

    this.reducerRegistery.addChangeListener(
      'default',
      (
        newReducers: ReducersMapObject,
        newDynamicReducers: ReducersMapObject,
        persistBlacklistedDynamicReducers: ReducerNames
      ): void => {
        store.replaceReducer(
          persistReducer(
            this.persistConfig,
            this.combine(
              newReducers,
              newDynamicReducers,
              persistBlacklistedDynamicReducers,
              store.getState()
            )
          )
        );
        persistor.persist();
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.rootSagas.map((saga) => this.sagaMiddleware!.run(saga));

    return {
      store,
      reducerRegistry: this.reducerRegistery,
      runSaga: this.sagaMiddleware.run,
      context: this.context,
    };
  }

  private combine(
    reducers: ReducersMapObject,
    dynamicReducers: ReducersMapObject,
    persistBlacklistedDynamicReducers: ReducerNames,
    currentState: T | null = null
  ): Reducer {
    const reducerNames = Object.keys(reducers);
    const dynamicReducerNames = Object.keys(dynamicReducers);

    Object.keys(currentState || this.initialState).forEach((item: string): void => {
      if (reducerNames.indexOf(item) === -1) {
        reducers[item] = (state = null): {} => state;
      }
    });
    //console.log('somnath reducers');
    //console.log(reducers);


    Object.keys((currentState && currentState.dynamic) || {}).forEach((item: string) => {
      if (dynamicReducerNames.indexOf(item) === -1) {
        dynamicReducers[item] = (state = null): {} => state;
      }
    });

    if (dynamicReducerNames.length > 0) {
      reducers.dynamic = persistReducer(
        {
          ...this.persistConfig,
          key: `${this.persistConfig.key}.dynamic`,
          whitelist: undefined,
          blacklist: persistBlacklistedDynamicReducers,
        },
        combineReducers(dynamicReducers)
      );
    }

    return combineReducers(reducers);
  }
}

// export class StoreBuilder<T extends IAppState> implements IStoreBuilder<T> {
//   private readonly initialState;
//   private middlewares;
//   private readonly reducerRegistery;
//   private sagaMiddleware;
//   private sagaContext;
//   private persistConfig;
//   private rootSagas;

//   constructor(reducerRegistery: IReducerRegistry, initialState: T) {
//     this.middlewares = [];
//     this.persistConfig = {
//       key: 'root',
//       storage: persistStorage,
//       stateReconciler: autoMergeLevel2,
//       // https://github.com/rt2zz/redux-persist/issues/786
//       // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//       // @ts-ignore
//       timeout: null
//     };
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     this.rootSagas = [];
//     this.reducerRegistery = reducerRegistery;
//     this.initialState = Object.assign({ dynamic: {}, user: {} }, initialState);
//   }

//   configureSaga(context: C): IStoreBuilder<T, C> {
//     this.sagaContext = context;
//     this.sagaMiddleware = createSagaMiddleware({
//       context
//     });
//     this.middlewares.push(this.sagaMiddleware);
//     return this;
//   }

//   configureLogger(options?: ReduxLoggerOptions): IStoreBuilder<T, C> {
//     this.middlewares.push(createLogger(options));
//     return this;
//   }

//   configurePersistor(config: PersistConfig): IStoreBuilder<T, C> {
//     this.persistConfig = config;
//     return this;
//   }

//   addMiddleware(middleware: Middleware): IStoreBuilder<T, C> {
//     this.middlewares.push(middleware);
//     return this;
//   }

//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   addRootSagas(sagas: any[]): IStoreBuilder<T, C> {
//     this.rootSagas = sagas;
//     return this;
//   }

//   build(): IStoreBuilderResult<T, C> {
//     if (!this.sagaMiddleware)
//       throw new Error('Saga middleware was not configured.');
//     if (!this.sagaContext)
//       throw new Error('Saga context is required');
//     const reducers = this.combine(this.reducerRegistery.getReducers(), this.reducerRegistery.getDynamicReducers(), this.initialState);
//     const persistedReducer = persistReducer(this.persistConfig, reducers);
//     const store = createStore(persistedReducer, this.initialState, applyMiddleware(...this.middlewares));
//     const persistor = persistStore(store);
//     const rootSagas = this.rootSagas;
//     this.reducerRegistery.addChangeListener('default', (newReducers, newDynamicReducers) => {
//       store.replaceReducer(persistReducer(this.persistConfig, this.combine(newReducers, newDynamicReducers, store.getState())));
//       persistor.persist();
//     });
//     this.sagaMiddleware.run(function* () {
//       yield all([...rootSagas]);
//     });
//     return {
//       store,
//       reducerRegistry: this.reducerRegistery,
//       runSaga: this.sagaMiddleware.run,
//       sagaContext: this.sagaContext,
//       initialState: this.initialState
//     };
//   }

//   private combine(reducers, dynamicReducers, currentState = null) {
//     const reducerNames = Object.keys(reducers);
//     const dynamicReducerNames = Object.keys(dynamicReducers);
//     Object.keys(currentState || this.initialState).forEach((item) => {
//       if (reducerNames.indexOf(item) === -1) {
//         reducers[item] = (state = null) => state;
//       }
//     });
//     Object.keys((currentState && currentState.dynamic) || {}).forEach((item) => {
//       if (dynamicReducerNames.indexOf(item) === -1) {
//         dynamicReducers[item] = (state = null) => state;
//       }
//     });
//     if (dynamicReducerNames.length > 0) {
//       reducers.dynamic = combineReducers(dynamicReducers);
//     }
//     return combineReducers(reducers);
//   }
// }
