import * as React from 'react';
import { ICardFrameProps, ICardFrameStyles } from './MsxCardFrame.types';
import { IconButton, Button } from 'office-ui-fabric-react/lib/Button';
import { getStyles } from './MsxCardFrame.styles';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IProcessedStyleSet } from 'office-ui-fabric-react/lib/Styling';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { IContextualMenuProps } from 'office-ui-fabric-react';
import { Link, Text } from '@fluentui/react';

export interface IViewState {
}

const DASHBOARD_EDIT_EVENT = "MsxDashboardEditMode";
const DACHBOARD_EDIT_ACTION_EVENT = "MsxDashboardEditAction";
const DASHBOARD_EDIT_ACTION_MOVE_FORWARD = 'MoveForward';
const DASHBOARD_EDIT_ACTION_MOVE_BACKWARD = 'MoveBackward';
const DASHBOARD_EDIT_ACTION_REMOVE = 'Remove';

export class CardFrame extends React.Component<ICardFrameProps, IViewState> {
  _isMounted = false;
  constructor(props: ICardFrameProps) {
    super(props);
  }

  public render(): JSX.Element {
    const getClassNames = classNamesFunction<ICardFrameProps, ICardFrameStyles>();
    const { fontFamily, fontSize, cardTitle, seperatorColor, titleTextColor, disableDrag } = this.props;
    const classNames = getClassNames(getStyles, {
      cardTitle,
      fontFamily,
      fontSize,
      seperatorColor,
      titleTextColor,
      disableDrag
    });

    return (
      <div className={classNames.root}>
        <div className={classNames.cardTitleBox}>
          <h2 className={classNames.cardTitle}>
            {cardTitle}
          </h2>
          {this.props.inDashboardEditMode ? this.getRemoveButton(classNames) : ''}
          {this.props.inDashboardEditMode ? this.getSettingsButton(classNames) : ''}
          {!this.props.inDashboardEditMode && this.props.onViewMoreClick ? this.getViewMoreButton(classNames) : ''}
          {!this.props.inDashboardEditMode && this.props.onExpandClick ? this.getExpandButton(classNames) : ''}
        </div>
        <div className={classNames.layout}>{this.props.children}</div>
      </div>
    );
  }

  private handleMoveForward = () => {
    const newEvent = new CustomEvent(DACHBOARD_EDIT_ACTION_EVENT, {
      bubbles: false,
      detail: { action: DASHBOARD_EDIT_ACTION_MOVE_FORWARD, id: this.props.id }
    });
    window.dispatchEvent(newEvent);
  }

  private handleMoveBackward = () => {
    const newEvent = new CustomEvent(DACHBOARD_EDIT_ACTION_EVENT, {
      bubbles: false,
      detail: { action: DASHBOARD_EDIT_ACTION_MOVE_BACKWARD, id: this.props.id }
    });
    window.dispatchEvent(newEvent);
  }

  private menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'moveForward',
        text: 'Move forward',
        iconProps: { iconName: 'DecreaseIndentArrowMirrored' },
        onClick: this.handleMoveForward,
        disabled: false// tileIndex == tilesOrder.length - 1
      },
      {
        key: 'moveBackward',
        text: 'Move backward',
        iconProps: { iconName: 'IncreaseIndentArrowMirrored' },
        onClick: this.handleMoveBackward,
        disabled: false// tileIndex == 0

      }
    ],
    directionalHintFixed: true
  }

  private getSettingsButton = (classNames) => {
    return (
      <div className={classNames.cardTitleEllipsisButton}>
        <TooltipHost
          content={`${this.props.cardTitle} Move Settings`}
          id="MoveSettings"
        >
          <IconButton
            id={`Settings${this.props.id}`}
            className={classNames.ellipsisButtonStyle}
            menuIconProps={{ iconName: 'More' }}
            menuProps={this.menuProps}
            split={false}
            ariaLabel={`${this.props.cardTitle} Move Settings`}
            styles={{
              root: { width: '100%', height: '100%' },
              rootHovered: { background: '#EAEAEA', color: '#000000' },
              rootPressed: { background: '#979797', color: '#000000' },
              rootExpanded: { color: '#000000' }
            }}
          />
        </TooltipHost>
      </div>
    );
  }

  private handleRemoveClick = () => {
    const newEvent = new CustomEvent(DACHBOARD_EDIT_ACTION_EVENT, {
      bubbles: false,
      detail: { action: DASHBOARD_EDIT_ACTION_REMOVE, id: this.props.id }
    });
    window.dispatchEvent(newEvent);
  }

  private getRemoveButton = (classNames: IProcessedStyleSet<ICardFrameStyles>) => {
    return (
      <div className={classNames.cardTitleEllipsisButton}>
        <TooltipHost
          content={`${this.props.cardTitle} Remove Tile`}
          id='removeSettings'
        >
          <IconButton
            id={`Remove${this.props.id}`}
            className={classNames.ellipsisButtonStyle}
            menuIconProps={{ iconName: 'Delete' }}
            split={false}
            ariaLabel={`${this.props.cardTitle} Remove Tile`}
            onClick={this.handleRemoveClick}
            styles={{
              root: { width: '100%', height: '100%' },
              rootHovered: { background: '#EAEAEA', color: '#000000' },
              rootPressed: { background: '#979797', color: '#000000' },
              rootExpanded: { color: '#000000' }
            }}
          />

        </TooltipHost>
      </div>
    );
  }
  private getExpandButton(classNames: IProcessedStyleSet<ICardFrameStyles>) {
    if (this.props.expandButtonText) {
      return (
        <div className={classNames.expandLinkButtonStyle}>
          <Link
            onClick={this.props.onExpandClick}
            aria-label={this.props.expandButtonText}
          >
            {this.props.expandButtonText}
          </Link>
        </div>
      );
    }
    return (
      <div className={classNames.cardTitleEllipsisButton}>
        <IconButton
          id={`Expand${this.props.id}`}
          className={classNames.ellipsisButtonStyle}
          menuIconProps={{ iconName: 'FullScreen' }}
          split={false}
          aria-label={`${this.props.cardTitle} Expand`}
          onClick={this.props.onExpandClick}
          styles={{
            root: { width: '100%', height: '100%' },
            rootHovered: { background: '#EAEAEA', color: '#000000' },
            rootPressed: { background: '#979797', color: '#000000' },
            rootExpanded: { color: '#000000' }
          }}
        />
      </div>
    );
  }

  private getViewMoreButton(classNames: IProcessedStyleSet<ICardFrameStyles>) {
    return (
      <div className={classNames.cardTitleActionButton}>
        <Button
          className={classNames.ellipsisButtonStyle}
          disabled={false}
          allowDisabledFocus={false}
          text={'View More'}
          split={false}
          aria-label={'View More'}
          onClick={this.props.onViewMoreClick}
          styles={{
            root: {
              width: '100%',
              height: '100%',
              background: 'transparent',
              fontSize: '12px',
              color: '#0078D4',
              fontWeight: '400'
            },
            rootHovered: { background: 'transparent', color: '#0078D4' },
            rootPressed: { background: 'transparent', color: '#0078D4' },
            rootExpanded: { color: '#000000' }
          }}
        />
      </div>
    );
  }
}
