import queryString from 'query-string';
import { InjectedIntlProps } from 'react-intl';
import { RouteProps } from "react-router";
import { IExtensionComponent, } from '../../common/models'
import { IAppState, IAppTheme } from '@msx/platform-types'
import { ExtensionEventTypes } from './Extension.types';

export interface IExtensionHelper {
  loaderScript(scriptUrl: string, type: string): any;
  cleanupExtensions(): void;
  parseQueryString(url: string): any;
  dispatchExcludeEvent(): void;
}

export class ExtensionHelper implements IExtensionHelper {
  private location: RouteProps["location"];
  private intl: InjectedIntlProps["intl"];
  private componentSchema: IExtensionComponent;
  private extensionFileCount: number;
  public constructor(componentSchema: IExtensionComponent) {
    this.componentSchema = componentSchema;
    //this.extensionsRegistrationClient = extensionsRegistrationClient;
    //this.componentKey = componentKey;
    this.extensionFileCount = 0;
  }

  public cleanupExtensions = () => {
    const files = this.componentSchema.files;
    if (files.length > 0) {
      files.map(file => {
        const filename = `${file.url}${file.name}?${file.token}`;
        this.unloadScript(filename);
      })
    }
  }

  private unloadScript = (filename: string) => {
    const scripts = document.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
      const src = scripts[i].getAttribute('src');
      if (src === filename) {
        scripts[i].parentNode.removeChild(scripts[i]);
      }
    }
  }

  public loaderScript(scriptUrl: string, type: string): any {
    return new Promise(function (res, rej) {
      let script = document.createElement('script');
      if (!scriptUrl.includes("https://localhost")) script.crossOrigin = "anonymous";
      script.src = scriptUrl;
      script.type = type !== 'es6module' ? 'text/javascript' : 'module';
      script.onerror = rej;
      script.async = true;
      script.onload = res;
      script.addEventListener('error', rej);
      script.addEventListener('load', res);
      document.head.appendChild(script);
    })
  }

  public dispatchExcludeEvent = () => {
    let options = []
    if (this.componentSchema.exclude && this.componentSchema.exclude.length > 0) {
      options = this.componentSchema.exclude;
    }
    const event = new CustomEvent(ExtensionEventTypes.EXCLUDE_OPTIONS_EVENT, { detail: options });
    window.dispatchEvent(event);
  }

  public parseQueryString(url: string): any {
    let params = [];
    let obj = queryString.parse(url);
    Object.keys(obj).forEach(function (key, idx) {
      params.push(
        {
          key: key,
          value: obj[key]
        });
    });
    return params;
  }

  public deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }

    return true;
  }

  private isObject(object) {
    return object !== null && typeof object === 'object';
  }
}


