import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import { getStyles } from './MsxDetailsHeading.styles';
import { Guid, formatDate } from '../../utils/helpers';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { IAppTheme } from '../../theme/Theme.types'


interface OwnProps {
  detailsFields: any;
  theme: IAppTheme;
  title?: string;
}

type Props = OwnProps;
const getClassNames = classNamesFunction<any, any>();
let classes: any;

class MsxDetailsHeading extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    classes = getClassNames(getStyles(props.theme));

  }

  convertValidDates(detailsField: any) {
    if (!React.isValidElement(detailsField.label))
      if (detailsField.label && detailsField.label.toLowerCase().includes('date'))
        return [detailsField.label, formatDate(detailsField.value)];
    return [detailsField.label, detailsField.value];
  }

  renderDetailsFields() {
    const { detailsFields } = this.props;
    const gapSub = 0;
    if (detailsFields)
      return detailsFields.map(f => {
        const [label, value] = this.convertValidDates(f);
        const style = f.width && f.wrap ? { width: f.width + 'px' } : {};
        return (
          <Stack.Item key={f.key ? f.key : Guid.newGuid()}>
            <Stack gap={gapSub} wrap={f.wrap} className={f.wrap ? classes.wrapWord : classes.noWrapWord} style={style}>
              <Stack.Item className={[classes.label].join(' ')}>{label}</Stack.Item>
              <Stack.Item className={[classes.value].join(' ')}>{value}</Stack.Item>
            </Stack>
          </Stack.Item>
        );
      });
  }

  render() {
    const gapMain = 30;
    const title = this.props.title ? this.props.title : 'Details';
    return (
      <React.Fragment>
        <Stack.Item className={[classes.headerContainer].join(' ')}>
          <Stack>
            <Stack.Item >
              <p className={[classes.headerTitle].join(' ')}>{title}</p>
            </Stack.Item>
            <Stack.Item>
              <Stack className={classes.wrapPatch} horizontal wrap gap={gapMain}>
                {this.renderDetailsFields()}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </React.Fragment>
    );
  }
}

export default MsxDetailsHeading;
