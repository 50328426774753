import React, { useEffect, useContext, useState, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Stack, IconButton, IStackTokens } from 'office-ui-fabric-react';
import { ApplicationContext, } from '../../common/context/ApplicationContext';
import { ExtensionSchemaLoader } from '../Extension/ExtensionSchemaLoader';
import { TileListProps } from './TileList.types'
import { ExtensionHelper } from '../Extension/ExtensionHelper';
import { ExtensionEventTypes, ExtensionErrorTypes, ExtensionKeys } from '../Extension/Extension.types';
import { ServiceContext } from '../../common/context/ServiceContext';
import { UserEvent, UsageEventName } from '../../common/models/UsageTelemetry';
import { EventType } from '../../common/models/UsageTelemetry/UsageEvent';
import { DxpCard } from '../Card';
import { DxpModal, DxpModalHeight } from '../Modal';
import messages from './TileList.message';
import { getStyles } from './TileList.styles'
import { IAppError } from '../..';
import { AppContext, IAppContextProps } from '../Extension/AppContext';
import { useUser } from '../../common/hooks';

const getClassNames = classNamesFunction<any, any>();
let classes: any;

export const MsxTileList: (React.FC<TileListProps>) = props => {
  const { tiles } = props;
  const user = useUser();
  const { extensionsRegistrationClient, appState, userDashboardTiles } = useContext(ApplicationContext);
  const { telemetryClient, telemetryContext, authClient, httpClient } = useContext(ServiceContext);
  classes = getClassNames(getStyles(appState.theme));

  const renderTileList = () => {
    const sectionStackTokens: IStackTokens = { childrenGap: 20 };
    return (
      // <Stack>
      <Stack horizontal wrap tokens={sectionStackTokens}>
        {tiles.map((tile, i) => {
          return (
            <Stack.Item key={i}>
              <section style={{ cursor: 'auto' }}>
                {tile}
              </section>
            </Stack.Item>
          )
        })}
        {/* </Stack> */}
      </Stack>
    );
  }

  const renderMain = (): JSX.Element => {
    return (
      <div >
        {renderTileList()}
      </div>
    );
  }


  return renderMain();
}

export default MsxTileList;
