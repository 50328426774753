import * as React from 'react';
import { ClipLoader } from 'react-spinners';
import FabricTable from './FabricTable/FabricTable';
import ExportCsv from './ExportCsv/ExportCsv';
import { Headers, Data } from 'react-csv/src/metaProps';
import { Stack, IColumn, Announced } from 'office-ui-fabric-react';
import { getTableStyles } from './Table.styles';
import TableFilterDropdownCollection from './TableFilterDropdownCollection/TableFilterDropdownCollection';
import { IAppTheme } from '../../theme/Theme.types'
import { classNamesFunction } from 'office-ui-fabric-react/lib/Utilities';

const getClassNames = classNamesFunction<any, any>();
let classes: any;


export interface ITableColumn extends Partial<IColumn> {
  text?: string;
  filterable?: boolean;
  displayInTable?: boolean;
}
interface OwnProps<T> {
  rows: T[];
  customCsvData?: any[];
  columns: ITableColumn[];
  tableTitle?: string;
  isLoading?: boolean;
  exportCsvProps?: ExportCsvProps;
  onColumnSorted?: (data: any) => void;
  customHeight?: string;
  isModal?: boolean;
  disableSorting?: boolean;
  allowDataFilter?: boolean;
  theme: IAppTheme;
  telemetryContext?: any;
}

export interface ExportCsvProps {
  canExportCsv?: boolean;
  csvHeaders?: Headers;
  csvFilename?: string;
  csvTelemetryProps?: { [key: string]: string };
  id: string;
  exportButtonLabel?: string;
}
interface MyState {
  filteredItems?: any[];
}

type Props<T> = OwnProps<T>;

export class MsxTable<T> extends React.Component<Props<T>, MyState> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      filteredItems: this.props.rows
    };

    classes = getClassNames(getTableStyles(props.theme))
  }
  private telemetryContext = this.props.telemetryContext;

  componentDidUpdate(nextProps) {
    const { rows } = this.props
    if (nextProps.rows !== rows) {
      if (rows) {
        this.setState({ filteredItems: rows })
      }
    }
  }
  handleExportCsvClick = (): void => {
    if (this.telemetryContext) {
      this.telemetryContext.logEvent(this.props.exportCsvProps.id.concat("-clicked"));

      const { csvTelemetryProps } = this.props.exportCsvProps;
      const event = 'EXPORT_CSV';
      this.telemetryContext.logEvent(event, csvTelemetryProps);
    }
  }

  handleFilterChange = (filteredItems) => {
    this.setState({
      filteredItems: filteredItems
    })
  }

  renderExportCsv = (): JSX.Element => {
    const { rows, customCsvData } = this.props;
    let csvData
    if (customCsvData)
      csvData = customCsvData
    else if (rows)
      csvData = rows
    if (
      this.props.exportCsvProps &&
      this.props.exportCsvProps.canExportCsv &&
      csvData && (typeof (csvData[0] != 'boolean' || typeof (csvData[0] != 'number')))
    ) {
      const { csvHeaders, csvFilename, csvTelemetryProps, exportButtonLabel } = this.props.exportCsvProps;
      return (
        <Stack.Item>
          <ExportCsv
            data={csvData as unknown as Data | string}
            filename={csvFilename}
            asyncOnClick={false}
            headers={csvHeaders}
            onClick={this.handleExportCsvClick}
            telemetryProps={csvTelemetryProps}
            exportButtonLabel={exportButtonLabel}
          />
        </Stack.Item>
      );
    }
  }

  renderTable = (): JSX.Element => {
    const { isLoading, rows, columns, customHeight, onColumnSorted, disableSorting, allowDataFilter } = this.props;
    const defaultHeight = '100%';
    return (isLoading && rows.length === 0)
      ? <ClipLoader size={30} color={'#36D7B7'} loading={true} />
      : <div className={classes.tableStackItem}>
        <FabricTable
          theme={this.props.theme}
          columns={this.getColumnsForTable()}
          items={allowDataFilter ? this.state.filteredItems : rows}
          title={this.props.tableTitle}
          height={customHeight ? customHeight : defaultHeight}
          disableSorting={disableSorting}
          onColumnSorted={onColumnSorted}
        />
      </div>
  }

  renderTableTitle = (): JSX.Element => {
    const { tableTitle } = this.props;
    const tableTitleId = 'tableTitle';
    if (tableTitle) {
      return (
        <Stack.Item align={'start'}>
          <h2 className={classes.tableTitle} id={tableTitleId} tabIndex={-1}>
            {tableTitle}
          </h2>
        </Stack.Item>
      );
    }
  }
  getFilterableColumns = () => {
    return (

      this.props.columns.filter(column => column.filterable !== false)
    )
  }
  getColumnsForTable = () => {
    if (this.props.allowDataFilter === true) {
      return this.props.columns.filter(column => column.displayInTable !== false)
    }
    else
      return (
        this.props.columns
      )
  }

  compare(a, b) {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  }

  renderTableHeader = (): JSX.Element => {
    const exportCsv = this.renderExportCsv();
    const tableTitle = this.renderTableTitle();
    if (exportCsv || tableTitle) {
      const headingGap = '10%';
      if (this.props.allowDataFilter === true)
        return (
          <Stack.Item>
            <Stack horizontal wrap styles={{ root: { width: '100%' } }} >
              {tableTitle}
              {exportCsv}
            </Stack>
            <TableFilterDropdownCollection
              theme={this.props.theme}
              items={this.props.rows}
              columns={this.getFilterableColumns()}
              maxFilters={4}
              onColumnSorted={this.props.onColumnSorted}
              onChange={this.handleFilterChange}
            />
          </Stack.Item>
        );
      else
        return (
          <Stack.Item>
            <Stack horizontal wrap styles={{ root: { width: '100%' } }} >
              {tableTitle}
              {exportCsv}
            </Stack>
          </Stack.Item>
        );
    }
  }

  announceFiltering = (): JSX.Element => {
    const { tableTitle } = this.props;
    return <Announced message={tableTitle} />
  }

  render(): JSX.Element {
    return (
      <Stack className={classes.rootStack}>
        {this.announceFiltering()}
        {this.renderTableHeader()}
        {this.renderTable()}
      </Stack>
    );
  }
}

export default MsxTable;
