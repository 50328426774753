import * as AI from '@microsoft/applicationinsights-web';
import { CustomProperties, Measurements } from './TelemetryClient.types';
import { ITelemetryClient } from '@msx/platform-types';
import { IUTPConfig } from '../../common/models/IUTPConfig';
import { UsageTelemetryConfig, UsageEvent, asCuppSchema, UsageTracker } from '../../common/models//UsageTelemetry';
import { ITelemetryContext } from '../../common/Models';
import { deviceDetect } from 'react-device-detect';

//import { ILoggingContext } from '../../common/models/ILoggingContext';
//import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { guid } from '../utils/guid';

type TelemetryConfig = AI.IConfig &
  AI.IConfiguration &
  IUTPConfig & { defaultProperties?: CustomProperties; usageTelemetryConfig?: UsageTelemetryConfig };
export class TelemetryClient implements ITelemetryClient {
  private readonly appInsights: AI.ApplicationInsights;
  private readonly correlationId: string;
  private readonly config: TelemetryConfig;
  private telemetryContext: ITelemetryContext | undefined;

  public constructor(config: TelemetryConfig, correlationId?: string, appInsights?: AI.ApplicationInsights) {
    this.correlationId = correlationId || guid();
    if (!appInsights) {
      appInsights = new AI.ApplicationInsights({
        config,
      });
      const deviceInfo = deviceDetect();
      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer((item: AI.ITelemetryItem) => {
        item.data = {
          ...item.data,
          correlationId: this.correlationId,
          location: window.location.href.split('#')[0],
          telemetrySource: 'UI',
          ...config.UTPConfig,
          ...config.defaultProperties,
          ...deviceInfo,
        };
        if (item.data) {
          item.data = asCuppSchema(item.data);
        }
      });
    }
    this.appInsights = appInsights;
    this.config = config;
  }

  public trackPageView(pageView: AI.IPageViewTelemetry & UsageEvent): void {
    this.appInsights.trackPageView(pageView);
  }

  public startTrackPage(name?: string | undefined): void {
    this.appInsights.startTrackPage(name);
  }

  public trackPageViewPerformance(pageViewPerformance: AI.IPageViewPerformanceTelemetry): void {
    this.appInsights.trackPageViewPerformance(pageViewPerformance);
  }

  public trackEvent(event: AI.IEventTelemetry, customProperties?: CustomProperties): void;
  public trackEvent(event: UsageEvent, customProperties?: CustomProperties): void;
  public trackEvent(event: AI.IEventTelemetry & UsageEvent, customProperties?: CustomProperties): void {
    const usageEvent = this.telemetryContext?.setUsageEvent(event);
    this.appInsights.trackEvent({ ...event, ...usageEvent } as AI.IEventTelemetry, {
      ...usageEvent,
      ...customProperties,
    });
  }

  public setAuthenticatedUserContext(
    authenticatedUserId: string,
    accountId?: string | undefined,
    storeInCookie?: boolean | undefined
  ): void {
    this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
  }

  public trackException(exception: AI.IExceptionTelemetry): void {
    this.appInsights.trackException(exception);
  }

  public _onerror(exception: AI.IAutoExceptionTelemetry): void {
    this.appInsights._onerror(exception);
  }

  public trackTrace(trace: AI.ITraceTelemetry, customProperties?: CustomProperties): void {
    this.appInsights.trackTrace(trace, customProperties);
  }

  public trackMetric(metric: AI.IMetricTelemetry, customProperties?: CustomProperties): void {
    this.appInsights.trackMetric(metric, customProperties);
  }

  public stopTrackPage(
    name?: string | undefined,
    url?: string | undefined,
    customProperties?: CustomProperties
  ): void {
    this.appInsights.stopTrackPage(name, url, customProperties);
  }
  public startTrackEvent(name: string): void;
  public startTrackEvent(usageEvent: UsageEvent): UsageEvent | undefined;
  public startTrackEvent(name: string | UsageEvent): UsageEvent | undefined {
    if (typeof name === 'string') {
      this.appInsights.startTrackEvent(name);
    }
    if (typeof name !== 'string') {
      const eventName = 'eventName' in name ? (name.eventName || 'PageLoad') + 'Start' : 'PageLoadStart';
      const startEvent = { ...name, eventName };
      const event = this.telemetryContext?.setUsageEvent(startEvent);
      this.appInsights.startTrackEvent((event as UsageTracker).name);
      return event;
    }
  }

  // eslint-disable-next-line prettier/prettier
  public stopTrackEvent(name: string, properties?: CustomProperties, measurements?: Measurements): unknown;
  // eslint-disable-next-line prettier/prettier
  public stopTrackEvent(usageEvent: UsageEvent, properties?: CustomProperties, measurements?: Measurements): UsageEvent | undefined;
  // eslint-disable-next-line prettier/prettier
  public stopTrackEvent(name: string | UsageEvent, properties?: CustomProperties, measurements?: Measurements): UsageEvent | undefined {
    if (typeof name === 'string') {
      this.appInsights.stopTrackEvent(name, properties, measurements);
    } else {
      const telemetryProps: UsageEvent & CustomProperties = {
        ...((name || {}) as UsageEvent),
        ...(properties || {}),
      };
      const e = this.telemetryContext?.setUsageEvent({ ...name });
      const event: UsageEvent & CustomProperties = { ...telemetryProps, ...e };
      this.appInsights.stopTrackEvent(event.name, event, measurements);
      return event;
    }
  }

  public trackDependencyData(dependency: AI.IDependencyTelemetry): void {
    this.appInsights.trackDependencyData(dependency);
  }

  public addTelemetryInitializer(telemetryInitializer: (item: AI.ITelemetryItem) => boolean | void): void {
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public getChildInstance(
    config?: AI.IConfig & AI.IConfiguration & IUTPConfig,
    correlationId?: string,
    telemetryContext?: ITelemetryContext
  ): ITelemetryClient {
    const childClient = new TelemetryClient(
      config || this.config,
      correlationId || this.correlationId,
      this.appInsights
    );
    if (telemetryContext) {
      childClient.setContext(telemetryContext);
    }
    return childClient as ITelemetryClient;
  }

  public setContext(telemetryContext: ITelemetryContext): void {
    this.telemetryContext = telemetryContext;
  }

  public getCorrelationId(): string {
    return this.correlationId;
  }

  public getCookieMgr() {
    return this.appInsights.getCookieMgr();
  }

}
