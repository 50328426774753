import { IDetailsHeaderStyles } from 'office-ui-fabric-react/lib/components/DetailsList/DetailsHeader.types';
import { IDetailsRowStyles, IDetailsListStyles } from 'office-ui-fabric-react/lib/DetailsList';
import { IAppTheme } from '../Theme.types'

export const customHeaderStyles = (theme: IAppTheme): Partial<IDetailsHeaderStyles> => {
  return {
    root: {
      backgroundColor: 'transparent',
      //borderBottom: ' 1px solid lightgray;',
      // minHeight: '42px',
      // height:'auto',
      //paddingBottom: '10px',
      marginBottom: '10px',
      lineHeight: 'normal',
      selectors: {
        '.ms-DetailsHeader': {
          //height: '100%',
          //lineHeight: 'normal',
        },
        '.ms-DetailsHeader-cell': {
          //height: '100%',
          //lineHeight: 'auto',
        },
        '.ms-DetailsHeader-cellTitle': {
          height: '100%',
          lineHeight: 'auto',
        },
        '.ms-DetailsHeader-cellTitle i': {
          // margin: 'auto 0 0 0',
          //margin: 'auto',
          // border: '1px solid red',
          color: theme ? theme.palette.black : '#333333',
        },
        '.ms-DetailsHeader-cellName': {
          // border: '1px solid green',
          fontFamily: 'Segoe UI',
          //color: '#333333',
          color: theme ? theme.palette.black : '#333333',
          fontSize: '13px',
          fontWeight: 'bold',
          //lineHeight: 'normal',
          //margin: 'auto 0 0 0',
          whiteSpace: 'pre-line',
        },
        // '.ms-DetailsHeader-cellName:before': {
        //   content: "",
        //   width: '5px',
        //   height: '50px',
        // },
        '.ms-DetailsHeader-cell:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer',
        },
      }
    }
  }
};

export const customRowStyle = (theme: IAppTheme): Partial<IDetailsRowStyles> => {
  return {
    root: {
      backgroundColor: 'transparent',
      //color: 'black',
      color: theme ? theme.palette.black : 'black',
      // minHeight: '36px',
      // selectors: {
      //   minHeight: '36px',
      // }
    },
    cell: {
      //display: 'flex',
      alignSelf: 'center',
      backgroundColor: 'transparent',
      minHeight: '24px',
      paddingTop: '0',
      paddingBottom: '0',
      //borderBottom: ' .25px solid #e6e6e6',
    }
  }
};

export const customListStyle = (theme: IAppTheme): Partial<IDetailsListStyles> => {
  return {
    root: {
      backgroundColor: 'transparent',
      width: '100%',
      selectors: {
        '.ms-List-cell':
        {
          minHeight: '22px'
        },
        '.ms-DetailsHeader': {
          borderBottom: `1px solid gray`,
          //paddingTop: '0',
        },
      }
    }
  }
};
