import {
  IExtensionsRegistrationClient,
  IExtensionsRegistration,
  IAppExtension,
  IAppRoute,
  IAppCatalogue,
  IAppExtensionPage,
  IAppExtensionTile,
} from '../common/models';


export class ExtensionsRegistrationClient implements IExtensionsRegistrationClient {
  private readonly extensionsRegistration: IExtensionsRegistration;

  public constructor(extensionsRegistration: IExtensionsRegistration) {
    this.extensionsRegistration = extensionsRegistration;
  }

  public getExtensions(): IAppExtension[] {
    return this.extensionsRegistration.extensions;
  }

  public getRoutes(): IAppRoute[] {
    return this.extensionsRegistration.routes;
  }

  public getCatalogue(): IAppCatalogue[] {
    return this.extensionsRegistration.catalogue;
  }

  public getExtensionsPages(): IAppExtensionPage[] {
    return this.filterPages();
  }

  public getExtensionsTiles(): IAppExtensionTile[] {
    return this.filterTile();
  }

  private filterPages(): IAppExtensionPage[] {
    const result: IAppExtensionPage[] = [];
    if (!this.extensionsRegistration || this.extensionsRegistration.extensions.length == 0)
      return result;

    this.extensionsRegistration.extensions.map(extension => {
      if (extension.active) {
        extension.pages.map(page => {
          if (page.active) {
            const route = this.extensionsRegistration.routes.find(item => item.key === page.routeKey);
            page.routePath = route ? route.routePath : page.routeKey;
            result.push(page);
          }
        })
      }
    })
    return result;
  }

  private filterTile(): IAppExtensionTile[] {
    const result: IAppExtensionTile[] = [];
    if (!this.extensionsRegistration.extensions || this.extensionsRegistration.extensions.length == 0)
      return result;

    this.extensionsRegistration.extensions.map(extension => {
      if (extension.active) {
        extension.tiles.map(tile => {
          if (tile.active) {
            result.push(tile);
          }
        })
      }
    })

    return result;
  }

}
