import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '@msx/platform-types'

export const getStyles = (theme: IAppTheme) => {
  return {
    // root: {
    //   padding: '0',
    //   margin: '0'
    // },
    // headingMain: {
    //   //color: theme.colors.Text.HeadingMain,
    //   marginLeft: '10px',
    // },
    // tiles: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   alignItems: 'stretch',
    // },
    newTile: {
      margin: '10px',
      // width: '392px',
      // height: '230px',
    },
    selectCheckBox: {
      marginRight: '10px',
    },
  };
};
