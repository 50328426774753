import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';
import { DarkPalette } from './darkTheme/palette';
import { DefaultColors } from './defaultTheme/colors';
import { DarkColors } from './darkTheme/colors';
const fontFamily = '"Segoe UI", "Segoe UI Semibold", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif';

export const DefaultTheme: any = {
  name: 'default',
  palette: DefaultPalette,
  fabricTheme: { // obsolete, need to remove later
    palette: DefaultPalette
  },
  colors: DefaultColors, // obsolete, need to remove later
  fontFamily: fontFamily, // obsolete, need to remove later
};

export const DarkTheme: any = {
  name: 'dark',
  palette: DarkPalette,
  fabricTheme: { // obsolete, need to remove later
    palette: DarkPalette
  },
  colors: DarkColors, // obsolete, need to remove later
  fontFamily: fontFamily, // obsolete, need to remove later
};
