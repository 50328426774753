import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { IAppTheme } from '../../theme/Theme.types'

export interface IDetailsHeadingStyles {
  root: IStyle;
  loading: IStyle;
  headerContainer: IStyle;
  headerTitle: IStyle;
  group: any;
  row: IStyle;
  label: IStyle;
  value: IStyle;
  wrapWord: IStyle;
  wrapWordSmall: IStyle;
  noWrapWord: IStyle;
  wrapPatch: IStyle;
}

export const getStyles = (theme: IAppTheme): IDetailsHeadingStyles => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      minWidth: '350px',
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    headerContainer: {
      padding: '20px 0 0 20px',
      marginBottom: '30px',
    },
    headerTitle: {
      fontFamily: 'Segoe UI',
      fontSize: '18px',
      fontWeight: '600',
      //color: '#333333',
      margin: '0 0 10px 0',
    },
    group: {
      display: 'flex',
      alignItems: 'top',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      marginBottom: '20px',
    },
    row: {
      marginBottom: '10px',
      minWidth: '100px',
      paddingRight: '10px',
    },
    label: {
      fontFamily: 'Segoe UI',
      fontSize: '14px',
      fontWeight: 'bold',
      //color: '#333333',
      margin: '0',

    },
    value: {
      fontFamily: 'Segoe UI',
      fontSize: '12px',
      //color: '#333333',
      margin: '0',
      marginTop: '5px',
    },
    wrapWord: {
      width: '65px',
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
    wrapWordSmall: {
      width: '50px',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      height: '38px',
    },
    noWrapWord: {
      minWidth: '210px',
    },
    wrapPatch: {
      marginRight: '5px',
    }
  }
}
